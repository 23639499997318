import React, { useState, useEffect, useRef } from "react";

function AnimatedTextFormAutocomplete({
  type,
  value,
  idName,
  name,
  data,
  generate_template,
  searchFunction,
  loadingData,
  onEnterFunction,
  addFunction,
  onSelectOption,
  required,
  selectionFunction,
  searchType,
  setShowCompanyPage,
  showCompany,
  companyNew,
  initialSelectedEmails,
  className = "",
  inputClassName = "",
  listClass = "",
  ...props
}) {
  const [hide, setHide] = useState(true);
  const [disabled, setDisabled] = useState(!value?.trim());
  const [typedPlaceholder, setTypedPlaceholder] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [typingDirection, setTypingDirection] = useState(1); // 1 for typing, -1 for deleting
  const textareaRef = useRef(null);

  const placeholderTexts = [
    "Seeking a Software Engineer with 5 years of experience",
    "Looking for a Data Analyst with 5 years of experience",
    "Hiring a Software Developer with 5 years of professional experience",
    "In need of a Data Specialist with 5 years of expertise",
    "Searching for a Senior Software Engineer with 5 years of experience",
    "Wanting a Data Analyst with a minimum of 5 years of experience",
    "Requesting applications for a Software Engineer with 5 years in the field",
    "Desiring a Data Analyst with at least 5 years of experience",
    "Seeking an experienced Software Engineer with 5 years in the industry",
    "Looking for a seasoned Data Analyst with 5 years of hands-on experience",
  ];

  const handleButtonClick = () => {
    if (textareaRef.current) {
      generate_template(textareaRef.current.value);
    }
    setDisabled(true);
  };

  useEffect(() => {
    setDisabled(false);
  }, [loadingData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const text = placeholderTexts[placeholderIndex];
      const currentLength = typedPlaceholder.length;
      const targetLength = text.length;

      if (typingDirection === 1) {
        // Typing
        if (currentLength < targetLength) {
          setTypedPlaceholder(text.substring(0, currentLength + 1));
        } else {
          // Start deleting
          setTypingDirection(-0.5);
        }
      } else {
        // Deleting
        if (currentLength > 0) {
          setTypedPlaceholder(text.substring(0, currentLength - 1));
        } else {
          // Start typing the next placeholder
          const nextIndex = (placeholderIndex + 1) % placeholderTexts.length;
          setPlaceholderIndex(nextIndex);
          setTypingDirection(1);
        }
      }
    }, 100); // Typing speed

    return () => clearInterval(intervalId);
  }, [typedPlaceholder, placeholderIndex, typingDirection, placeholderTexts]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(`#${idName} .auto-complete-input`)) {
        setHide(true);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [idName]);

  return (
    <div id={idName} className={`auto-complete relative ${className}`}>
      <div className="relative">
        <textarea
          ref={textareaRef}
          className={`w-full auto-complete-input ${inputClassName} placeholder-gray-600`}
          name={name}
          value={value}
          required={required}
          placeholder={typedPlaceholder}
          style={{
            fontSize: "16px",
            minHeight: "100px",
            height: "150px", // Adjust the height as needed
            width: "100%", // Adjust the width as needed
            border: "1px solid #ccc", // Adjust the border as needed
            borderRadius: "4px", // Optional: Add border radius for rounded corners
            padding: "10px 10px 40px", // Optional: Add padding for better appearance
            boxSizing: "border-box", // Ensure padding and border are included in the element's total width and height
            position: "relative",
          }}
          onChange={(e) => {
            selectionFunction("template_name", e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              generate_template(e.target.value);
              e.preventDefault();
            }
          }}
          {...props}
        />
        {value?.length > 0 && (
          <button
            onClick={handleButtonClick}
            disabled={disabled}
            className="absolute bottom-2.5 right-1.5 btn-md btn-primary flex items-center justify-center border-none cursor-pointer rounded-lg bg-blue-800"
          >
            Generate...
          </button>
        )}
      </div>
    </div>
  );
}

export default AnimatedTextFormAutocomplete;
