import { Switch } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import errorIcon from "../../assets/error.svg";

const typeOptions = [
  { value: 0, label: "Not Required" },
  { value: 1, label: "Nice to Have" },
  { value: 2, label: "Must Have" },
];

const OtherAttributes = ({
  formJobdetails,
  setFormJobdetails,
  errorList,
  setErrorList,
}) => {
  console.log(formJobdetails);
  const [stability, setStability] = useState({
    value: [0],
    type: 0,
  });
  const [career_growth, setcareer_growth] = useState({
    value: [0],
    type: 0,
  });
  const [startup_experience, setstartup_experience] = useState({
    value: [0],
    type: 0,
  });
  const [open_for_new_role, setopen_for_new_role] = useState(false);
  const [companyMatchAttributes, setCompanyMatchAatributes] = useState([
    "Overview",
    "Culture",
    "Industry",
    "Products & Services",
    "Careers",
  ]);
  const [inital, setInitial] = useState(true);

  useEffect(() => {
    if (!inital) return;
    setTimeout(() => {
      if (
        formJobdetails?.company_match_attributes &&
        formJobdetails?.company_match_attributes.length > 0
      ) {
        let arr = [];
        for (const i of formJobdetails?.company_match_attributes) {
          if (!companyMatchAttributes.includes(i)) {
            arr.push(i);
          }
        }
        setCompanyMatchAatributes([...companyMatchAttributes, ...arr]);
      }
      setInitial(false);
    }, [300]);
  }, [formJobdetails?.company_match_attributes]);

  const [newInput, setNewInput] = useState("");
  return (
    <div className="px-4 flex flex-col gap-10">
      <div className="grid grid-cols-12 gap-2 items-center mt-8">
        <div className="col-span-4">Stability</div>
        <div></div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.stability?.threshold || 0]}
            onChange={(newValues) =>
              setFormJobdetails((prev) => ({
                ...prev,
                stability: {
                  ...prev?.stability,
                  threshold: newValues[0],
                },
              }))
            }
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [[formJobdetails?.stability?.threshold || 0]],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.stability?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                stability: {
                  ...prev?.stability,
                  type: parseInt(e.target.value),
                },
              }))
            }
            value={formJobdetails?.stability?.type || 0}
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4">Career growth</div>
        <div></div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.career_growth?.threshold || 0]}
            onChange={(newValues) =>
              setFormJobdetails((prev) => ({
                ...prev,
                career_growth: {
                  ...prev?.career_growth,
                  threshold: newValues[0],
                },
              }))
            }
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [[formJobdetails?.career_growth?.threshold || 0]],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.career_growth?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                career_growth: {
                  ...prev?.career_growth,
                  type: parseInt(e.target.value),
                },
              }))
            }
            value={formJobdetails?.career_growth?.type || 0}
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4">Startup experience</div>
        <div></div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.startup_experience?.threshold || 0]}
            onChange={(newValues) => {
              setFormJobdetails((prev) => ({
                ...prev,
                startup_experience: {
                  ...prev?.startup_experience,
                  threshold: newValues[0],
                },
              }));
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [
                      [formJobdetails?.startup_experience?.threshold || 0],
                    ],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.startup_experience?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            value={formJobdetails?.startup_experience?.type || 0}
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                startup_experience: {
                  ...prev?.startup_experience,
                  type: parseInt(e.target.value),
                },
              }))
            }
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4">Open for new role</div>
        <div></div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.open_for_new_role?.threshold || 0]}
            onChange={(newValues) => {
              setFormJobdetails((prev) => ({
                ...prev,
                open_for_new_role: {
                  ...prev?.open_for_new_role,
                  threshold: newValues[0],
                },
              }));
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [
                      [formJobdetails?.open_for_new_role?.threshold || 0],
                    ],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.open_for_new_role?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            value={formJobdetails?.open_for_new_role?.type || 0}
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                open_for_new_role: {
                  ...prev?.open_for_new_role,
                  type: parseInt(e.target.value),
                },
              }))
            }
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4">Company match</div>
        <div></div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.company_match?.threshold || 0]}
            onChange={(newValues) => {
              setFormJobdetails((prev) => ({
                ...prev,
                company_match: {
                  ...prev?.company_match,
                  threshold: newValues[0],
                },
              }));
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [[formJobdetails?.company_match?.threshold || 0]],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.company_match?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            value={formJobdetails?.company_match?.type || 0}
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                company_match: {
                  ...prev?.company_match,
                  type: parseInt(e.target.value),
                },
              }))
            }
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-6">Open For New Role</div>
        <div className="col-span-6 flex justify-end">
          <Switch
            className="bg-gray-300 aria-checked:bg-teal-700"
            checked={formJobdetails.open_for_new_role}
            onChange={(checked) => {
              setFormJobdetails((prev) => ({
                ...prev,
                open_for_new_role: checked,
              }));
            }}
          />
        </div>
      </div> */}
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4">Number of Prospects</div>
        <div></div>
        <div className="col-span-7">
          <input
            type="number"
            value={formJobdetails?.number_of_prospects}
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                number_of_prospects: e.target.value,
              }))
            }
            className="w-full border border-gray-300 rounded-md p-2"
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-6">Blue Color Job</div>
        <div className="col-span-6 flex justify-end">
          <Switch
            className="bg-gray-300 aria-checked:bg-teal-700"
            checked={formJobdetails?.blueColor}
            onChange={(checked) => {
              setFormJobdetails((prev) => ({
                ...prev,
                blueColor: checked,
              }));
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-6">Seniority Filter</div>
        <div className="col-span-6 flex justify-end">
          <Switch
            className="bg-gray-300 aria-checked:bg-teal-700"
            checked={formJobdetails?.seniority_check}
            onChange={(checked) => {
              setFormJobdetails((prev) => ({
                ...prev,
                seniority_check: checked,
              }));
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-12">
          company Match Attributes
          {Object.keys(errorList).includes("company_match_attr") ? (
            <>
              <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
              <span className="text-red-800 text-sm">
                {errorList?.company_match_attr}
              </span>
            </>
          ) : null}
        </div>
        <div className="col-span-12">
          <input
            value={newInput}
            className={`w-full px-2 mb-4 ${
              Object.keys(errorList).includes("company_match_attr")
                ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                : ""
            }`}
            type="text"
            placeholder="Press enter to add criteria"
            onChange={(event) => {
              // If input is cleared, remove error
              if (
                event.target.value == "" &&
                Object.keys(errorList).includes("company_match_attr")
              ) {
                setErrorList((current) => {
                  const { company_match_attr, ...rest } = current;
                  return rest;
                });
              }
              setNewInput(event.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                try {
                  if (e.target.value == "") return;
                  if (
                    companyMatchAttributes
                      .map((d) => String(d).toLowerCase())
                      .includes(String(e.target.value).toLowerCase())
                  )
                    throw "already added";
                  setCompanyMatchAatributes((prev) => [
                    ...prev,
                    e.target.value,
                  ]);
                  console.log(e.target.value);
                  setFormJobdetails((prev) => ({
                    ...prev,
                    company_match_attributes: [
                      ...prev?.company_match_attributes,
                      e.target.value,
                    ],
                  }));
                  setNewInput("");
                  // If validated value, remove previous error
                  setErrorList((current) => {
                    const { company_match_attr, ...rest } = current;
                    return rest;
                  });
                } catch (error) {
                  console.error(error);
                  setErrorList({ ...errorList, company_match_attr: error });
                }
              }
            }}
          />
        </div>
        <div className="col-span-12">
          {companyMatchAttributes.map((attr) => (
            <button
              key={attr.trim().split(" ").join("_")}
              className={`mr-2 mb-2 h-[34px] px-3 rounded-2xl whitespace-nowrap min-w-fit ${
                formJobdetails?.company_match_attributes?.includes(attr)
                  ? "btn-primary !bg-[#00B98D] !border-[#00B98D]"
                  : "btn-sec hover:text-[#00B98D] hover:border-[#00B98D]"
              }`}
              onClick={(e) => {
                e.preventDefault();
                if (formJobdetails?.company_match_attributes?.includes(attr)) {
                  setFormJobdetails((prev) => ({
                    ...prev,
                    company_match_attributes:
                      prev?.company_match_attributes?.filter(
                        (item) => item !== attr,
                      ),
                  }));
                } else {
                  setFormJobdetails((prev) => ({
                    ...prev,
                    company_match_attributes: [
                      ...prev?.company_match_attributes,
                      attr,
                    ],
                  }));
                }
              }}
            >
              {attr}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(OtherAttributes);
