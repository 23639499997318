import React from "react";
import Modal from "../../modal/Modal";
import JobTempData from "../templates/JobTempData";
import { useNavigate } from "react-router-dom";

function CompanyModal({ modalDismiss, data, templateData, tempid }) {
  const nav = useNavigate();
  console.log(data);

  const [companyData, setCompanyData] = React.useState(data);

  const handleInputChange = (fieldKey, value) => {
    setCompanyData({ ...companyData, [fieldKey]: value });
  };

  const EditableField = ({ label, value, fieldKey, textarea }) => {
    return (
      <div className="mb-2">
        <label className="w-32 font-semibold">{label}</label>
        {textarea ? (
          <textarea
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={value}
            onChange={(e) => handleInputChange(fieldKey, e.target.value)}
          />
        ) : (
          <input
            type="text"
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={value}
            onChange={(e) => handleInputChange(fieldKey, e.target.value)}
          />
        )}
      </div>
    );
  };

  return (
    <Modal
      className={"w-full max-w-full"} // Adjusted class to make modal full width
      onSave={(e) => {
        const url = `/templates/job/${tempid}`;
        const newTab = window.open(url, "_blank");
        newTab.focus();
      }}
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryClass: "bg-[#1369E9]",
        primaryLabel: <span className="flex items-center gap-4">Save</span>,
      }}
    >
      <div className="p-8 w-full max-w-full mx-auto">
        <h3 className="mb-2 text-gray-800">{data?.name}</h3>
        <div className="space-y-4 w-full">
          <EditableField
            label="Industry"
            value={companyData.industry.join(", ")}
            fieldKey="industry"
          />
          <EditableField
            label="About"
            value={companyData.about}
            textarea
            fieldKey="about"
          />
          <EditableField
            label="Domain URL"
            value={companyData.domain_url}
            fieldKey="domain_url"
          />
          <EditableField
            label="Year Founded"
            value={companyData.year_founded}
            fieldKey="year_founded"
          />
          <EditableField
            label="Location"
            value={companyData.location}
            fieldKey="location"
          />
          <EditableField
            label="Country"
            value={companyData.country}
            fieldKey="country"
          />
          <EditableField
            label="LinkedIn"
            value={companyData.linkedin}
            fieldKey="linkedin"
          />
        </div>
        {JSON.stringify(templateData)}
      </div>
    </Modal>
  );
}

export default CompanyModal;
