import { useEffect, useState, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./screens/auth/Login";
import NewUserRegister from "./screens/auth/NewUserRegister";
import Dashboard from "./screens/user/Dashboard";
import CreateProfile from "./screens/user/CreateProfile";

import JobDescription from "./screens/jobBoard/Description";
import SavedJobs from "./screens/candidate/dashboard/SavedJobs";
import AppliedJobs from "./screens/candidate/dashboard/AppliedJobs";
import ProfilePage from "./screens/candidate/ProfilePage";

// Recruiter
import RecruiterProfile from "./screens/recruiter/RecruiterProfile";
import CreateCompany from "./screens/recruiter/CreateCompany";
import CreateJob from "./screens/recruiter/CreateJob";
import EditCompany from "./screens/recruiter/EditCompany";
import Message from "./screens/user/Message";
import CompanyList from "./screens/recruiter/companyProfile/CompanyList";
import CompanyDetails from "./components/recruiter/company/CompanyDetails";
import JobDetailPage from "./screens/recruiter/jobs/JobExpandedNew";
import Bot from "./screens/recruiter/botComponent/Bot";
import FormBuilder from "./screens/temp/FormBuilder";
import Admin from "./screens/admin/Admin";
import NewSetting from "./screens/user/setting/NewSetting";
import ChatRecruiter from "./screens/recruiter/ChatRecruiter";
import GoogleCal from "./components/recruiter/GoogleCal";
import "./style/all/style.scss";
import { ToastContainer } from "react-toastify";
import MeetingNotes from "./screens/recruiter/instantMeeting/MeetingNotes";
import LiveMeeting from "./screens/recruiter/instantMeeting/LiveMeeting";
import EvaluationDoc from "./screens/recruiter/SubmitCandidate";
import CandidateEvaluation from "./screens/recruiter/candidate/CandidateEvaluation";
import BrowseCandidates from "./screens/recruiter/candidate/BrowseCandidates";
import Assessment from "./screens/candidate/assessment/Assessment";
import InviteSignUp from "./screens/auth/inviteAuth/InviteSignUp";
import InviteVerifyCode from "./screens/auth/inviteAuth/InviteVerifyCode";
import InvitePassword from "./screens/auth/inviteAuth/InvitePassword";
import Zoom from "./components/candidate/tempFolder/Zoom";
import AssessmentDashboard from "./screens/candidate/assessment/Assessmentdashboard";
import AssessmentEvalReport from "./screens/recruiter/AssessmentEvalReport";
import InviteAuth from "./screens/auth/inviteAuth/InviteAuth";
import ChartsTemplate from "./screens/temp/Chart";
import Twilio from "./screens/twilio/Twilio";
import Sms from "./screens/twilio/Sms";
import VoiceCall from "./screens/voice/VoiceCall";
import ForgotPassword from "./screens/auth/ForgotPassword";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import JobListing from "./screens/jobBoard/JobListing";
import NewUser from "./screens/admin/NewUser";
import Role from "./screens/admin/Role";
import User from "./screens/admin/User";
import TemplateManager from "./screens/admin/TemplateManager";
import AdminDashboard from "./screens/admin/Dashboard";
import GmailVerificationComplete from "./screens/recruiter/GmailVerificationComplete";
import CandidateDrawer from "./components/recruiter/candidate/CandidateDrawer";
import HandPickedCandidate from "./screens/recruiter/HandPickedCandidate";
import LockedPage from "./layouts/LockedPage";
import InviteLogin from "./screens/auth/InviteLogin";
import { Tooltip } from "react-tooltip";
import SequencePage from "./screens/recruiter/sequence/SequencePage";
import SequencePageContent from "./components/recruiter/sequencing/SequencePageContent";
import CreateSequence from "./components/recruiter/sequencing/CreateSequence";
import Structure from "./components/recruiter/sequencing/tabs/Structure";
import Status from "./components/recruiter/sequencing/tabs/Status";
import TemplatePage from "./screens/recruiter/TemplatePage";
import CreateTemplate from "./components/recruiter/templates/CreateTemplate";
import TemplatePageContent from "./components/recruiter/templates/TemplatePageContent";
import TemplateStructure from "./components/recruiter/templates/tabs/Structure";
import { SocketProvider } from "./lib/ChatContext";
import TemplateStructure2 from "./components/recruiter/templates/tabs/jobTempStructure";
import { JobTemplateScreen } from "./screens/recruiter/JobTemplateScreen";
import EditJobTemplate from "./components/recruiter/templates/EditJobTemplate";
// import jobTempStructure from "./components/recruiter/templates/tabs/jobTempStructure";
import Jobs from "./screens/jobPost/Jobs";
import JobDescr from "./screens/jobPost/JobDescr";
import JobForm from "./screens/jobPost/JobForm";
import JobDesc from "./screens/jobPost/jobDesc/JobDesc";
import JobApplicationForm from "./screens/jobPost/jobDesc/JobApplicationForm";
import JobSubmitted from "./screens/jobPost/jobDesc/JobSubmitted";
import Avavilabiltycheck from "./screens/recruiter/Avavilabiltycheck";
const core = require("./lib/core");

export default function App() {
  const [user, setUser] = useState();
  const nav = useNavigate();
  const [profile, setProfile] = useState();

  const [resources, setResources] = useState([]);
  const [role, setRole] = useState();

  const userMemo = useMemo(
    () => ({ user, setUser, profile, resources, role }),
    [user, profile, resources, role],
  );
  const loc = useLocation();
  useEffect(() => {
    if (core.checkAuth()) getUser();
  }, []);
  // Get user details

  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
      );
      // If user exists
      if (json?.data?.user) {
        setUser(json?.data?.user);
        // Get profile details
        // let profileAPI =
        //   json?.data?.user?.account == 0
        //     ? core.CANDIDATE_API.GET_PROFILE_DETAILS
        //     : core.RECRUITER_API.GET_PROFILE_DETAILS;
        // try {
        //   await core.API(core.API_METHODS.GET, profileAPI, 1);
        // } catch (err) {
        //   console.error("User does not have a profile", err);
        //   setProfile(null);
        // }
      } else {
        console.error("There is no user with these credentials");
        core.logout();
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const { resources, role } = await core.getResources();
      setResources(resources);
      setRole(role);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (user?.id && user?.stage != "COMPLETED") {
      nav("/register", { replace: true });
      return;
    }
    // if (user?.account == 1 && user?.active != 1) {
    //   nav("/unactive");
    // }
  }, [user]);
  const ProtectedRoute = ({ children }) => {
    if (core.checkAuth()) {
      return children;
    } else {
      return <Navigate to="/login" state={loc?.pathname + loc.search} />;
    }
  };

  console.log("userMemo", userMemo);
  return (
    <core.UserContext.Provider value={userMemo}>
      <SocketProvider>
        <Routes>
          {/* job post */}
          <Route path="/job-post/:id" element={<Jobs />} />
          <Route path="/job-post/form/:id" element={<JobForm />} />
          <Route path="/details/:id" element={<JobDesc />} />
          <Route path="job-post/submit" element={<JobSubmitted />} />
          <Route path="job-post/submit" element={<JobSubmitted />} />

          <Route path="/availabilty" element={<Avavilabiltycheck />} />

          {/* Login */}

          <Route path="/linkedin" element={<LinkedInCallback />} />
          <Route path="/register" element={<NewUserRegister />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* <Route path="/unactive" element={<UnactiveAccount />} /> */}
          <Route path="/invite" element={<InviteAuth />} />
          <Route path="/invitelogin" element={<InviteLogin />} />
          {/* Invite Pages */}
          <Route path="/invite-signup" element={<InviteSignUp />} />
          <Route path="/invite-enter-code" element={<InviteVerifyCode />} />
          <Route path="/invite-set-password" element={<InvitePassword />} />

          {/* Public Pages */}
          <Route path="/submit/:jobId" element={<EvaluationDoc />} />
          <Route
            path="/evaluation/:id/:interview_id"
            element={<CandidateEvaluation />}
          />
          <Route path="/careers" element={<JobListing />} />
          <Route path="/jobpost/:id" element={<JobDescription />} />
          <Route
            path="/assessment-report/:id"
            element={<AssessmentEvalReport />}
          />

          <Route
            path="/verification-complete"
            element={<GmailVerificationComplete />}
          />

          {/* Temporary/Practice files */}
          <Route path="/chart-temp" element={<ChartsTemplate />} />
          <Route path="/form-builder" element={<FormBuilder />} />
          {/* Recruiter */}
          <Route path="/voice-call" element={<VoiceCall />} />
          <Route path="/twilioapp" element={<Twilio />} />
          <Route
            path="/sms"
            element={
              <LockedPage page={"Communications_read"}>
                <Sms />
              </LockedPage>
            }
          />
          <Route path="/zoom" element={<Zoom />} />

          {/* Recruiter */}
          <Route
            path="/"
            exact
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:id"
            exact
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-profile"
            exact
            element={
              <ProtectedRoute>
                <CreateProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                {/* <Setting /> */}
                <NewSetting />
              </ProtectedRoute>
            }
          />
          <Route
            path="/googleCal"
            element={
              <ProtectedRoute>
                <GoogleCal />
              </ProtectedRoute>
            }
          />

          {user && (
            <>
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    {/* <Dashboard /> */}
                    <AssessmentDashboard />
                  </ProtectedRoute>
                }
              />
              {user?.account === 0 ? (
                <>
                  <Route
                    path="/saved"
                    element={
                      <ProtectedRoute>
                        <SavedJobs />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/applied"
                    element={
                      <ProtectedRoute>
                        <AppliedJobs />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile/:id/edit"
                    element={
                      <ProtectedRoute>
                        <ProfilePage />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/profile/:id"
                    element={
                      <ProtectedRoute>
                        <ProfilePage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/assessment/:id"
                    element={
                      <ProtectedRoute>
                        <Assessment />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/assessment"
                    element={
                      <ProtectedRoute>
                        <Assessment />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/assessment-dashboard"
                    element={
                      <ProtectedRoute>
                        <AssessmentDashboard />
                      </ProtectedRoute>
                    }
                  />
                </>
              ) : user?.account === 1 ? (
                <>
                  <Route
                    path="/admin/dashboard"
                    element={
                      <LockedPage page={"Analytics_read"}>
                        <AdminDashboard />
                      </LockedPage>
                    }
                  />
                  <Route
                    path="/edit-company"
                    element={
                      <ProtectedRoute>
                        <EditCompany />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/create-job"
                    element={
                      <LockedPage page={"Job_create"}>
                        <CreateJob />
                      </LockedPage>
                    }
                  />
                  <Route
                    path="/edit-job/:id"
                    element={
                      <ProtectedRoute>
                        <CreateJob />
                      </ProtectedRoute>
                    }
                  />

                  <Route path="/job">
                    <Route
                      path=":jobType"
                      element={
                        <ProtectedRoute>
                          <JobDetailPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path=":jobType/:jobId/:tab"
                      element={
                        <ProtectedRoute>
                          <JobDetailPage />
                        </ProtectedRoute>
                      }
                    >
                      <Route
                        path="show-candidate/:candidateId"
                        element={
                          <ProtectedRoute>
                            <CandidateDrawer source="job" />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                  </Route>

                  <Route
                    path="/browse-candidates"
                    exact
                    element={
                      <ProtectedRoute>
                        <BrowseCandidates />
                      </ProtectedRoute>
                    }
                  >
                    <Route
                      path="show-candidate/:candidateId"
                      element={
                        <ProtectedRoute>
                          <CandidateDrawer source="candidate" />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route
                    path="/create-company"
                    element={
                      <ProtectedRoute>
                        <CreateCompany />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/create-company"
                    element={
                      <ProtectedRoute>
                        <CreateCompany />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/company-profile"
                    element={
                      <LockedPage>
                        <CompanyList />
                      </LockedPage>
                    }
                  ></Route>
                  <Route
                    path="/company-profile/:companyId"
                    element={
                      <LockedPage>
                        <CompanyList />
                      </LockedPage>
                    }
                  ></Route>
                  <Route
                    path="/hand-picked"
                    element={
                      <ProtectedRoute>
                        <HandPickedCandidate />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/company-details/:id"
                    element={
                      <ProtectedRoute>
                        <CompanyDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/sequences" element={<SequencePage />}>
                    <Route path="new" element={<CreateSequence />} />
                    <Route path=":id" element={<SequencePageContent />}>
                      <Route index element={<Structure />} />
                      <Route path="structure" element={<Structure />} />
                      <Route path="status" element={<Status />} />
                    </Route>
                  </Route>

                  <Route path="/templates/email" element={<TemplatePage />}>
                    <Route path="new" element={<CreateTemplate />} />
                    <Route path=":id" element={<TemplatePageContent />}>
                      <Route index element={<TemplateStructure />} />
                      <Route path="structure" element={<TemplateStructure />} />
                      {/* <Route path="status" element={<Status />} /> */}
                    </Route>
                  </Route>

                  <Route path="/templates/job" element={<JobTemplateScreen />}>
                    <Route path="new" element={<EditJobTemplate />} />
                    <Route path=":id" element={<TemplateStructure2 />} />
                  </Route>

                  <Route
                    path="/recruiter-profile"
                    element={
                      <ProtectedRoute>
                        <RecruiterProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/chat"
                    element={
                      <ProtectedRoute>
                        <Message />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/chats"
                    element={
                      <ProtectedRoute>
                        <ChatRecruiter />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/interview/:id"
                    element={
                      <ProtectedRoute>
                        <Bot />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/instant-meet/:id"
                    element={
                      <ProtectedRoute>
                        <MeetingNotes />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/instant-meet"
                    element={
                      <ProtectedRoute>
                        <MeetingNotes />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/instant-meet/live/:id"
                    element={
                      <ProtectedRoute>
                        <LiveMeeting />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/systemadmin" element={<Admin user={user} />}>
                    {/* Nested routes */}
                    <Route path="new-appearence" element={<NewUser />} />
                    <Route path="role/:teamId" element={<Role />} />
                    <Route path="users" element={<User />} />
                  </Route>
                  <Route
                    path="/company-profile"
                    element={
                      <LockedPage>
                        <CompanyList />
                      </LockedPage>
                    }
                  ></Route>
                  <Route
                    path="/company-profile/:companyId"
                    element={
                      <LockedPage>
                        <CompanyList />
                      </LockedPage>
                    }
                  ></Route>
                  <Route
                    path="/hand-picked"
                    element={
                      <ProtectedRoute>
                        <HandPickedCandidate />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/company-details/:id"
                    element={
                      <ProtectedRoute>
                        <CompanyDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/sequences" element={<SequencePage />}>
                    <Route path="new" element={<CreateSequence />} />
                    <Route path=":id" element={<SequencePageContent />}>
                      <Route index element={<Structure />} />
                      <Route path="structure" element={<Structure />} />
                      <Route path="status" element={<Status />} />
                    </Route>
                  </Route>
                  <Route path="/templates" element={<TemplatePage />}>
                    <Route path="new" element={<CreateTemplate />} />
                    <Route path=":id" element={<TemplatePageContent />}>
                      <Route index element={<TemplateStructure />} />
                      <Route path="structure" element={<TemplateStructure />} />
                      {/* <Route path="status" element={<Status />} /> */}
                    </Route>
                  </Route>
                  <Route
                    path="/recruiter-profile"
                    element={
                      <ProtectedRoute>
                        <RecruiterProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/chat"
                    element={
                      <ProtectedRoute>
                        <Message />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/chats"
                    element={
                      <ProtectedRoute>
                        <ChatRecruiter />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/interview/:id"
                    element={
                      <ProtectedRoute>
                        <Bot />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/instant-meet/:id"
                    element={
                      <ProtectedRoute>
                        <MeetingNotes />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/instant-meet"
                    element={
                      <ProtectedRoute>
                        <MeetingNotes />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/instant-meet/live/:id"
                    element={
                      <ProtectedRoute>
                        <LiveMeeting />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/systemadmin" element={<Admin user={user} />}>
                    {/* Nested routes */}
                    <Route path="new-appearence" element={<NewUser />} />
                    <Route path="role/:teamId" element={<Role />} />
                    <Route path="users" element={<User />} />
                    <Route
                      path="template-manager"
                      element={<TemplateManager />}
                    />
                    {/* Add more nested routes here */}
                  </Route>
                </>
              ) : null}
            </>
          )}
        </Routes>
      </SocketProvider>
      <ToastContainer />
      <Tooltip
        id="tooltip"
        noArrow={false}
        style={{
          zIndex: 9999,
          background: "#5F6989",
          fontSize: "12px",
          padding: "4px 8px",
          fontWeight: "400",
          color: "white",
        }}
        place="bottom"
      />
    </core.UserContext.Provider>
  );
}
