import React, { useState, useEffect } from "react";
import JobDescHeader from "./JobDescHeader";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import Sprouts from "../sprouts.png";
const core = require("../../../../src/lib/core");

const JobDesc = () => {
  const location = useLocation();

  // const [formJobdetails, setFormJobdetails] = useState({});
  const [jobDetails, setJobDetails] = useState(null);
  const [criteria, setCriteria] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [description, setDescription] = useState("");
  const [education, setEducation] = useState("");

  const fetchJob = async () => {
    let json = await core.API(
      core.API_METHODS.GET,
      core.ORGANIZATION_API.GET_JOBS + location.pathname.split("/").at(-1),
      1,
      {},
    );
    console.log("Fetched Job Details:", json); // Log the fetched data
    setJobDetails(json.data); // Access the data property

    const criteriaArray = json.data.analytics.skills.map(
      (skillCategory) => skillCategory.criteria,
    );
    setCriteria(criteriaArray);

    const departmentName =
      json.data.departments.length > 0 ? json.data.departments[0].name : "";
    setDepartmentName(departmentName);

    setDescription(parseHtml(json.data.description || ""));

    setEducation(parseHtmledu(json.data.education || ""));
  };
  //  "/job_details/",

  useEffect(() => {
    fetchJob();
  }, [location]);

  return (
    <>
      <div className="h-full flex flex-col text-justify  overflow-auto">
        <div className="flex items-center justify-between py-5">
          <img
            src={Sprouts}
            alt="Sprouts Logo"
            className="ml-4 md:ml-24  w-40 h-auto  "
          />
          <div className="text-white mr-4 md:mr-20 hover:bg-blue-600 hover:no-underline inline-flex items-center bg-blue-900 px-3 py-2 rounded-md">
            <a href="https://www.sproutsai.com/" className="hover:no-underline">
              Company Website
            </a>
          </div>
        </div>
        <hr className="border-gray-400 " />
        <div className="md:p-20 md:pt-10">
          <JobDescHeader
            jobDetails={jobDetails}
            criteria={criteria}
            departmentName={departmentName}
            description={description}
            education={education}
          />
        </div>
        <footer className="bg-gray-200 pt-5  pb-4">
          <div className="container mx-auto flex items-center justify-center">
            <p className="text-gray-600 mr-1">Hire with</p>
            <img
              src={Sprouts}
              alt="Sprouts Logo"
              className="w-24 h-auto ml-4"
            />
          </div>
          <div className="text-center mt-2">
            <p className="text-sm">
              Read our{" "}
              <a
                href="https://www.sproutsai.com/privacy"
                className="text-blue-500 hover:underline"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default JobDesc;

function parseHtml(html) {
  const parsedHtml = parse(html.split("&lt;").join("<"));
  return parsedHtml;
}

function parseHtmledu(html) {
  const parsedHtml = parse(html.split("&lt;").join("<"));
  const textContent = parsedHtml.props.children || ""; // Get the text content within the parsed HTML
  return textContent.trim() ? parsedHtml : null; // Return parsed HTML if there's meaningful text, otherwise null
}
