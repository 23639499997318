import React, { useEffect, useState } from "react";
import $ from "jquery";
import InputBox from "../../../layouts/InputBox";
import { ScaleLoader } from "react-spinners";

export default function AutoCompleteInputLocation({
  list,
  idx,
  setvalue,
  initialArray,
  classText,
  autoSuggestion,
  onInputChange,
  setCoordinates,
  loading,
}) {
  const [hide, setHide] = useState(true);

  $(document).on("click", function (e) {
    if ($(e.target).is("#" + idx + ".auto-complete-input") === false) {
      setHide(true);
    } else {
      setHide(false);
    }
  });
  const [tempValue, setTempValue] = useState();
  const [searchList, setSearchList] = useState();

  const search = (e) => {
    const filteredItems = list?.filter((item) =>
      item?.name?.toLowerCase()?.includes(e?.toLowerCase()),
    );
    setSearchList(filteredItems);
  };

  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    if (!autoSuggestion) {
      setSearchList([searchValue]);
    }
    if (autoSuggestion) {
      setSearchList(list);
    }
  }, [searchValue, list]);

  return (
    <div className={idx} id={idx}>
      <InputBox
        placeholder="Search..."
        className={` auto-complete-input ${classText}`}
        size={"small"}
        onChange={(e) => {
          search(e.target.value);
          setSearchValue(e.target.value);
          setHide(false);
          if (onInputChange) onInputChange(e.target.value);
        }}
      />
      {loading ? (
        <div className="auto-complete-list mt-0  bg-white  no-list-style py-2  rounded-lg  shadow-menuOptionShadow z-10 border top-[44px] flex items-center justify-center py-5">
          <ScaleLoader height={14} color="#1369E9" loading={true} />
        </div>
      ) : (
        <>
          {searchList?.length > 0 && (
            <ul
              className={
                hide
                  ? "auto-complete-list hide"
                  : "auto-complete-list mt-0  bg-white  no-list-style py-2  rounded-lg  shadow-menuOptionShadow z-10 border top-[44px]"
              }
            >
              {searchList
                ?.filter((element) => !initialArray?.includes(element.name))
                .map((item, i) => (
                  <li
                    key={i}
                    className="match-value px-[20px] py-[10px]"
                    onClick={() => {
                      $("#" + idx + " .auto-complete-input").val("");
                      // console.log($("#" + idx + " .auto-complete-input"))
                      setvalue([...initialArray, item.name]);
                      setTempValue();
                      setHide(true);
                      setCoordinates((prev) => [...prev, item]);
                    }}
                  >
                    {item.name}
                  </li>
                ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
}
