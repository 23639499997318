import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Calmlogo from "../images.jpg";
import Sprouts from "../sprouts.png";

const JobSubmitted = () => {
  const location = useLocation();
  const { orgDetail, jobDetail, message } = location.state || {};
  console.log(message);
  return (
    <>
      <div className="h-full overflow-auto bg-gray-50">
        <div className="overflow-auto pt-0 mt-0">
          <div className="max-w-3xl mx-auto p-6 mt-5">
            <div className="flex flex-col md:flex-row justify-between items-center gap-3 mb-4">
              <div className="flex-shrink-0 mb-4 md:mb-0">
                <img
                  src={orgDetail?.logo}
                  alt="Logo"
                  className="ml-2 md:ml-0 w-auto max-w-[120px] rounded-lg"
                />
                <a
                  href="/job-post"
                  className="text-xs pl-3 pt-2 text-sky-600 font-semibold hover:text-sky-900 hover:underline focus:outline-none focus:ring-2 focus:ring-sky-600"
                >
                  (View All Jobs)
                </a>
              </div>
            </div>
            <h1 className="text-2xl md:text-3xl font-bold text-center md:text-left">
              {jobDetail?.name}
            </h1>
            <div className="text-gray-600 text-center md:text-left">
              <p>{jobDetail?.location[0]?.name}</p>
            </div>
          </div>
        </div>
        <div className="bg-gray-100">
          <div className="py-72 pt-20 mx-10 ">
            <div className="flex flex-col items-center justify-center">
              <p className="pt-4 text-base md:text-lg font-semibold text-gray-700 mb-4">
                {message === "Duplicate found"
                  ? "Already Responded!"
                  : "Application Submitted!"}
              </p>
              <button
                onClick={() => (window.location.href = "/job-post")}
                className="mt-4 mx-4 sm:mx-8 sm:px-0 text-white font-semibold hover:font-bold bg-blue-900 hover:bg-blue-600 px-6 py-1 rounded-md flex justify-center items-center w-full md:w-auto md:px-8 md:py-3 transition transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-600"
              >
                RETURN TO THE MAIN PAGE
              </button>
            </div>
          </div>
        </div>
        <footer className="bg-gray-200 pt-4 pb-4">
          <div className="container mx-auto flex items-center justify-center">
            <p className="text-gray-600 mr-1">Hire with</p>
            <img
              src={Sprouts}
              alt="Sprouts Logo"
              className="w-24 h-auto ml-4"
            />
          </div>
          <div className="text-center mt-2">
            <p className="text-sm">
              Read our{" "}
              <a
                href="https://www.sproutsai.com/privacy"
                className="text-blue-500 hover:underline"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default JobSubmitted;
