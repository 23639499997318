import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import Modal from "../../../modal/Modal";
import TemplateData from "../TemplateData";
import InputBox from "../../../../layouts/InputBox";
import { Editor } from "react-draft-wysiwyg";
import EditTemplate from "../EditTemplate";
import EditJobTemplate from "../EditJobTemplate";
import JobTempData from "../JobTempData";
import ShareJobTemplateModal from "../ShareJobTemplateModal"; // Import the ShareJobModal component
const core = require("../../../../lib/core");

const TemplateStructure2 = () => {
  const { reloadList, changeTabs, showTabsItem } = useOutletContext();
  const id = useParams().id;
  const nav = useNavigate();
  const [data, setData] = useState({});
  const [message, setMessage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false); // State to manage the visibility of the share modal

  console.log("showTabsItem", showTabsItem);

  useEffect(() => {
    setEdit(false);
    getData();
  }, [id]);

  const getData = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_JOB_TEMPLATE_Detail + id,
      1,
    );
    if (json?.data) {
      setData(json?.data);
      console.log("data job temp", json?.data);
    }
  };

  const deleteTemplate = async () => {
    let json = await core.API(
      core.API_METHODS.PUT,
      core.TEMPLATE_API.PUT_DELETE_JOB_TEMPLATE + id,
      1,
    );
    if (json?.message) {
      reloadList(showTabsItem);
      setMessage({ type: "success", message: json?.data });
      setTimeout(() => {
        setMessage({});
        nav("/templates/job");
        changeTabs(showTabsItem);
      }, 2500);
    }
  };

  return (
    <>
      <div className="w-full grow relative p-4 rounded-lg bg-white flex flex-col gap-4">
        {edit ? (
          <EditJobTemplate
            id={id}
            data={data}
            setEdit={setEdit}
            reload={() => {
              getData();
              reloadList();
            }}
          />
        ) : (
          <>
            <div className="w-auto absolute right-4 top-4 flex justify-between items-start gap-4">
              <div className="flex gap-4 w-auto relative">
                <button
                  className="btn-sec btn-md"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Edit"}
                  onClick={() => setEdit(edit ? revertChanges() : true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke={edit ? "blue" : "currentColor"}
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </button>
                <button
                  className="btn-sec btn-md"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Delete"}
                  onClick={() => setShowModal("DELETE")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="red"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </button>
                <button
                  className="btn-sec btn-md"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Share"}
                  onClick={() => setShareModalVisible(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    // fill="none"
                    viewBox="0 0 30 30"
                    strokeWidth={1.5}
                  >
                    <path d="M 23 3 A 4 4 0 0 0 19 7 A 4 4 0 0 0 19.09375 7.8359375 L 10.011719 12.376953 A 4 4 0 0 0 7 11 A 4 4 0 0 0 3 15 A 4 4 0 0 0 7 19 A 4 4 0 0 0 10.013672 17.625 L 19.089844 22.164062 A 4 4 0 0 0 19 23 A 4 4 0 0 0 23 27 A 4 4 0 0 0 27 23 A 4 4 0 0 0 23 19 A 4 4 0 0 0 19.986328 20.375 L 10.910156 15.835938 A 4 4 0 0 0 11 15 A 4 4 0 0 0 10.90625 14.166016 L 19.988281 9.625 A 4 4 0 0 0 23 11 A 4 4 0 0 0 27 7 A 4 4 0 0 0 23 3 z"></path>
                  </svg>
                </button>
              </div>
            </div>
            <JobTempData data={data} showType={true} />
          </>
        )}
      </div>
      {showModal == "DELETE" && (
        <Modal
          heading={"Delete template - " + data?.template_name}
          subheading={"Are you sure you want to delete this job template"}
          defaultButtons={{
            primaryLabel: "Delete",
            primaryClass: "!bg-red-800 !border-red-900 hover:!bg-red-900",
          }}
          onSave={deleteTemplate}
          modalDismiss={() => setShowModal(false)}
        >
          {Object?.entries(message)?.length > 0 && (
            <p
              style={
                message?.type == "error" ? { color: "red" } : { color: "green" }
              }
              className="block text-sm font-medium grow"
            >
              {message?.message}
            </p>
          )}
        </Modal>
      )}
      {shareModalVisible && (
        <ShareJobTemplateModal
          jobId={id}
          modalDismiss={() => setShareModalVisible(false)}
        />
      )}
    </>
  );
};

export default TemplateStructure2;
