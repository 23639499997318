function SvgIcon2({ className }) {
  return (
    <div className={`grow-0 shrink-0 basis-auto box-border w-5 h-5 text-[#252e4b] flex ${className}`}>
      <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" x="0" y="0">
        <g id="left icon_2" xmlns="http://www.w3.org/2000/svg">
          <path
            id="Vector_6"
            d="M2.5,7.74995v7.5c0,1.841 1.492,3.3334 3.333,3.3334h8.334c1.841,0 3.333,-1.4924 3.333,-3.3334v-7.5M2.5,7.74995v-1.25c0,-1.8409 1.492,-3.3333 3.333,-3.3333h8.334c1.841,0 3.333,1.4924 3.333,3.3333v1.25M2.5,7.74995h15M13.333,1.91665v2.5M6.667,1.91665v2.5"
            stroke="currentColor"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon2;
