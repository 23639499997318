import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ScheduleInterview from "../../applicant/ScheduleInterview";
import { CandidatePageContext } from "../../../../lib/context";
const core = require("../../../../lib/core");

const InterviewScheduledCard = ({ dataList }) => {
  const { setShowModal } = useContext(CandidatePageContext);
  const nav = useNavigate();
  const candidate = useSelector((state) => state?.jobCandidateMatch?.profile);
  const jobId = useSelector((state) => state?.jobCandidateMatch?.profile?.job);

  if (dataList?.length === 0)
    return (
      <div className="w-full mb-4 last:mb-0 flex justify-between">
        <div className="max-w-[50%] flex flex-col">
          <span className="text-xs text-gray-700 font-normal">
            No Interviews scheduled
          </span>
        </div>
      </div>
    );
  return dataList?.map((data, idx) => (
    <div
      className={`w-full mb-4 last:mb-0 flex justify-between ${idx == dataList?.length - 1 ? "" : "border-b-[2px] pb-4"}`}
      key={idx}
    >
      <div className="max-w-[50%] flex flex-col">
        <span className="text-xs text-gray-700 font-normal">
          {new Date(data?.date).toDateString() +
            " at " +
            new Date(data?.date).toLocaleTimeString()}
        </span>
        <div className="text-gray-900 text-base font-semibold">
          {data?.title}
        </div>
      </div>
      <div className="max-w-[50%] flex gap-2">
        {/* <button
          onClick={() =>
            setShowModal({ type: "interview", edit: true, data: data })
          }
          className="btn-sec btn-md"
        >
          Reschedule
        </button> */}

        <button
          // to={"/interview/" + data?.id}
          onClick={() => {
            nav(
              "/interview/" +
                data?._id +
                "?link=" +
                data?.link +
                "?link=&" +
                candidate?.matchProfile?._id +
                "&" +
                jobId,
              {
                state: {
                  jobID: jobId,
                  candID: candidate?.matchProfile?._id,
                },
              },
            );
            window.open(data?.link, "_blank");
          }}
          className="btn-primary btn-md"
        >
          Join now
        </button>
        {/* <span className="h-10 p-2.5">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.16667 7.16675L4.16667 15.5001C4.16667 17.341 5.65905 18.8334 7.5 18.8334H12.5C14.3409 18.8334 15.8333 17.341 15.8333 15.5001L15.8333 7.16675M11.6667 9.66675V14.6667M8.33333 9.66675L8.33333 14.6667M13.3333 4.66675L12.1614 2.90891C11.8523 2.44525 11.3319 2.16675 10.7747 2.16675L9.22531 2.16675C8.66805 2.16675 8.14767 2.44525 7.83856 2.90891L6.66667 4.66675M13.3333 4.66675L6.66667 4.66675M13.3333 4.66675H17.5M6.66667 4.66675L2.5 4.66675"
              stroke="#FF475D"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span> */}
      </div>
    </div>
  ));
};

export default InterviewScheduledCard;
