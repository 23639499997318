import React from "react";
import Modal from "../../modal/Modal";
import JobTempData from "../templates/JobTempData";
import { useNavigate } from "react-router-dom";

function JobTemplateDataModal({ modalDismiss, data, tempid }) {
  const nav = useNavigate();
  console.log(data);
  return (
    <Modal
      heading={"Template Data"}
      className={"md:max-w-[75%] "}
      // subheading={"View and edit the ."}
      onSave={(e) => {
        const url = `/templates/job/${tempid}`;
        const newTab = window.open(url, "_blank");
        newTab.focus();
      }}
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryClass: "bg-[#1369E9]",
        primaryLabel: (
          <span className="flex items-center gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M6.0002 2H4.2002C3.08009 2 2.51962 2 2.0918 2.21799C1.71547 2.40973 1.40973 2.71547 1.21799 3.0918C1 3.51962 1 4.08009 1 5.2002V12.8002C1 13.9203 1 14.4801 1.21799 14.9079C1.40973 15.2842 1.71547 15.5905 2.0918 15.7822C2.5192 16 3.07899 16 4.19691 16H11.8031C12.921 16 13.48 16 13.9074 15.7822C14.2837 15.5905 14.5905 15.2839 14.7822 14.9076C15 14.4802 15 13.921 15 12.8031V11M16 6V1M16 1H11M16 1L9 8"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>{" "}
            Edit
          </span>
        ),
      }}
    >
      <JobTempData data={data} />
      {/* <EditJobTemplate data={data} /> */}
    </Modal>
  );
}

export default JobTemplateDataModal;
