import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import Modal from "../../../modal/Modal";
import TemplateData from "../TemplateData";
import InputBox from "../../../../layouts/InputBox";
import { Editor } from "react-draft-wysiwyg";
import EditTemplate from "../EditTemplate";
const core = require("../../../../lib/core");

const TemplateStructure = () => {
  const { reloadList } = useOutletContext();
  const id = useParams().id;
  const nav = useNavigate();
  const [data, setData] = useState({});
  const [message, setMessage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);

  console.log("called");
  useEffect(() => {
    getData();
    setEdit(false);
  }, [id]);

  const getData = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_TEMPLATE_DATA + id,
      1,
    );
    if (json?.data) {
      setData(json?.data);
      //   setName(json?.response?.name);
      //   setSteps(json?.response?.steps);
    }
  };

  const deleteTemplate = async () => {
    let json = await core.API(
      core.API_METHODS.DELETE,
      core.TEMPLATE_API.DEL_TEMPLATE_DATA + id,
      1,
    );
    if (json?.message) {
      reloadList();
      setMessage({ type: "success", message: json?.data });
      setTimeout(() => {
        setMessage({});
        nav("/templates");
      }, 2500);
    }
  };

  return (
    <>
      <div className="w-full grow relative p-4 rounded-lg bg-white flex flex-col gap-4">
        {edit ? (
          <EditTemplate
            data={data}
            setEdit={setEdit}
            reload={() => {
              getData();
              reloadList();
            }}
          />
        ) : (
          <>
            <div className="w-auto absolute right-4 top-4 flex justify-between items-start gap-4">
              <div className="flex gap-4 w-auto relative">
                <button
                  className="btn-sec btn-md"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Edit"}
                  onClick={() => setEdit(edit ? revertChanges() : true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke={edit ? "blue" : "currentColor"}
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </button>
                <button
                  className="btn-sec btn-md"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Delete"}
                  onClick={() => setShowModal("DELETE")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="red"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <TemplateData data={data} showType={true} />
          </>
        )}
      </div>
      {showModal == "DELETE" ? (
        <Modal
          heading={"Delete template - " + data?.template_name}
          subheading={"Are you sure you want to delete this email template"}
          defaultButtons={{
            primaryLabel: "Delete",
            primaryClass: "!bg-red-800 !border-red-900 hover:!bg-red-900",
          }}
          onSave={deleteTemplate}
          modalDismiss={() => setShowModal(false)}
        >
          {Object?.entries(message)?.length > 0 && (
            <p
              style={
                message?.type == "error" ? { color: "red" } : { color: "green" }
              }
              className="block text-sm font-medium grow"
            >
              {message?.message}
            </p>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default TemplateStructure;
