import React, { useEffect, useState } from "react";
const core = require("../../../src/lib/core");
import Header from "./JobPostHeader";
import JobOpening from "./JobOpening";
import Footer from "./JobPostFooter";
import { useLocation } from "react-router-dom";

const Jobs = () => {
  const [jobList, setJobList] = useState([]);
  const [orgDetails, setOrgDetails] = useState({}); // [1]
  const location = useLocation();

  const fetchJob = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_ORG_JOB_LIST +
          `/${location?.pathname?.split("/")?.at(-1)}`,
        1,
        {},
      );
      if (json && json.data) {
        setJobList(json?.data?.content);
        setOrgDetails(json?.data?.organization);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchJob();
  }, []);

  // console.log(jobList);

  const theme = "blue";
  return (
    <>
      <div className="font-sans h-full  flex  flex-col overflow-auto">
        <div className="flex justify-center items-center md:mx-16 w-11/12 sm:mx-0 sm:px-0">
          <Header orgDetails={orgDetails} />
        </div>

        <div className="flex justify-center items-center md:mx-16 w-11/12 sm:mx-0 sm:px-0">
          <JobOpening jobList={jobList} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Jobs;
