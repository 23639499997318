import { convertToHTML } from "draft-convert";
import { ContentState, EditorState, Modifier, convertFromHTML } from "draft-js";
import React, { useEffect, useState } from "react";
import InputBox from "../../../layouts/InputBox";
import { Editor } from "react-draft-wysiwyg";
import { getCharacterCountWithoutHTML } from "./getCharCount";

const core = require("../../../lib/core");

const TemplateForm = ({ formData, setData, bodyCharCount }) => {
  const [body, setBody] = useState(() =>
    formData?.body
      ? EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(
              new String(formData?.body?.split("&lt;").join("<")).toString(),
            ),
          ),
        )
      : EditorState.createEmpty(),
  );
  const [variables, setVariables] = useState([]);

  useEffect(() => {
    getVariables();
  }, []);

  useEffect(() => {
    setData("body", convertToHTML(body?.getCurrentContent()));
    bodyCharCount(getCharacterCountWithoutHTML(body));
  }, [body]);

  const getVariables = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_VARIABLE_LIST,
      1,
    );
    if (json?.data) setVariables(json?.data);
  };

  const formatText = (text) => {
    text = text.slice(2, -2);
    text = text.replace(/_/g, " ");
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
  };

  const addText = (text) => {
    const currentContent = body.getCurrentContent();
    const currentSelection = body.getSelection();
    const newContent = Modifier.insertText(
      currentContent,
      currentSelection,
      text,
    );

    let newEditorState = EditorState.push(
      body,
      newContent,
      "insert-characters",
    );
    const newSelection = newEditorState.getSelection().merge({
      anchorOffset: currentSelection.getAnchorOffset() + text.length,
      focusOffset: currentSelection.getFocusOffset() + text.length,
    });
    newEditorState = EditorState.forceSelection(newEditorState, newSelection);
    setBody(newEditorState);
  };

  return (
    <>
      <div className="w-full">
        <label className="text-sm font-normal not-italic required" htmlFor="">
          Template name
        </label>
        <InputBox
          size={"small"}
          placeholder={"type here"}
          value={formData?.name}
          onChange={(e) => setData("name", e?.target?.value)}
        />
      </div>
      <div className="w-full">
        <label className="text-sm font-normal not-italic required" htmlFor="">
          Template type
        </label>
        <InputBox
          size={"small"}
          placeholder={"type here"}
          value={formData?.type}
          onChange={(e) => setData("type", e?.target?.value)}
        />
      </div>
      <div className="w-full">
        <label className="text-sm font-normal not-italic required" htmlFor="">
          Subject
        </label>
        <InputBox
          size={"small"}
          placeholder={"type here"}
          value={formData?.subject}
          onChange={(e) => setData("subject", e?.target?.value)}
        />
      </div>
      <div className="w-full relative">
        <label className="text-right text-sm font-normal not-italic required">
          Body
        </label>
        <Editor
          editorState={body}
          onEditorStateChange={setBody}
          wrapperClassName={"wrapper-class !w-[calc(100%-2px)]"}
          editorClassName="editor-class !min-h-[75px] !max-h-[800px] pb-12 rounded-b-lg pr-5"
          toolbarClassName="toolbar-class"
        />
        <div className="w-[calc(100%-2px)] rounded-b-lg mb-px ml-px flex absolute bottom-0 bg-[#f7f7fb] py-1 px-2 h-9 items-center gap-2 z-50">
          <h4>Add variables :</h4>
          {variables?.map((item, index) => (
            <button
              key={index}
              onClick={() => addText(item)}
              className="btn-sm btn-sec"
            >
              {formatText(item)}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default TemplateForm;
