import React, { useEffect, useState } from "react";
import companyLogo from "../../assets/Enterprise.png";
import { getTypeColor, getTypeName } from "./TypesButton";
import Description from "../job/description/Description";
import JobAnalytics from "../job/description/JobAnalytics";
var parse = require("html-react-parser");
function ReviewPage({
  formData,
  bonus,
  benefits,
  skills,
  preferArr,
  pipeline,
  EditFunction,
  JobTypeArray,
  departments,
  jobLocation,
}) {
  const [experience, setExperience] = useState("");
  console.log("formData", formData);
  var categories = [];
  if (preferArr?.length > 0) {
    categories = [...new Set(preferArr.map((d) => d?.category))];
  }
  useEffect(() => {
    formatExperienceValue();
  }, []);

  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }

  const formatExperienceValue = () => {
    setExperience("");
    if (
      typeof formData?.experienceLevel == "string" &&
      String(formData?.experienceLevel)?.length > 0
    ) {
      let matches = String(formData?.experienceLevel)?.match(/\d+/g);
      let exp =
        matches?.length == 1
          ? String(formData?.experienceLevel?.min) + "+ years"
          : matches?.length >= 1
            ? "Upto " + String(formData?.experienceLevel?.min) + " years"
            : formData?.experienceLevel;
      setExperience(exp);
      return;
    }
    // min present but not max
    if (
      +formData?.experienceLevel?.min > -1 &&
      String(formData?.experienceLevel?.min)?.length > 0 &&
      !(
        +formData?.experienceLevel?.max > -1 &&
        String(formData?.experienceLevel?.max)?.length > 0
      )
    ) {
      setExperience(String(formData?.experienceLevel?.min) + "+ years");
      return;
    }
    // min present but not max
    if (
      +formData?.experienceLevel?.max > -1 &&
      String(formData?.experienceLevel?.max)?.length > 0 &&
      !(
        +formData?.experienceLevel?.min > -1 &&
        String(formData?.experienceLevel?.min)?.length > 0
      )
    ) {
      setExperience(
        "Upto " + String(formData?.experienceLevel?.min) + " years",
      );
      return;
    }
    // setExperience(String(formData?.experienceLevel?.min) + " years");
    if (
      +formData?.experienceLevel?.min > -1 &&
      String(formData?.experienceLevel?.max)?.length > 0 &&
      +formData?.experienceLevel?.max > -1
    ) {
      if (formData?.experienceLevel?.min == formData?.experienceLevel?.max) {
        setExperience(String(formData?.experienceLevel?.min) + " years");
      }
      setExperience(
        String(formData?.experienceLevel?.min) +
          " - " +
          String(formData?.experienceLevel?.max) +
          " years",
      );
    }
  };

  const capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const [view, setView] = useState("all");

  const editsvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
      ></path>
    </svg>
  );

  const formattedSalaryRange = (formData) => {
    const currency = formData?.salary?.currency ?? "$";
    const minSalary = formData?.salary?.min?.toLocaleString("en-US") ?? ""; // Add commas
    const maxSalary = formData?.salary?.max?.toLocaleString("en-US") ?? ""; // Add commas

    if (minSalary && maxSalary) {
      return `${currency}${minSalary} - ${currency}${maxSalary}`;
    } else if (minSalary) {
      return `${currency}${minSalary}+`; // Only min provided
    } else if (maxSalary) {
      return `Up to ${currency}${maxSalary}`; // Only max provided
    } else {
      return ""; // No salary data available
    }
  };

  return (
    <div
      className={`${
        view == "all" ? "max-w-full grid grid-cols-12 gap-[60px]" : ""
      } bg-white rounded-xl mt-2.5  p-6`}
    >
      {view != "analytics" && (
        <div className={view == "all" ? "grow shrink col-span-7" : "w-full"}>
          {(departments ||
            formData?.workplace ||
            (formData?.job_type && formData?.job_type?.length > 0) ||
            (formData?.location && formData?.location?.length > 0) ||
            formData?.experience ||
            (formData?.equity &&
              Object.entries(formData?.equity)?.length > 1) ||
            (formData?.salary &&
              Object.entries(formData?.salary)?.length > 1 &&
              +formData?.salary?.min + +formData?.salary?.max > 1)) && (
            <div className="flex flex-wrap mb-5">
              {jobLocation && jobLocation?.length > 0 && (
                <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                  <span className="text-[#5F6989] text-sm min-w-max">
                    Location
                  </span>
                  <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                    {jobLocation
                      ?.map((d) => capitalize(d?.name ?? d))
                      ?.join(", ")}
                  </span>
                </div>
              )}
              {departments &&
                departments?.filter((item) => item?.status)?.length > 0 && (
                  <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                    <span className="text-[#5F6989] text-sm min-w-max">
                      Departments
                    </span>
                    <span className="text-[#252E4B] flex items-center text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                      {/* If new format - {name: '', status: true/false} */}
                      {Object?.keys(departments[0])?.includes("status")
                        ? departments
                            ?.filter((item) => item?.status === true)
                            ?.map((item) => item?.name)
                            ?.join(",")
                        : departments
                            ?.map((item) => capitalize(item))
                            ?.join(",")}

                      {/* <span
                        className="ml-[8px] cursor-pointer"
                        onClick={() => EditFunction("departments")}
                      >
                        {" "}
                        {editsvg}
                      </span> */}
                    </span>
                  </div>
                )}
              {formData?.workplace && (
                <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                  <span className="text-[#5F6989] text-sm min-w-max">
                    Workplace type
                  </span>
                  <span className="text-[#252E4B] flex items-center text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                    {formData?.workplace}
                    {/* <span
                      className="ml-[8px] cursor-pointer"
                      onClick={() => EditFunction("workplace")}
                    >
                      {" "}
                      {editsvg}
                    </span> */}
                  </span>
                </div>
              )}
              {JobTypeArray?.length > 0 &&
                (typeof JobTypeArray[0] == "string" ||
                (JobTypeArray?.length > 0 &&
                  Object?.keys(JobTypeArray[0])?.includes("status"))
                  ? JobTypeArray?.filter((item) => item?.status === true)
                      ?.length > 0
                  : true) && (
                  <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                    <span className="text-[#5F6989] text-sm min-w-max">
                      Job type
                    </span>
                    <span className="text-[#252E4B] flex items-center text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                      {/* If new format - {type: '', status: true/false} */}
                      {Object?.keys(JobTypeArray[0])?.includes("status")
                        ? JobTypeArray?.filter((item) => item?.status === true)
                            .map((d) => capitalize(d?.type))
                            ?.join(", ")
                        : JobTypeArray?.map((d) => capitalize(d))?.join(", ")}
                      {/* <span
                        className="ml-[8px] cursor-pointer"
                        onClick={() => EditFunction("jobType")}
                      >
                        {" "}
                        {editsvg}
                      </span> */}
                    </span>
                  </div>
                )}
              {formData?.experienceLevel && (
                <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                  <span className="text-[#5F6989] text-sm min-w-max">
                    Experience
                  </span>
                  <span className="text-[#252E4B] flex items-center text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                    {experience}
                    {/* <span
                      className="ml-[8px] cursor-pointer"
                      onClick={() => EditFunction("experience")}
                    >
                      {" "}
                      {editsvg}
                    </span> */}
                  </span>
                </div>
              )}
              {formData?.salary &&
              Object.entries(formData?.salary)?.length > 1 &&
              +formData?.salary?.min + +formData?.salary?.max > 1 ? (
                <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                  <span className="text-[#5F6989] text-sm min-w-max">
                    Salary
                  </span>
                  <span className="text-[#252E4B] flex items-center text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                    {typeof formData?.salary === "string"
                      ? formData?.salary
                      : formattedSalaryRange(formData)}
                    {/* <span
                      className="ml-[8px] cursor-pointer"
                      onClick={() => EditFunction("salary")}
                    >
                      {" "}
                      {editsvg}
                    </span> */}
                  </span>
                </div>
              ) : formData?.expectedSalaryMax || formData?.expectedSalaryMin ? (
                <div className=" flex items-center  rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                  <span className="text-[#5F6989] text-sm min-w-max">
                    Salary
                  </span>
                  <span className="text-[#252E4B] flex items-center text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                    {formData?.expectedSalaryMin +
                      " - " +
                      formData?.expectedSalaryMax}
                  </span>
                </div>
              ) : null}
              {formData?.equity &&
                Object.entries(formData?.equity)?.length > 1 && (
                  <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                    <span className="text-[#5F6989] text-sm min-w-max">
                      Equity
                    </span>
                    <span className="text-[#252E4B] flex items-center text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                      {typeof formData?.equity === "string"
                        ? formData?.equity
                        : formData?.equity?.min +
                          " - " +
                          formData?.equity?.max +
                          " %"}
                      {/* <span
                        className="ml-[8px] cursor-pointer"
                        onClick={() => EditFunction("equity")}
                      >
                        {" "}
                        {editsvg}
                      </span> */}
                    </span>
                  </div>
                )}

              {formData?.company && (
                <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                  <span className="text-[#5F6989] text-sm min-w-max">
                    Company
                  </span>
                  <span className="text-[#252E4B] flex items-center text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                    {formData?.company}
                    {/* <span className="ml-[8px] cursor-pointer"> {editsvg}</span> */}
                  </span>
                </div>
              )}
            </div>
          )}
          <div className="mb-5">
            <h4
              className={
                "text-[#16182F] text-[16px] not-italic font-medium " + ""
              }
            >
              Description
              <span
                className="ml-[8px] cursor-pointer"
                onClick={() => EditFunction("description")}
              >
                {" "}
                {editsvg}
              </span>
            </h4>
            <div className="mt-[12px]">
              <p className="text-textColor1 text-sm not-italic font-normal  job-description">
                {formData?.description ? (
                  formData?.description?.includes("<body>") ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formData?.description?.split("&lt;")?.join("<"),
                      }}
                    ></div>
                  ) : (
                    parse(formData?.description?.split("&lt;")?.join("<"))
                  )
                ) : (
                  "No Content"
                )}
              </p>
              {/* <div
      className={
        "text-textColor1 text-sm not-italic font-normal break-words " +
        ""
      }
    >
      {formData?.description ? (
        formData?.description?.includes("<body>") ? (
          <div
            dangerouslySetInnerHTML={{ __html: formData?.description }}
          ></div>
        ) : parse(formData?.description)?.length > 0 ? (
          parse(getText(formData?.description))
        ) : (
          "No Content"
        )
      ) : (
        "No Content"
      )}
    </div> */}
            </div>
          </div>
          {formData?.roles && parse(getText(formData?.roles))?.length > 0 && (
            <div className="mb-5">
              <h4
                className={
                  "text-[#16182F] text-[16px] not-italic font-medium " + ""
                }
              >
                Roles and responsibilities
                <span
                  className="ml-[8px] cursor-pointer"
                  onClick={() => EditFunction("roles")}
                >
                  {" "}
                  {editsvg}
                </span>
              </h4>
              <div className="mt-[12px]">
                <div
                  className={
                    "text-textColor1 text-sm not-italic font-normal " + ""
                  }
                >
                  {parse(getText(formData?.roles))}
                </div>
              </div>
            </div>
          )}

          {formData?.education?.length > 0 && (
            <div className="mb-5">
              <h4
                className={
                  "text-[#16182F] text-[16px] not-italic font-medium " + ""
                }
              >
                Experience and education
                <span
                  className="ml-[8px] cursor-pointer"
                  onClick={() => EditFunction("education")}
                >
                  {" "}
                  {editsvg}
                </span>
              </h4>
              <div className="mt-[12px]">
                <div
                  className={
                    "text-textColor1 text-sm not-italic font-normal " + ""
                  }
                >
                  {parse(getText(formData?.education))}
                </div>
              </div>
            </div>
          )}
          {formData?.bonus?.filter((d) => d.status == true).length > 0 && (
            <div className="mb-5">
              <h4
                className={
                  "text-[#16182F] text-[16px] not-italic font-medium " + ""
                }
              >
                Do you offer any other compensation?
                <span className="ml-[8px] cursor-pointer"> {editsvg}</span>
              </h4>
              <div className="mt-[12px]">
                <div className="flex flex-wrap ">
                  {bonus
                    ?.filter((d) => d.status == true)
                    .map((item) => (
                      <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                        <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                          {item?.benifits}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {benefits?.filter((d) => d.status == true).length > 0 && (
            <div>
              <h4
                className={
                  "text-[#16182F] text-[16px] not-italic font-medium " + ""
                }
              >
                Benefits offered
                <span className="ml-[8px] cursor-pointer"> {editsvg}</span>
              </h4>
              <div className="mt-[12px]">
                <div className="flex flex-wrap ">
                  {formData?.benefits
                    ?.filter((d) => d.status == true)
                    ?.map((item) => (
                      <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                        <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                          {item?.benifits}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}

          <div>
            <h4
              className={
                "text-[#16182F] text-[16px] not-italic font-medium mb-[16px]"
              }
            >
              Hiring pipeline
              <span className="ml-[8px] cursor-pointer"> {editsvg}</span>
            </h4>
            <div className="mt-[16px]">
              {pipeline?.map((skill, it) => (
                <div className="border-t border-l border-r last-of-type:border-b-[1px] border-[#dadae4] p-[10px] first-of-type:rounded-t-lg last-of-type:rounded-b-lg">
                  <div className="flex flex-col">
                    <span className="text-sm text-[#252e4b] font-medium ">
                      {skill?.stage}
                    </span>
                    <span className="text-[12px] text-[#8d98b1] font-normal mb-[8px]">
                      {skill?.text}
                    </span>
                    {skill?.subStage?.map((item, idx) => (
                      <div
                        key={idx}
                        className="w-full bg-bg1 border-t border-l border-r last-of-type:border-b-[1px] first-of-type:rounded-t-[8px] last-of-type:rounded-b-[8px] border-[#E1E1EE]  py-[8px] px-[11px]"
                      >
                        <span>{`${idx + 1}. ${item}`}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {view != "details" &&
        (skills?.length > 0 || formData?.preferCriteria?.length > 0) && (
          <JobAnalytics
            hide={true}
            classTxt={
              view == "all"
                ? "analytics-dashboard  col-span-5"
                : "analytics-dashboard  w-full gap-y-8 gap-x-4 flex flex-row items-start"
            }
            onlyAnalytics={false}
            job={{ skills: skills }}
            preferCriteria={formData?.preferCriteria}
          />
        )}
    </div>
  );
}

export default ReviewPage;
