import React from "react";
import { ANSWERTYPES } from "../../../lib/constants";

const capitalize = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);

const OneChoiceAnswer = ({ answer, candidateAnswer, index }) => {
  return answer?.map((opt, idx) => (
    <div className="w-full flex gap-2 items start">
      <input
        onChange={(e) => candidateAnswer(index, opt)}
        id={"qu" + index + idx}
        name={"qu" + index}
        type="radio"
        className="w-4 h-4 p-1"
      />
      <label htmlFor={"qu" + index + idx} className="text-sm font-normal mb-0">
        {capitalize(opt)}
      </label>
    </div>
  ));
};

const MCQAnswer = ({ question, answer, candidateAnswer, index }) => {
  const setAnswer = (event) => {
    question?.map((qu, idx) => {
      if (idx === index) {
        var answers = qu?.candidateResponse ? [...qu?.candidateResponse] : [];
        if (event?.target?.checked) answers.push(event?.target?.value);
        else answers = answers.filter((ans) => ans !== event?.target?.value);
        candidateAnswer(index, answers);
      }
    });
  };

  return answer?.map((opt, idx) => (
    <div className="w-full flex gap-2 items start">
      <input
        onChange={setAnswer}
        id={"qu" + index + idx}
        name={"qu" + index}
        value={opt}
        type="checkbox"
        className="w-4 h-4 p-1"
      />
      <label htmlFor={"qu" + index + idx} className="text-sm font-normal mb-0">
        {capitalize(opt)}
      </label>
    </div>
  ));
};

const LikertScale = ({ answer, candidateAnswer, index }) => {
  return (
    <div className="w-full flex gap-2 items-start justify-between">
      {answer?.map((opt, idx) => (
        <div className="flex flex-col gap-2 items-center justify-start">
          <input
            onChange={(e) => candidateAnswer(index, opt?.name)}
            id={"qu" + index + idx}
            name={"qu" + index}
            type="radio"
            className="w-4 h-4 p-1"
          />
          <label
            htmlFor={"qu" + index + idx}
            className="text-sm font-normal mb-0"
          >
            {capitalize(opt?.name)}
          </label>
        </div>
      ))}{" "}
    </div>
  );
};

const ParagraghAnswer = ({ index, candidateAnswer, type }) => {
  return (
    <div className="w-full">
      <input
        type={type}
        onChange={(e) => candidateAnswer(index, e.target.value)}
        className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
      />
    </div>
  );
};

const Answer = ({ answer, candidateAnswer, answerType, index, question }) => {
  switch (answerType) {
    case ANSWERTYPES?.RADIO:
      return (
        <OneChoiceAnswer
          index={index}
          answer={answer}
          candidateAnswer={candidateAnswer}
        />
      );
    case ANSWERTYPES?.YESNO:
      return (
        <OneChoiceAnswer
          index={index}
          answer={answer}
          candidateAnswer={candidateAnswer}
        />
      );
    case ANSWERTYPES?.MCQ:
      return (
        <MCQAnswer
          question={question}
          index={index}
          answer={answer}
          candidateAnswer={candidateAnswer}
        />
      );
    case ANSWERTYPES?.LIKERT:
      return (
        <LikertScale
          index={index}
          answer={answer}
          candidateAnswer={candidateAnswer}
        />
      );
    default:
      return (
        <ParagraghAnswer
          index={index}
          type={answerType}
          candidateAnswer={candidateAnswer}
        />
      );
  }
};

export default Answer;
