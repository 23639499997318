import React, { useEffect, useState } from "react";
import Dropdown from "../../../layouts/Dropdown";
import { convertFromHTML, ContentState, EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import InputBox from "../../../layouts/InputBox";
const core = require("../../../lib/core");

const TemplateCRUD = ({
  steps,
  setSteps,
  templates,
  onSubmit,
  onCancel,
  submitDisabled = false,
  setStepsUpdated = false,
  btnGrpClass = "",
  message,
  setMessage,
}) => {
  const [edit, setEdit] = useState(-1);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState(() => EditorState.createEmpty());
  const [variables, setVariables] = useState([]);
  const [templateMsg, setTemplateMsg] = useState({});
  const [prevTemplateId, setPrevTemplateId] = useState("");

  useEffect(() => {
    getVariables();
  }, []);

  const getVariables = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_VARIABLE_LIST,
      1,
    );
    if (json?.data) setVariables(json?.data);
  };

  const getCharacterCountWithoutHTML = (editorState) => {
    // Convert EditorState to HTML
    const html = convertToHTML(editorState?.getCurrentContent());

    // Create a new DOM parser
    const parser = new DOMParser();

    // Parse the HTML
    const doc = parser?.parseFromString(html, "text/html");

    // Get the text content and return its length
    const textContent = doc?.body?.textContent;
    const trimmedTextContent = textContent?.trim().replace(/\s\s+/g, " ");

    return trimmedTextContent?.length;
  };

  const formatText = (text) => {
    text = text.slice(2, -2);
    text = text.replace(/_/g, " ");
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
  };

  const addText = (text) => {
    const currentContent = body.getCurrentContent();
    const currentSelection = body.getSelection();
    const newContent = Modifier.insertText(
      currentContent,
      currentSelection,
      text,
    );

    let newEditorState = EditorState.push(
      body,
      newContent,
      "insert-characters",
    );
    const newSelection = newEditorState.getSelection().merge({
      anchorOffset: currentSelection.getAnchorOffset() + text.length,
      focusOffset: currentSelection.getFocusOffset() + text.length,
    });
    newEditorState = EditorState.forceSelection(newEditorState, newSelection);
    setBody(newEditorState);
  };

  const addStep = (template, idx) => {
    const bodyContent = body,
      subjectContent = subject;
    let temp = [...steps];
    temp.splice(
      idx + 1,
      0,
      { wait_time: 0, action: "WAIT" },
      {
        action: "SEND_FOLLOW_UP_EMAIL",
        template_id: template?._id,
        template: template,
      },
    );
    setSteps(temp);

    if (setStepsUpdated) setStepsUpdated(true);
    if (edit > -1 && edit > idx) {
      setEdit(edit + 2);
      setSubject(subjectContent);
      setBody(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(
              new String(bodyContent?.split("&lt;").join("<")).toString(),
            ),
          ),
        ),
      );
    }
  };

  const removeStep = (idx) => {
    const bodyContent = body,
      subjectContent = subject;
    let temp = [...steps];
    if (idx > 0) temp.splice(idx - 1, 2);
    else if (idx == 0) {
      temp.splice(idx, 2);
      temp[0].action = "SEND_TEMPLATE";
    }
    setSteps(temp);
    if (edit > -1) {
      if (edit == idx || idx >= temp?.length) setEdit(-1);
      else if (edit > idx) {
        setEdit(edit - 2);
        setSubject(subjectContent);
        setBody(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(
                new String(bodyContent?.split("&lt;").join("<")).toString(),
              ),
            ),
          ),
        );
      }
    }

    if (setStepsUpdated) setStepsUpdated(true);
  };

  const enableEdit = (i) => {
    if (edit == i) {
      revertChanges();
      return;
    }
    setEdit(i);
    setSubject(steps[i]?.template?.subject);
    setBody(
      EditorState?.createWithContent(
        ContentState?.createFromBlockArray(
          convertFromHTML(
            new String(
              steps[i]?.template?.body?.split("&lt;").join("<"),
            ).toString(),
          ),
        ),
      ),
    );
  };

  const swapStep = (idx, template) => {
    let temp = [...steps];
    temp[idx].template_id = template?._id;
    temp[idx].template = template;
    setSteps(temp);
    setSubject(temp[idx]?.template?.subject);
    setBody(
      EditorState?.createWithContent(
        ContentState?.createFromBlockArray(
          convertFromHTML(
            new String(
              temp[idx]?.template?.body?.split("&lt;").join("<"),
            ).toString(),
          ),
        ),
      ),
    );
    if (setStepsUpdated) setStepsUpdated(true);
  };

  const changeWaitTime = (iter, value) => {
    if (setStepsUpdated) setStepsUpdated(true);
    let temp = [...steps];
    temp[iter].wait_time = value;
    setSteps(temp);
  };

  const revertChanges = async () => {
    setTemplateMsg({});
    const prevId =
      prevTemplateId?.length > 0 ? prevTemplateId : steps[edit]?.template_id;
    const newTemplate = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_TEMPLATE_DATA + prevId,
      1,
    );
    swapStep(edit, newTemplate?.data);
    setPrevTemplateId("");
    setEdit(-1);
  };

  const editTemplate = async () => {
    if (
      subject?.trim()?.length <= 0 ||
      getCharacterCountWithoutHTML(body) <= 0
    ) {
      setTemplateMsg({
        type: "error",
        message: "Required data cannot be empty",
      });
      return;
    }
    let json = await core.API(
      core.API_METHODS.PUT,
      core.TEMPLATE_API.PUT_TEMPLATE_DATA + steps[edit]?.template_id,
      1,
      {
        template_name: steps[edit]?.template?.template_name,
        subject: subject,
        body: convertToHTML(body?.getCurrentContent()),
        updateType: "sequence",
      },
    );
    if (json?.data) {
      setPrevTemplateId("");
      swapStep(edit, json?.data);
      setTemplateMsg({
        type: "success",
        message: "Template updated successfully",
      });
      setTimeout(() => {
        setTemplateMsg({});
        setEdit(-1);
      }, 1500);
    } else {
      setTemplateMsg({
        type: "error",
        message:
          json?.error?.message ??
          "Unable to update the template. Please try again",
      });
    }
  };

  return (
    <>
      {steps?.map((iter, i) =>
        iter?.template_id ? (
          <div
            className={`w-full border-gray-500 ${i == steps?.length - 1 ? "" : "border-b"} flex flex-col gap-3 pb-4`}
            key={i}
          >
            <div className="w-full flex justify-between items-center">
              {edit == i ? (
                <Dropdown
                  removeDefaultBtnStyle={true}
                  buttonStyle={
                    "flex gap-2 items-center text-gray-800 text-base not-italic font-normal hover:text-blue-800 cursor-pointer"
                  }
                  listAlign="left"
                  buttonLabel={
                    <span
                      className="flex gap-2 items-center"
                      data-tooltip-id="tooltip"
                      data-tooltip-content={"Change current step"}
                    >
                      {iter?.template?.template_name}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  }
                  dropdownStyle={"!top-8"}
                  customList={true}
                  dropdownItems={
                    templates?.length > 0
                      ? templates?.map((item, key) => (
                          <li
                            key={"template" + (key + 1)}
                            className={`w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 `}
                            onClick={() => {
                              setPrevTemplateId(iter?.template_id);
                              swapStep(i, item);
                            }}
                          >
                            {item?.template_name}
                          </li>
                        ))
                      : [
                          <li
                            onClick={() => nav("/templates/new")}
                            className="w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 "
                          >
                            Add new template
                          </li>,
                        ]
                  }
                />
              ) : (
                <h2 className="text-gray-800 text-base not-italic font-normal ">
                  {iter?.template?.template_name}
                </h2>
              )}
              <div className="flex gap-4 w-auto relative">
                <Dropdown
                  buttonLabel={
                    <svg
                      data-tooltip-id="tooltip"
                      data-tooltip-content={"Add new step"}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                  removeDefaultBtnStyle={true}
                  buttonStyle={""}
                  dropdownStyle={"!top-8 !bottom-auto"}
                  customList={true}
                  dropdownItems={
                    templates?.length > 0
                      ? templates?.map((item, key) => (
                          <li
                            key={"template" + key}
                            className={`w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 `}
                            onClick={() => addStep(item, i)}
                          >
                            {item?.template_name}
                          </li>
                        ))
                      : [
                          <li
                            onClick={() => nav("/templates/new")}
                            className="w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 "
                          >
                            Add new template
                          </li>,
                        ]
                  }
                />
                <button
                  data-tooltip-id="tooltip"
                  data-tooltip-content={
                    edit > -1 && edit != i
                      ? "Please save the current template before editing another"
                      : "Edit this template"
                  }
                  disabled={edit > -1 && edit != i}
                  onClick={() => enableEdit(i)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke={edit == i ? "blue" : "currentColor"}
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </button>
                <button
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Remove this step"}
                  disabled={steps?.length == 1}
                  className="disabled:opacity-40 disabled:cursor-default disabled:pointer-events-none"
                  onClick={() => removeStep(i)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="red"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="w-full">
              <label
                className="text-sm font-normal not-italic required"
                htmlFor=""
              >
                Subject
              </label>
              <InputBox
                className={
                  edit != i
                    ? "opacity-70 cursor-default pointer-events-none"
                    : ""
                }
                size={"small"}
                type="text"
                value={edit == i ? subject : iter?.template?.subject}
                onChange={(e) => setSubject(e?.target?.value)}
              />
            </div>
            <div className="w-full relative">
              <label className="text-right text-sm font-normal not-italic required">
                Body
              </label>
              <Editor
                editorState={
                  edit == i
                    ? body
                    : EditorState?.createWithContent(
                        ContentState?.createFromBlockArray(
                          convertFromHTML(
                            new String(
                              iter?.template?.body?.split("&lt;").join("<"),
                            ).toString(),
                          ),
                        ),
                      )
                }
                onEditorStateChange={setBody}
                wrapperClassName={
                  edit == i
                    ? "wrapper-class !w-[calc(100%-2px)]"
                    : "wrapper-class !w-[calc(100%-2px)] opacity-70 cursor-default"
                }
                editorClassName="editor-class !min-h-[75px] !max-h-[500px] pb-12 rounded-b-lg overflow-y-auto pr-5 ![scrollbar-width:thin] !overflow-x-hidden"
                toolbarClassName="toolbar-class  pointer-events-none"
              />
              <div
                className={
                  edit == i
                    ? "w-[calc(100%-2px)] rounded-b-lg mb-px ml-px z-50 flex absolute bottom-0 bg-[#f7f7fb] py-1 px-2 h-9 items-center gap-2"
                    : "hidden"
                }
              >
                <h4>Add variables :</h4>
                {variables?.map((item, index) => (
                  <button
                    key={"var" + (index + 1)}
                    onClick={() => addText(item)}
                    className="btn-sm btn-sec"
                  >
                    {formatText(item)}
                  </button>
                ))}
              </div>
            </div>
            {edit == i ? (
              <div className="w-full flex items-end gap-4 flex-row-reverse self-end">
                <button
                  onClick={editTemplate}
                  className="btn-md btn-primary disabled:opacity-50 disabled:pointer-events-none"
                >
                  Update
                </button>
                <button
                  onClick={() => revertChanges(iter?.template_id)}
                  className="btn-md btn-sec disabled:opacity-50 disabled:pointer-events-none"
                >
                  Cancel
                </button>
                {Object?.entries(templateMsg)?.length > 0 && (
                  <p
                    style={
                      templateMsg?.type == "error"
                        ? { color: "red" }
                        : { color: "green" }
                    }
                    className="block text-sm font-medium grow"
                  >
                    {templateMsg?.message}
                  </p>
                )}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="w-full border-gray-500 border-b flex flex-col gap-3">
            <h2 className="text-gray-700 text-base not-italic font-normal ">
              Wait time before next step
            </h2>
            <div className="w-full flex gap-2 mb-4 items-center">
              <label className="text-right text-sm font-normal not-italic  !mb-0">
                Follow up in
              </label>
              <InputBox
                className={"w-auto"}
                min={0}
                size={"small"}
                type="number"
                value={+iter?.wait_time}
                onChange={(e) => changeWaitTime(i, +e?.target?.value)}
              />
              <label className="text-right text-sm font-normal not-italic !mb-0">
                days
              </label>
            </div>
          </div>
        ),
      )}
      <div
        className={
          "w-full flex items-center gap-4 flex-row-reverse self-end " +
          btnGrpClass
        }
      >
        <button
          disabled={submitDisabled || edit > -1}
          onClick={() => {
            if (edit > -1) {
              setMessage({
                type: "error",
                message:
                  "Please save the changes in template before submitting the sequence",
              });
              return;
            }
            onSubmit();
          }}
          className="btn-md btn-primary"
        >
          Save
        </button>
        <button
          onClick={() => {
            setMessage({});
            setTemplateMsg({});
            onCancel();
          }}
          className="btn-md btn-sec"
        >
          Cancel
        </button>
        {Object?.entries(message)?.length > 0 && (
          <p
            style={
              message?.type == "error" ? { color: "red" } : { color: "green" }
            }
            className="block text-sm font-medium grow"
          >
            {message?.message}
          </p>
        )}
      </div>
    </>
  );
};

export default TemplateCRUD;
