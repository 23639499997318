import React, { useEffect, useState } from "react";
import axios from "axios";

const JobApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    resume: "",
  });

  useEffect(() => {
    // Load LinkedIn SDK
    const script = document.createElement("script");
    script.src = "https://platform.linkedin.com/in.js";
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.innerHTML = `
            api_key: ${process.env.REACT_APP_LINKEDIN_CLIENT_ID}
            authorize: true
        `;
    script.onload = () => {
      console.log("LinkedIn SDK Loaded");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleProfileData = (profile) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: profile.localizedFirstName + " " + profile.localizedLastName,
      email: profile.elements[0]["handle~"].emailAddress,
    }));
  };

  const handleLinkedInApply = () => {
    if (window.IN && window.IN.User) {
      window.IN.User.authorize(() => {
        window.IN.API.Raw(
          "/me?projection=(id,localizedFirstName,localizedLastName)",
        ).result((profile) => {
          window.IN.API.Raw(
            `/emailAddress?q=members&projection=(elements*(handle~))`,
          ).result((email) => {
            profile.elements = email.elements;
            handleProfileData(profile);
          });
        });
      });
    } else {
      console.error("LinkedIn SDK not loaded");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/submitApplication", formData);
      console.log("Application submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting application:", error);
    }
  };

  return (
    <div className="w-full max-w-lg mx-auto p-4">
      <form
        onSubmit={handleFormSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter your name"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter your email"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="resume"
            className="block text-gray-700 font-bold mb-2"
          >
            Resume
          </label>
          <input
            type="file"
            id="resume"
            name="resume"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <button
            type="button"
            onClick={handleLinkedInApply}
            className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Apply with LinkedIn
          </button>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit Application
          </button>
        </div>
      </form>
    </div>
  );
};

export default JobApplicationForm;
