import React, { useEffect, useState } from "react";
import Modal from "../../../modal/Modal";
import InputBox from "../../../../layouts/InputBox";
import SelectBox from "../../../../layouts/SelectBox";
import { ScaleLoader } from "react-spinners";

const core = require("../../../../lib/core");

function ChangeCandidatesDataModal({
  sourcecomp,
  candidateDetail,
  jobId,
  modalDismiss,
  loadData,
}) {
  const [name, setName] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [title, setTitle] = useState("");
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [linkedinurls, setLinkedinurls] = useState("");
  const [source, setSource] = useState("");
  const [remoteSource, setRemoteSource] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (candidateDetail) {
      setName(candidateDetail?.name || "");
      setLast_Name(candidateDetail?.last_name || "");
      setTitle(candidateDetail?.title || "");
      setEmails(candidateDetail?.email || []);
      setPhones(candidateDetail?.phone || []);
      setLinkedinurls(candidateDetail?.linkedln || "");
      setSource(candidateDetail?.source || "");
      setRemoteSource(candidateDetail?.remote_source || "");
    }
  }, [candidateDetail]);

  const updateNotes = async () => {
    setLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.PUT_UPDATE_CANDIDATE_DETAILS +
          candidateDetail?.id +
          "/" +
          candidateDetail?.matchProfileId,
        1,
        {
          first_name: name,
          last_name,
          title,
          email: emails?.length > 0 && emails[0]?.length > 1 ? emails : [],
          phone: phones?.length > 0 && phones[0]?.length > 1 ? phones : [],
          linkedin: linkedinurls,
          source,
          remoteSource,
        },
      );
      if (json?.data) {
        setLoading(false);
        loadData(json?.data);
        modalDismiss();
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  return (
    <Modal
      heading={"Edit candidate detail"}
      onSave={updateNotes}
      subheading={
        "You can edit the candidate detail if any information parsed wrong"
      }
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          "Save"
        ),
      }}
    >
      <div>
        <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
          First name
        </label>
        <InputBox value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div>
        <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
          Last name
        </label>
        <InputBox
          value={last_name}
          onChange={(e) => setLast_Name(e.target.value)}
        />
      </div>
      <div>
        <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
          Title
        </label>
        <InputBox value={title} onChange={(e) => setTitle(e.target.value)} />
      </div>
      <div>
        {emails.length > 1 ? (
          <>
            {emails.map((email, index) => (
              <>
                <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
                  Email {index + 1}
                </label>
                <InputBox
                  key={index}
                  value={email}
                  onChange={(e) => {
                    const newEmails = [...emails];
                    newEmails[index] = e.target.value;
                    setEmails(newEmails);
                  }}
                />
              </>
            ))}
          </>
        ) : (
          <>
            <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
              Email{" "}
            </label>
            <InputBox
              value={emails[0] ?? ""}
              onChange={(e) => {
                const newEmails = [...emails];
                newEmails[0] = e.target.value;
                setEmails(newEmails);
              }}
            />
          </>
        )}
      </div>
      <div>
        {phones.length > 1 ? (
          <>
            {phones.map((phone, index) => (
              <>
                <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
                  Phone {index + 1}
                </label>
                <InputBox
                  key={index}
                  value={phone}
                  onChange={(e) => {
                    const newPhones = [...phones];
                    newPhones[index] = e.target.value;
                    setPhones(newPhones);
                  }}
                />
              </>
            ))}
          </>
        ) : (
          <>
            <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
              Phone{" "}
            </label>
            <InputBox
              value={phones[0] ?? ""}
              onChange={(e) => {
                const newPhones = [...phones];
                newPhones[0] = e.target.value;
                setPhones(newPhones);
              }}
            />
          </>
        )}
      </div>
      <div>
        <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
          Linkedin url
        </label>
        <InputBox
          value={linkedinurls}
          onChange={(e) => setLinkedinurls(e.target.value)}
        />
      </div>
      {!sourcecomp && (
        <>
          <div>
            <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
              Source
            </label>
            <SelectBox
              defaultValue={source}
              options={[
                "Greenhouse",
                "Applied",
                "Sprouts",
                "Recruiter",
                "None",
              ]}
              onChange={(e) => setSource(e.target.value)}
            />
          </div>

          <div>
            <label className="mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic">
              Remote Source
            </label>
            <SelectBox
              defaultValue={remoteSource}
              options={["Internal Applicant", "Referral", "None"]}
              onChange={(e) => setRemoteSource(e.target.value)}
            />
          </div>
        </>
      )}
    </Modal>
  );
}

export default ChangeCandidatesDataModal;
