import React, { useEffect, useState } from "react";
import InputBox from "../../layouts/InputBox";
import SelectBox from "../../layouts/SelectBox";

// const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const timeZones = ["UTC", "GMT", "PST", "CST", "EST"]; // You can add more time zones as needed

const ToggleButton = ({ isEnabled, toggle }) => {
  return (
    <div
      onClick={toggle}
      className={`relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer ${isEnabled ? "bg-[#1369E9]" : "bg-gray-300"}`}
    >
      <span
        className={`transform transition ease-in-out duration-200 ${isEnabled ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 bg-white rounded-full`}
      ></span>
    </div>
  );
};

function Avavilabiltycheck() {
  const [availability, setAvailability] = useState({
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  });
  const [timeZone, setTimeZone] = useState("UTC");
  const [daysOfWeek, setDaysOfWeek] = useState([]);

  useEffect(() => {
    const availability = JSON.parse(localStorage.getItem("availability"));
  }, []);

  useEffect(() => {
    const today = new Date();
    const days = [];
    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      days.push(nextDate.toLocaleDateString("en-US"));
    }
    setDaysOfWeek(days);
  }, []);

  console.log("daysOfWeek:", daysOfWeek);

  const handleToggleDay = (day) => {
    if (availability[day].length === 0) {
      setAvailability({
        ...availability,
        [day]: [{ from: "", to: "" }],
      });
    } else {
      setAvailability({
        ...availability,
        [day]: [],
      });
    }
  };

  const handleTimeChange = (day, index, type, value) => {
    const newTimes = availability[day].map((slot, i) =>
      i === index ? { ...slot, [type]: value } : slot,
    );
    setAvailability({
      ...availability,
      [day]: newTimes,
    });
  };

  const addTimeSlot = (day) => {
    setAvailability({
      ...availability,
      [day]: [...availability[day], { from: "", to: "" }],
    });
  };

  const deleteTimeSlot = (day, index) => {
    const newTimes = availability[day].filter((_, i) => i !== index);
    setAvailability({
      ...availability,
      [day]: newTimes,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const candidateAvailability = { availability, timeZone };
    console.log("Candidate Availability:", candidateAvailability);
  };

  // const [availability, setAvailability] = useState({
  //     Sunday: [],
  //     Monday: [],
  //     Tuesday: [],
  //     Wednesday: [],
  //     Thursday: [],
  //     Friday: [],
  //     Saturday: []
  // });
  // const [timeZone, setTimeZone] = useState('UTC');

  // const handleToggleDay = (day) => {
  //     if (availability[day].length === 0) {
  //         setAvailability({
  //             ...availability,
  //             [day]: [{ from: '', to: '' }]
  //         });
  //     } else {
  //         setAvailability({
  //             ...availability,
  //             [day]: []
  //         });
  //     }
  // };

  // const handleTimeChange = (day, index, type, value) => {
  //     const newTimes = availability[day].map((slot, i) => (
  //         i === index ? { ...slot, [type]: value } : slot
  //     ));
  //     setAvailability({
  //         ...availability,
  //         [day]: newTimes
  //     });
  // };

  // const addTimeSlot = (day) => {
  //     setAvailability({
  //         ...availability,
  //         [day]: [...availability[day], { from: '', to: '' }]
  //     });
  // };

  // const deleteTimeSlot = (day, index) => {
  //     const newTimes = availability[day].filter((_, i) => i !== index);
  //     setAvailability({
  //         ...availability,
  //         [day]: newTimes
  //     });
  // };

  // const handleSubmit = (event) => {
  //     event.preventDefault();
  //     const candidateAvailability = { availability, timeZone };
  //     console.log('Candidate Availability:', candidateAvailability);
  // };

  return (
    <div className="w-full h-full mx-auto p-4 bg-[#F3F5F9]">
      <h2 className="text-2xl font-bold mb-4">
        Candidate Availability for Interview
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-3 gap-8">
          <div className="rounded-[12px] bg-white shadow-PipelineCardsShadow py-[18px] col-span-2 p-[28px]">
            {daysOfWeek.map((day) => (
              <div key={day} className="mb-4 flex">
                <label
                  className="flex items-center space-x-4 w-[250px]
                                "
                >
                  <ToggleButton
                    isEnabled={availability[day]?.length > 0}
                    toggle={() => handleToggleDay(day)}
                  />
                  <span className=" text-sm text-gray-800 not-italic font-normal">
                    {day}
                  </span>
                </label>
                {availability[day].length > 0 && (
                  <div className="mt-2 space-y-2 ">
                    {availability[day].map((slot, index) => (
                      <div key={index} className="flex space-x-4 items-center">
                        <InputBox
                          type="time"
                          value={slot.from}
                          onChange={(e) =>
                            handleTimeChange(day, index, "from", e.target.value)
                          }
                          className="border rounded p-2 w-full"
                          size="small"
                          width="200px"
                        />
                        <InputBox
                          type="time"
                          value={slot.to}
                          onChange={(e) =>
                            handleTimeChange(day, index, "to", e.target.value)
                          }
                          className="border rounded p-2 w-full"
                          size="small"
                          width="200px"
                        />
                        <div className="flex gap-4">
                          <button
                            type="button"
                            onClick={() => deleteTimeSlot(day, index)}
                            className=" w-[32px] h-[32px] 

                                                        ant-dropdown-trigger candidate-other-status-button border-button rounded-lg border  aspect-square border-gray-500 bg-white  flex items-center justify-center

                                                        "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <g id="left icon">
                                <path
                                  id="Vector"
                                  d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                                  stroke="#FF475D"
                                  strokeLinecap="round"
                                ></path>
                              </g>
                            </svg>
                          </button>
                          {/* {availability[day].length - 1 === index && ( */}
                          <button
                            type="button"
                            onClick={() => addTimeSlot(day)}
                            className=" w-[32px] h-[32px] ant-dropdown-trigger candidate-other-status-button border-button rounded-lg border  aspect-square border-gray-500 bg-white  flex items-center justify-center"
                          >
                            +
                          </button>
                          {/* // )} */}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="col-span-1 rounded-[12px] bg-white shadow-PipelineCardsShadow p-[18px] ">
            <label className="text-sm text-gray-800 not-italic font-normal">
              Select Time Zone
            </label>
            <SelectBox
              value={timeZone}
              onChange={(e) => setTimeZone(e.target.value)}
              options={timeZones}
              size={"small"}
            />
          </div>
        </div>

        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Submit Availability
        </button>
      </form>
    </div>
    // <div className="w-full h-full mx-auto p-4 bg-[#F3F5F9]">
    //     <h2 className="text-2xl font-bold mb-4">Candidate Availability for Interview</h2>
    //     <form onSubmit={handleSubmit} className="space-y-4">
    //         <div className='grid grid-cols-3 gap-8'>
    //             <div className='rounded-[12px] bg-white shadow-PipelineCardsShadow py-[18px] col-span-2 p-[28px]'>
    //                 {daysOfWeek.map((day) => (
    //                     <div key={day} className="mb-4 flex">
    //                         <label className="flex items-center space-x-4 w-[250px]
    //                         ">
    //                             <ToggleButton
    //                                 isEnabled={availability[day].length > 0}
    //                                 toggle={() => handleToggleDay(day)}
    //                             />
    //                             <span className=" text-sm text-gray-800 not-italic font-normal">{day}</span>
    //                         </label>
    //                         {availability[day].length > 0 && (
    //                             <div className="mt-2 space-y-2 ">
    //                                 {availability[day].map((slot, index) => (
    //                                     <div key={index} className="flex space-x-4 items-center">
    //                                         <InputBox
    //                                             type="time"
    //                                             value={slot.from}
    //                                             onChange={(e) => handleTimeChange(day, index, 'from', e.target.value)}
    //                                             className="border rounded p-2 w-full"
    //                                             size="small"
    //                                             width="200px"
    //                                         />
    //                                         <InputBox
    //                                             type="time"
    //                                             value={slot.to}
    //                                             onChange={(e) => handleTimeChange(day, index, 'to', e.target.value)}
    //                                             className="border rounded p-2 w-full"
    //                                             size="small"
    //                                             width="200px"
    //                                         />
    //                                         <div className='flex gap-4'>
    //                                             <button
    //                                                 type="button"
    //                                                 onClick={() => deleteTimeSlot(day, index)}
    //                                                 className=" w-[32px] h-[32px]

    //                                                 ant-dropdown-trigger candidate-other-status-button border-button rounded-lg border  aspect-square border-gray-500 bg-white  flex items-center justify-center

    //                                                 "
    //                                             >
    //                                                 <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><g id="left icon"><path id="Vector" d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602" stroke="#FF475D" stroke-linecap="round"></path></g></svg>
    //                                             </button>
    //                                             {/* {availability[day].length - 1 === index && ( */}
    //                                             <button
    //                                                 type="button"
    //                                                 onClick={() => addTimeSlot(day)}
    //                                                 className=" w-[32px] h-[32px] ant-dropdown-trigger candidate-other-status-button border-button rounded-lg border  aspect-square border-gray-500 bg-white  flex items-center justify-center"
    //                                             >
    //                                                 +
    //                                             </button>
    //                                             {/* // )} */}
    //                                         </div>
    //                                     </div>
    //                                 ))}
    //                             </div>
    //                         )}
    //                     </div>
    //                 ))}
    //             </div>

    //             <div className='col-span-1 rounded-[12px] bg-white shadow-PipelineCardsShadow p-[18px] '>
    //                 <label className="text-sm text-gray-800 not-italic font-normal">Select Time Zone</label>
    //                 <SelectBox
    //                     value={timeZone}
    //                     onChange={(e) => setTimeZone(e.target.value)}
    //                     options={timeZones}
    //                     size={'small'}
    //                 />
    //             </div>
    //         </div>

    //         <button
    //             type="submit"
    //             className="bg-green-500 text-white px-4 py-2 rounded"
    //         >
    //             Submit Availability
    //         </button>
    //     </form>
    // </div>
  );
}

export default Avavilabiltycheck;
