import React, { useEffect, useState } from "react";
import InputBox from "../../../layouts/InputBox";
import LineChart from "../../analytics/LineChart";
import ProgressBar from "../../analytics/ProgressBar";
const core = require("../../../lib/core");

const JobTempData = ({ data, showType }) => {
  const [showSection, setShowSection] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [errorList, setErrorList] = useState({});
  const [skills, setSkills] = useState(data?.skills);
  const [width, setWidth] = useState(500);
  const [loading, setLoading] = useState(false); // Add loading state
  const [jobTypeInputValue, setJobTypeInputValue] = useState(
    Array.isArray(data.job_type) ? data.job_type.join(", ") : data.job_type,
  );
  const [JobTypeArray, setJobTypeArray] = useState([
    { type: "full-time", status: false },
    { type: "regular/permanent", status: false },
    { type: "part-time", status: false },
    { type: "internship", status: false },
    { type: "contract/temporary", status: false },
    { type: "volunteer", status: false },
    { type: "other", status: false },
  ]);
  useEffect(() => {
    if (data?.skills) {
      setSkills(data?.skills);
    }
    // console.log("data", data?.skills);
  }, [data]);

  const addNewJobType = (value) => {
    if (
      JobTypeArray.map((d) => String(d.type).toLowerCase()).includes(
        String(value).toLowerCase(),
      )
    ) {
      throw new Error("Job type already added");
    }
    setJobTypeArray([
      ...JobTypeArray,
      { type: String(value).trim(), status: true },
    ]);
  };

  const handleNewJobType = (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.target.value.trim() === "") return;
        addNewJobType(e.target.value);
        setJobTypeInputValue("");
        setErrorList((current) => {
          const { type, ...rest } = current;
          return rest;
        });
      }
    } catch (error) {
      setErrorList({ ...errorList, type: error.message });
    }
  };

  const toggleJobTypeStatus = (index) => {
    const newArray = JobTypeArray.map((item, idx) =>
      idx === index
        ? { ...item, status: !item.status }
        : { ...item, status: false },
    );
    setJobTypeArray(newArray);
    const selectedJobType = newArray.find((item) => item.status)?.type || "";
    setJobTypeInputValue(selectedJobType);
  };

  const toggleSection = (key) => {
    if (showSection.includes(key)) {
      setShowSection(showSection.filter((item) => item !== key));
    } else {
      setShowSection([...showSection, key]);
    }
  };

  const update_template = async () => {
    setLoading(true);
    try {
      const list = await core.API(
        core.API_METHODS.PUT,
        core.TEMPLATE_API.PUT_UPDATE_JOB_TEMPLATE + data.template_id,
        1,
        {
          job_type: JobTypeArray?.filter((item) => item?.status).map(
            (item) => item?.type,
          ),
        },
      );

      if (list?.data) {
        reloadList();
        reload();
        setIsEditing(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const getTypeColor = (num) => {
    switch (num) {
      case -2: {
        return "#FF6C7E";
      }
      case -1: {
        return "#FFAA33";
      }
      case 1: {
        return "#1369E9";
      }
      case 2: {
        return "teal";
      }
    }
  };

  const sections = [
    {
      key: "description",
      name: "Description",
      content: (
        <>
          {data?.job_type && (
            <div className="flex mb-4 gap-1 group relative">
              <span className="job-detail-info-title block text-sm font-normal not-italic">
                Job Type:{" "}
                {isEditing ? (
                  <div>
                    <InputBox
                      type="text"
                      className="mb-1 flex"
                      value={jobTypeInputValue}
                      onChange={(e) => {
                        if (
                          e.target.value.trim() === "" &&
                          Object.keys(errorList).includes("type")
                        ) {
                          setErrorList((current) => {
                            const { type, ...rest } = current;
                            return rest;
                          });
                        }
                        setJobTypeInputValue(e.target.value);
                      }}
                      placeholder="Type here"
                      onKeyDown={handleNewJobType}
                    />
                    <div className="w-full compensation-tags flex flex-wrap gap-[6px]">
                      {JobTypeArray?.map((item, index) => (
                        <div key={index} className="flex">
                          {/* <button
                            className={`mt-[8px] rounded-[68px] border border-[#E1E1EE] flex items-center py-[7px] px-[12px] ${
                              item?.status
                                ? "hover:!bg-[#699c8f]"
                                : "hover:!bg-[#00b98e34]"
                            }`}
                            type="button"
                            style={{
                              background: item?.status
                                ? "#00B98D"
                                : "rgba(51, 52, 142, 0.03)",
                            }}
                            onClick={() => toggleJobTypeStatus(index)}
                          >
                            <span
                              style={{
                                color: item?.status ? "#FFFFFF" : "#252E4B",
                              }}
                            >
                              {item?.type}
                            </span>
                            {!item?.status ? (
                              <svg
                                className="ml-[8px]"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <g id="add">
                                  <path
                                    d="M4 8H12"
                                    stroke="#252E4B"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M8 12V4"
                                    stroke="#252E4B"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                              </svg>
                            ) : (
                              <svg
                                className="ml-[8px]"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <g id="Right icon">
                                  <path
                                    d="M4.66797 4.66602L11.3346 11.3327"
                                    stroke="white"
                                    strokeWidth={1.5}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.66732 11.3327L11.334 4.66602"
                                    stroke="white"
                                    strokeWidth={1.5}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                              </svg>
                            )}
                          </button> */}
                        </div>
                      ))}
                    </div>
                    <button
                      className="mt-2 bg-blue-800 text-white py-1 px-4 rounded"
                      onClick={update_template}
                      disabled={loading}
                    >
                      {loading ? "Updating..." : "Update"}
                    </button>
                  </div>
                ) : (
                  <>
                    {Array.isArray(data.job_type)
                      ? data.job_type.join(", ")
                      : data.job_type}
                  </>
                )}
              </span>
              {/* {!isEditing && (
                <span className="transform translate-y-1/2 w-3 h-3 invisible group-hover:visible">
                  <svg
                    onClick={() => setIsEditing(true)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 20h9" />
                    <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                  </svg>
                </span>
              )} */}
            </div>
          )}
          {data?.workplace_type && (
            <div className="mb-4">
              <span className="job-detail-info-title block text-sm font-normal not-italic">
                WorkPlace Type : {data.workplace_type}
              </span>
            </div>
          )}
          {data?.department && (
            <div className="mb-4">
              <span className="job-detail-info-title block text-sm font-normal not-italic">
                Department :{" "}
                {Array.isArray(data.department)
                  ? data.department.join(",")
                  : data.department}
              </span>
            </div>
          )}
          {data?.salary && (
            <div className="mb-4">
              <span className="job-detail-info-title block text-sm font-normal not-italic">
                Salary : {`${data.salary.min}$ - ${data.salary.max}$`}
              </span>
            </div>
          )}
          {data?.experience && (
            <div className="mb-4">
              <span className="job-detail-info-title block text-sm font-normal not-italic">
                Experience :{" "}
                {`${data.experience.min} - ${data.experience.max} years`}
              </span>
            </div>
          )}
          {data?.benefits && (
            <div className="mb-4">
              <span className="job-detail-info-title block text-sm font-normal not-italic">
                Benifits :{" "}
                {Array.isArray(data?.benefits)
                  ? data.benefits.join(",")
                  : data.benefits}
              </span>
            </div>
          )}
          {data?.template_name && (
            <div className="mb-4">
              <label className="block text-sm font-normal not-italic">
                Job title
              </label>
              <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                {data.template_name}
              </p>
            </div>
          )}
          {data?.job_details && (
            <div className="mb-4">
              <label className="block text-sm font-normal not-italic">
                Job description
              </label>
              <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                {data.job_details}
              </p>
            </div>
          )}
        </>
      ),
    },
    {
      key: "skills",
      name: "Skills",
      content: data?.skills && (
        <div className="flex flex-wrap gap-x-4 gap-y-2 ">
          {/* {data.skills.map((skill, index) => (
            <span
              key={index}
              className="py-1 px-2 border border-gray-400 rounded-lg text-sm font-normal not-italic text-gray-800 "
            >
              {skill}
            </span>
          ))} */}
          <div className="w-full relative overflow-x-scroll">
            <LineChart
              id="job-skills"
              data={skills?.filter((iter) => iter?.criteria != "Misc")}
              chartHeight={240}
              width={Math.max(width - 20, skills?.length * 125)}
              color={"steelblue"}
              clickFunction={false}
            />
          </div>
          <div>
            {skills?.length > 0 &&
              skills.map(
                (data, it) =>
                  data?.skills?.length > 0 && (
                    <div className="w-full mb-4" key={it}>
                      <h4 className="text-gray-700 text-sm not-italic font-medium mb-3">
                        {data?.criteria}
                      </h4>
                      <div className="w-full border p-2 border-[#E1E1EE] rounded-xl relative">
                        {data?.skills.map((skill, i) => (
                          <div
                            className=" flex last-of-type:pb-0 pb-2 items-center ml-0"
                            key={i}
                          >
                            <span
                              className="w-[45%] text-[#252E4B] text-[14px] not-italic font-normal leading-7 truncate overflow-hidden  mr-[10px]"
                              onMouseOver={(e) => {
                                var overflowed =
                                  e.target.scrollWidth > e.target.clientWidth;
                                e.target.title = overflowed
                                  ? e.target.textContent
                                  : "";
                              }}
                              onMouseOut={(e) => (e.target.title = "")}
                            >
                              {skill?.label}
                            </span>
                            <span className="w-[45%]  mr-[10px]">
                              <ProgressBar
                                id={"skills-bar" + it + i}
                                style_classes="w-full"
                                data={Math.abs(skill?.value)}
                                height={8}
                                barColor={getTypeColor(skill?.type) ?? "teal"}
                                bgColor="#EFF0F2"
                                width={width * 0.45 - 10}
                                fromRight={skill?.value < 0}
                              />
                            </span>
                            <span style={{ width: 20 }}>
                              {Math.floor(skill?.value)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ),
              )}
          </div>
        </div>
      ),
    },
    {
      key: "roles",
      name: "Roles and Responsibilty",
      content: data?.roles_and_responsibilities && (
        <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
          {data.roles_and_responsibilities}
        </p>
      ),
    },
    {
      key: "qualification",
      name: "Qualification",
      content: data?.education && (
        <div className="mb-4">
          <label className="block text-sm font-normal not-italic">
            Education
          </label>
          <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
            {data.education}
          </p>
        </div>
      ),
    },
  ];

  return (
    data && (
      <>
        {data?.template_name && (
          <h6 className="text-gray-800 text-base not-italic font-medium">
            {data.template_name}
          </h6>
        )}
        {showType && data?.template_type && (
          <h6 className="text-gray-700 text-sm not-italic font-semibold">
            {data.template_type}
          </h6>
        )}

        <div className="flex flex-col gap-4">
          {sections.map((section, index) => (
            <div key={index}>
              <h6
                onClick={() => toggleSection(section?.key)}
                className="text-gray-800 text-sm not-italic font-semibold mb-2 flex gap-2 items-center hover:font-bold cursor-pointer group"
              >
                {section.name}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 group-hover:animate-pulse group-hover:stroke-2 transition-all duration-300 ease-in-out"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </h6>
              {showSection.includes(section?.key) ? section.content : null}
            </div>
          ))}
        </div>

        {data?.custom_attributes?.length > 0 &&
          data.custom_attributes?.map((item, index) => (
            <div key={index} className="mb-4">
              <label className="block text-sm font-normal not-italic">
                {item?.name}
              </label>
              <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                {item?.value}
              </p>
            </div>
          ))}
      </>
    )
  );
};

export default JobTempData;
