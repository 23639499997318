// import React, { useState, useEffect } from "react";
// import { MdDeleteForever } from "react-icons/md";

// const JobFilter = ({ onFilter, locations, positions, workplaces, jobTypes, buttonBgColor,
//     buttonHoverColor }) => {
//     const [location, setLocation] = useState("");
//     const [position, setPosition] = useState("");
//     const [workplace, setWorkplace] = useState("");
//     const [jobType, setJobType] = useState("");

//     useEffect(() => {
//         onFilter({ location, position, workplace, jobType });
//     }, [location, position, workplace, jobType]);

//     const clearFilters = () => {
//         setLocation("");
//         setPosition("");
//         setWorkplace("");
//         setJobType("");
//     };

//     return (
//         <div className="flex flex-col justify-center pt-5 pb-7 md:flex-row items-center mb-6 w-full space-y-4 md:space-y-0 md:space-x-4">
//             <select
//                 value={location}
//                 onChange={(e) => setLocation(e.target.value)}
//                 className="p-2 border border-gray-500 rounded-md w-full md:w-1/5"
//             >
//                 <option value="">Select Location</option>
//                 {locations.map((loc, index) => (
//                     <option key={index} value={loc}>
//                         {loc}
//                     </option>
//                 ))}
//             </select>
//             <select
//                 value={position}
//                 onChange={(e) => setPosition(e.target.value)}
//                 className="p-2 border border-gray-500 rounded-md w-full md:w-1/5"
//             >
//                 <option value="">Select Position</option>
//                 {positions.map((pos, index) => (
//                     <option key={index} value={pos}>
//                         {pos}
//                     </option>
//                 ))}
//             </select>
//             <select
//                 value={workplace}
//                 onChange={(e) => setWorkplace(e.target.value)}
//                 className="p-2 border border-gray-500 rounded-md w-full md:w-1/5"
//             >
//                 <option value="">Select Workplace</option>
//                 {workplaces.map((work, index) => (
//                     <option key={index} value={work}>
//                         {work}
//                     </option>
//                 ))}
//             </select>
//             <select
//                 value={jobType}
//                 onChange={(e) => setJobType(e.target.value)}
//                 className="p-2 border border-gray-500 rounded-md w-full md:w-1/5"
//             >
//                 <option value="">Select Job Type</option>
//                 {jobTypes.map((type, index) => (
//                     <option key={index} value={type}>
//                         {type}
//                     </option>
//                 ))}
//             </select>
//             <button
//                 onClick={clearFilters}
//                 className={`p-2 flex justify-center items-center px-4 w-full md:w-auto ${buttonBgColor} text-white font-semibold rounded-md ${buttonHoverColor}`}
//             >

//                 <MdDeleteForever className="h-5 w-5" />

//             </button>
//         </div>
//     );
// };

// export default JobFilter;
import React, { useState, useEffect } from "react";
import { MdDeleteForever, MdSearch } from "react-icons/md";

const JobFilter = ({
  onFilter,
  locations,
  positions,
  workplaces,
  jobTypes,
  buttonBgColor,
  buttonHoverColor,
}) => {
  const [location, setLocation] = useState("");
  const [position, setPosition] = useState("");
  const [workplace, setWorkplace] = useState("");
  const [jobType, setJobType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPositions, setFilteredPositions] = useState([]);

  useEffect(() => {
    onFilter({ location, position, workplace, jobType });
  }, [location, position, workplace, jobType]);

  useEffect(() => {
    setFilteredPositions(
      positions.filter((pos) =>
        pos.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, positions]);

  const clearFilters = () => {
    setLocation("");
    setPosition("");
    setWorkplace("");
    setJobType("");
    setSearchTerm("");
  };

  return (
    <div className="flex flex-col justify-center pt-5 pb-7 items-center mb-6 w-full space-y-4 md:space-y-0">
      {/* <div className="w-full flex items-center relative py-2">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search Position"
                    className="p-2 border border-gray-500 rounded-md w-full pr-12 mr-4"
                />
                <button className={`p-2 flex justify-center items-center px-4 ${buttonBgColor} text-white font-semibold rounded-md ${buttonHoverColor}`}>
                    <MdSearch className="h-5 w-5" />
                </button>

                {searchTerm && (
                    <div className="absolute top-10 left-0 w-full bg-white border border-gray-500 rounded-md max-h-40 overflow-y-auto z-10">
                        {filteredPositions.map((pos, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    setPosition(pos);
                                    setSearchTerm("");
                                }}
                                className="p-2 cursor-pointer hover:bg-gray-200"
                            >
                                {pos}
                            </div>
                        ))}
                    </div>
                )}
            </div> */}

      {/* <div className="w-full flex items-center relative py-3">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search Position..."
          className="p-2 border border-gray-600 rounded-md w-full pr-12 mr-4 text-gray-800 placeholder-gray-800"
        />
        <button
          className={`p-2 flex justify-center items-center px-4 ${buttonBgColor} text-white font-semibold rounded-md ${buttonHoverColor}`}
        >
          <MdSearch className="h-5 w-5" />
        </button>

        {searchTerm && (
          <div className="absolute top-10 left-0 w-full bg-white border border-gray-600 rounded-md max-h-40 overflow-y-auto z-10">
            {filteredPositions.length > 0 ? (
              filteredPositions.map((pos, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setPosition(pos);
                    setSearchTerm("");
                  }}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                >
                  {pos}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-600">No Matches</div>
            )}
          </div>
        )}
      </div> */}

      <div className="w-full flex flex-col gap-8 space-y-4 md:space-y-0">
        <div>
          <label htmlFor="" className="block text-gray-800 font-semibold mb-2 ">
            Select location
          </label>
          <select
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className=" p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
          >
            <option value="">Select Location</option>
            {locations.map((loc, index) => (
              <option key={index} value={loc}>
                {loc}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="" className="block text-gray-800 font-semibold mb-2 ">
            Select workplace
          </label>
          <select
            value={workplace}
            onChange={(e) => setWorkplace(e.target.value)}
            className=" p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
          >
            <option value="">Select Workplace</option>
            {workplaces.map((work, index) => (
              <option key={index} value={work}>
                {work}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="" className="block text-gray-800 font-semibold mb-2 ">
            Select job type
          </label>
          <select
            value={jobType}
            onChange={(e) => setJobType(e.target.value)}
            className=" p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
          >
            <option value="">Select Job Type</option>
            {jobTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={clearFilters}
          className={`p-2 flex justify-center items-center px-4 w-full md:w-auto ${buttonBgColor} text-white font-semibold rounded-md ${buttonHoverColor}`}
        >
          Clear all
        </button>
      </div>
    </div>
  );
};

export default JobFilter;
