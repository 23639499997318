import CheckGreen from "./CheckGreen";
import IconInvite from "./IconInvite";
import UndefinedRed from "./UndefinedRed";
import VideoIcons from "./VideoIcons";

export const ICONS = {
  invite: IconInvite,
  VideoIcon: VideoIcons,
  CheckGreen: CheckGreen,
  UndefinedRed: UndefinedRed,
};
