import React, { useState, useEffect } from "react";
import Footer from "./JobPostFooter";
// const core = require("../../../src/lib/core");
import { useLocation, useNavigate } from "react-router-dom";
import ScreeningQuestionForm from "./ScreeningQuestionForm";
import { ScaleLoader } from "react-spinners";
const core = require("../../../src/lib/core");

function JobForm() {
  const navigate = useNavigate();
  const locate = useLocation();
  const { name, locationName } = locate.state || {};

  const loc = useLocation();

  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [location, setLocation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    // Parse query parameters from URL
    const params = new URLSearchParams(window.location.search);
    const firstName = params.get("firstName");
    const lastName = params.get("lastName");
    const email = params.get("email");

    if (firstName) setFirstName(firstName);
    if (lastName) setLastName(lastName);

    if (email) setEmail(email);
  }, []);

  const [orgDetail, setOrgDetail] = useState({}); // [1]
  const [jobDetail, setJobDetail] = useState();

  const fetchJob = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.ORGANIZATION_API.GET_SCREENING_QUESTION +
          loc.pathname.split("/").at(-1),
        1,
        {},
      );
      if (json && json.data) {
        setOrgDetail(json?.data?.organization);
        setJobDetail(json?.data);
        setQuestions(json?.data?.screeningQuestion);
      }
    } catch {
      console.log("error");
    }
  };

  // console.log(orgDetail);
  useEffect(() => {
    fetchJob();
  }, []);

  const handleResumeChange = (event) => {
    setResume(event.target.files[0]);
  };

  console.log(resume);

  const handleCoverLetterChange = (event) => {
    setCoverLetter(event.target.files[0]);
  };

  const user = core.getData(core.data.USER);

  const [loading, setLoading] = useState(false);
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      // setLoading(true);
      let formData = new FormData();
      formData.append("files", resume);
      formData.append("jobId", loc.pathname.split("/").at(-1));
      formData.append("location", location);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("linkedInProfile", linkedInProfile);
      formData.append("currentCompany", currentCompany);
      formData.append("screeningQuestion", JSON.stringify(questions));

      const response = await fetch(
        core.ORGANIZATION_API.POST_CANDIDATE_PROFILE,
        {
          method: "POST",
          headers: {},
          body: formData,
        },
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.data === "Duplicate found") {
            // Handle the case where a duplicate is found
            console.log(result);
            navigate("/job-post/submit", {
              state: { orgDetail, jobDetail, message: result.data },
            });
          } else {
            // Handle the case where the submission is successful
            navigate("/job-post/submit", { state: { orgDetail, jobDetail } });
          }
        });

      setLoading(false);
    } catch (err) {
      console.error("Error submitting the form", err);
      setFormError("An error occurred while submitting the form.");
      setLoading(false);
    }
  }

  return (
    <>
      <div className="h-full overflow-auto">
        <div className="overflow-auto pt-0 mt-0 bg-white">
          <div className="max-w-3xl mx-auto p-6 mt-5">
            <div className="flex justify-between items-center gap-3 mb-4">
              <div className="flex-shrink-0">
                <img
                  src={orgDetail?.logo}
                  alt="Logo"
                  className="ml-2 md:ml-0 w-auto max-w-[120px] rounded-lg mb-2 md:mb-0"
                />
                <a
                  href="/job-post"
                  className=" text-xs pl-3 pt-2 text-sky-600 font-semibold hover:text-sky-900 hover:cursor-pointer hover:no-underline"
                >
                  {" "}
                  (View All Jobs)
                </a>
              </div>
            </div>
            <h1 className="text-3xl font-bold">{jobDetail?.name}</h1>
            <div className="text-gray-600 ">
              <p>{locationName}</p>
            </div>
          </div>
        </div>
        <div className="bg-gray-300 pb-0 mb-0">
          <div className="max-w-3xl mx-auto p-8 mt-5">
            <h1 className="text-xl text-gray-800 font-bold mb-6">
              Submit Your Application
            </h1>
            <div className="flex justify-center items-center"></div>
            {/* {formError && (
              <div className="mb-4 text-red-600 font-semibold">{formError}</div>
            )} */}
            <form
              onSubmit={handleSubmit}
              className="bg-white p-8 rounded-lg shadow-2xl"
            >
              <div className="mb-6">
                <label className="block text-gray-800 font-semibold mb-2 required">
                  First Name
                </label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required={true}
                  placeholder="Enter your first name"
                  className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-800 font-semibold mb-2 ">
                  Last Name
                </label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter your last name"
                  className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-800 font-semibold mb-2 required">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  required={true}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-800 font-semibold mb-2 required">
                  Phone
                </label>
                <input
                  type="tel"
                  value={phone}
                  required={true}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter your phone number"
                  className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-800 font-semibold mb-2 required">
                  Location (City)
                </label>
                <div className="flex items-center">
                  <input
                    type="text"
                    value={location}
                    required={true}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="Enter your location"
                    className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-800 font-semibold mb-2 required">
                  Resume/CV
                </label>
                <input
                  type="file"
                  required={true}
                  onChange={handleResumeChange}
                  className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full focus:outline-none focus:border-sky-600 transition-colors"
                />
                <p className="text-sm text-gray-600 mt-2">
                  (File types: pdf, doc, docx, txt, rtf)
                </p>
              </div>
              {/* <div className="mb-6">
                <label className="block text-gray-800 font-semibold mb-2">
                  Cover Letter
                </label>
                <input
                  type="file"
                  onChange={handleCoverLetterChange}
                  className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full focus:outline-none focus:border-sky-600 transition-colors"
                />
                <p className="text-sm text-gray-600 mt-2">
                  (File types: pdf, doc, docx, txt, rtf)
                </p>
              </div> */}
              <div className="mb-6">
                <label className="block text-gray-800 font-semibold mb-2">
                  LinkedIn Profile
                </label>
                <input
                  type="url"
                  value={linkedInProfile}
                  onChange={(e) => setLinkedInProfile(e.target.value)}
                  placeholder="Enter your LinkedIn profile URL"
                  className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-800 font-semibold mb-2 ">
                  Current Company
                </label>
                <input
                  type="text"
                  value={currentCompany}
                  onChange={(e) => setCurrentCompany(e.target.value)}
                  placeholder="Enter your current company"
                  className="mt-1 p-3 border-2 hover:shadow-md border-gray-400 rounded-lg w-full placeholder-gray-600 focus:outline-none focus:border-sky-600 transition-colors"
                />
              </div>
              {jobDetail?.screeningQuestion?.length > 0 && (
                <ScreeningQuestionForm
                  questions={questions}
                  setQuestions={setQuestions}
                />
              )}
              <div className="mt-8">
                <button
                  type="submit"
                  disabled={loading}
                  className="py-3 rounded-lg w-full text-white font-semibold hover:font-bold bg-blue-900 hover:bg-blue-700  flex justify-center items-center transition transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-700"
                >
                  {loading ? (
                    <ScaleLoader height={14} color="#ffffff" loading={true} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default JobForm;
