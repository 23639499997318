import React, { useContext } from "react";
import { ScreeningQuestionContext } from "../../../../lib/context";

const capitalize = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);

const deleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g id="left icon">
      <path
        id="Vector"
        d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
        stroke="#FF475D"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

const ParagraghAnswer = ({ type, index }) => {
  return (
    <div className="w-full p-4 bg-gray-200 border border-gray-300">
      <label className="text-sm font-semibold text-gray-700">Preview</label>{" "}
      <input
        disabled
        placeholder="Candidate answer here"
        type={type}
        className="w-full border border-gray-300 rounded-md p-2 disabled:pointer-events-none disabled:bg-gray-300"
      />
    </div>
  );
};

const MCQAnswer = ({ index }) => {
  const { data, setData } = useContext(ScreeningQuestionContext);
  const options = data.filter((iter) => iter?.id == index)[0]?.answer || [""];
  const addOption = () => {
    const updatedOptions = [...options, ""];
    setData(index, "answer", updatedOptions);
  };
  const addOptionText = (text, idx) => {
    const updatedOptions = [...options];
    updatedOptions[idx] = text;
    setData(index, "answer", updatedOptions);
  };
  const removeOption = (idx) => {
    const updatedOptions = options.filter((_, i) => i !== idx);
    setData(index, "answer", updatedOptions);
  };

  return (
    <div className="w-full flex flex-col gap-2 mt-5">
      <div className="w-full flex justify-between items-end">
        <label className="text-sm font-semibold text-gray-700 grow">
          Options
        </label>
        <button
          onClick={addOption}
          type="button"
          className="btn-sec btn-sm self-end h-10"
        >
          + Add new option
        </button>
      </div>
      {options?.map((opt, idx) => (
        <div className="w-full flex gap-2 items-center relative">
          <input
            id={"opt" + index + idx}
            name={"opt" + index + idx}
            type="text"
            value={capitalize(opt)}
            onChange={(e) => addOptionText(e.target.value, idx)}
            placeholder={"Option " + (idx + 1)}
            className="w-full h-4 p-1 placeholder-gray-600 rounded-md !pr-12"
          />
          <button
            type="button"
            onClick={() => removeOption(idx)}
            data-tooltip-content={"Remove option"}
            data-tooltip-id="tooltip"
            className="absolute right-2 z-30 btn-sm text-red-800 w-10 h-10 p-1 hover:border-red-900 hover:text-red-900"
          >
            {deleteIcon}
          </button>
        </div>
      ))}
    </div>
  );
};

const OneChoiceAnswer = ({ index }) => {
  const { data, setData } = useContext(ScreeningQuestionContext);
  var qu = data.filter((iter) => iter?.id == index)[0];
  const options =
    qu?.answer?.length > 0
      ? qu?.answer
      : qu?.answerType == "yes/no"
        ? ["Yes", "No"]
        : [""];

  const addOption = () => {
    const updatedOptions = [...options, ""];
    setData(index, "answer", updatedOptions);
  };
  const addOptionText = (text, idx) => {
    const updatedOptions = [...options];
    updatedOptions[idx] = text;
    setData(index, "answer", updatedOptions);
  };
  const removeOption = (idx) => {
    const updatedOptions = options.filter((_, i) => i !== idx);
    setData(index, "answer", updatedOptions);
  };
  return (
    <div className="w-full flex flex-col gap-2 mt-2">
      <div className="w-full flex justify-between items-end">
        <label className="text-sm font-semibold text-gray-700 grow">
          Options
        </label>
        <button
          onClick={addOption}
          type="button"
          className="btn-sec btn-sm self-end h-10"
        >
          + Add new option
        </button>
      </div>
      {options?.map((opt, idx) => (
        <div className="w-full flex gap-2 items-center relative">
          <input
            id={"opt" + index + idx}
            name={"opt" + index + idx}
            type="text"
            value={capitalize(opt)}
            onChange={(e) => addOptionText(e.target.value, idx)}
            placeholder={"Option " + (idx + 1)}
            className="w-1/2 h-4 p-1 placeholder-gray-600 rounded-md grow !pr-12"
          />
          <button
            disabled={options.length === 1}
            type="button"
            onClick={() => removeOption(idx)}
            data-tooltip-content={"Remove option"}
            data-tooltip-id="tooltip"
            className="absolute right-2 z-30 btn-sm text-red-800 w-10 h-10 p-1 hover:border-red-900 hover:text-red-900"
          >
            {deleteIcon}
          </button>
        </div>
      ))}
    </div>
  );
};

const LikertScale = ({ index }) => {
  const { data, setData } = useContext(ScreeningQuestionContext);
  const levels = data.filter((iter) => iter?.id == index)[0]?.answer || [
    { name: "", value: 0 },
  ];

  const addLevel = () => {
    const updatedLevels = [...levels, { name: "", value: levels?.length }];
    setData(index, "answer", updatedLevels);
  };
  const removeLevel = (idx) => {
    const updatedLevels = levels.filter((_, i) => i !== idx);
    setData(index, "answer", updatedLevels);
  };
  const addLevelName = (text, idx) => {
    const updatedLevels = [...levels];
    updatedLevels[idx].name = text;
    setData(index, "answer", updatedLevels);
  };
  const addLevelValue = (val, idx) => {
    const updatedLevels = [...levels];
    updatedLevels[idx].value = +val;
    setData(index, "answer", updatedLevels);
  };

  return (
    <div className="w-full flex flex-col gap-2 mt-2">
      <div className="w-full flex justify-between items-end">
        <label className="text-sm font-semibold text-gray-700 grow">
          Levels
        </label>
        <button
          onClick={addLevel}
          type="button"
          className="btn-sec btn-sm self-end h-10"
        >
          + Add new level
        </button>
      </div>
      {levels?.map((opt, idx) => (
        <div className="w-full flex gap-2 items-center relative">
          <input
            id={"likertName" + index + idx}
            name={"likertName" + index + idx}
            type="text"
            value={opt?.name}
            onChange={(e) => addLevelName(e.target.value, idx)}
            placeholder={"Level " + (idx + 1)}
            className="w-1/2 h-4 p-1 placeholder-gray-600 rounded-md grow"
          />
          <input
            id={"likertValue" + index + idx}
            name={"likertValue" + index + idx}
            type="number"
            value={opt?.value}
            onChange={(e) => addLevelValue(+e.target.value, idx)}
            placeholder={"Option " + (idx + 1)}
            className="w-1/4 h-4 p-1 placeholder-gray-600 rounded-md !pr-12"
          />
          <button
            type="button"
            onClick={() => removeLevel(idx)}
            data-tooltip-content={"Remove option"}
            data-tooltip-id="tooltip"
            className="absolute right-2 z-30 btn-sm text-red-800 w-10 h-10 p-1 hover:border-red-900 hover:text-red-900"
          >
            {deleteIcon}
          </button>
        </div>
      ))}
    </div>
  );
};

export { ParagraghAnswer, MCQAnswer, OneChoiceAnswer, LikertScale };
