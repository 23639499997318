import React, { useState, useEffect } from "react";
import Modal from "../../modal/Modal";
import Autocomplete from "../createJob/Autocomplete";

const core = require("../../../lib/core");
const { TEMPLATE_API } = require("../../../lib/core");

function ShareJobTemplateModal({ jobId, modalDismiss }) {
  const [allUser, setAllUser] = useState([]);
  const [sharedEmails, setSharedEmails] = useState([]); // Store previously shared emails
  const [newEmails, setNewEmails] = useState([]); // Store newly selected emails
  const [searchUsersData, setSearchUsersData] = useState([]);
  const [value, setValue] = useState("");
  const [message, setMessage] = useState({});

  const GET_ALL_RECRUITER = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_ALL_RECRUITER,
      1,
    );
    if (json.data) setAllUser(json.data);
    else console.log("error");
  };

  const GET_SHARED_TEMPLATE_IDS = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        `${TEMPLATE_API.GET_TEMPLATE_SHARE}/${jobId}`,
        1,
      );
      if (json?.data) {
        setSharedEmails(json.data);
      } else {
        console.log("No data returned from GET_TEMPLATE_SHARE API");
      }
    } catch (error) {
      console.error("Error fetching shared template IDs:", error);
    }
  };

  const UPDATE_SHARED_TEMPLATE_IDS = async () => {
    const combinedEmails = [...sharedEmails, ...newEmails];
    const json = await core.API(
      core.API_METHODS.POST,
      TEMPLATE_API.SHARE_TEMPLATE,
      1,
      {
        templateId: jobId,
        userIds: combinedEmails.map((item) => item.id), // Send both shared and new emails
        hostname: window.location.hostname,
      },
    );
    if (json.data) {
      setMessage({ type: "success", message: "Template shared successfully" });
      setTimeout(() => {
        setMessage({});
        modalDismiss();
      }, 2000);
    } else console.log("error");
  };

  useEffect(() => {
    GET_SHARED_TEMPLATE_IDS();
  }, [jobId]);

  useEffect(() => {
    GET_ALL_RECRUITER();
  }, []);

  const modalElements = {
    heading: "Share job template",
    subheading: "Add emails to share the job template with",
    modalDismiss: () => modalDismiss(),
    onSave: () => UPDATE_SHARED_TEMPLATE_IDS(),
    defaultButtons: {
      primaryLabel: "Share",
      primaryDisabled: !(sharedEmails.length > 0 || newEmails.length > 0),
    },
  };

  function searchListJobs(keyword) {
    let results = [];
    allUser.forEach((item) => {
      if (item?.email?.toLowerCase()?.includes(keyword.toLowerCase())) {
        results.push(item);
      }
    });
    setSearchUsersData(results);
  }

  const removeSharedEmail = (id) => {
    setSharedEmails(sharedEmails.filter((email) => email.id !== id));
  };

  return (
    <Modal {...modalElements}>
      {[...sharedEmails, ...newEmails]
        .filter((x) => x?.owner)
        .map((item) => (
          <div className="flex flex-wrap gap-2">
            <span className="text-xs mt-1 font-semibold">
              {item?.owner ? "Owner: " : ""}
            </span>
            <span className="text-gray text-sm font not-italic">
              {item.email}
            </span>
          </div>
        ))}
      <div className="w-full tags flex flex-wrap gap-2">
        {[...sharedEmails, ...newEmails]
          .filter((x) => !x?.owner)
          .map((item) => (
            <>
              <span className="text-xs mt-2">
                {item?.owner ? "Owner: " : ""}
              </span>
              <span
                className={`flex items-center px-2 py-1 rounded-lg ${
                  newEmails.find((email) => email.id === item.id)
                    ? "bg-green-800"
                    : "bg-blue-800"
                }`}
                key={item.id}
              >
                <span className="text-white text-sm font-normal not-italic">
                  {item.email}
                </span>
                <button
                  className="remove"
                  onClick={(e) => {
                    e.preventDefault();
                    if (sharedEmails.find((email) => email.id === item.id)) {
                      removeSharedEmail(item.id);
                    } else {
                      setNewEmails(newEmails.filter((d) => d.id !== item.id));
                    }
                  }}
                >
                  <svg
                    className="ml-1 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M4.66797 4.66669L11.3346 11.3334"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.66927 11.3334L11.3359 4.66669"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </span>
            </>
          ))}
      </div>

      <div className="w-full flex flex-col gap-2">
        <h3 className="w-full text-black text-sm font-normal">Email</h3>
        <Autocomplete
          type="text"
          listClass="relative top-0"
          inputClassName="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
          idName="position"
          name="position"
          value={value}
          data={searchUsersData}
          required={true}
          searchFunction={searchListJobs}
          selectionFunction={(name, value) => {
            setValue(value);
          }}
          onSelectOption={(e) => {
            if (
              !newEmails.find((item) => item.id === e.id) &&
              !sharedEmails.find((item) => item.id === e.id)
            ) {
              setNewEmails((prevNewEmails) => [...prevNewEmails, e]);
            }
            setValue("");
          }}
        />
      </div>
      {Object.entries(message).length > 0 && (
        <p
          style={
            message.type === "error" ? { color: "red" } : { color: "green" }
          }
          className="block text-sm font-medium mt-2"
        >
          {message.message}
        </p>
      )}
    </Modal>
  );
}

export default ShareJobTemplateModal;
