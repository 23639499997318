import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
const core = require("../../../../lib/core");

const Status = () => {
  const sequenceId = useParams().id;
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getSequenceLogs();
  }, []);

  const getSequenceLogs = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.SEQUENCE_API.GET_SEQUENCE_LOGS + sequenceId,
      1,
    );
    if (json?.response) setLogs(json?.response);

    console.log(json);
  };

  return (
    <div className="w-full grow relative rounded-lg bg-white flex flex-col gap-4">
      <table className="table-fixed w-full">
        <colgroup>
          <col span={2} className="w-1/3" />
          <col span={2} className="w-1/5" />
        </colgroup>
        <thead>
          <tr className="border-b border-gray-500 relative">
            {["Candidate", "Job", "Status", ""].map((heading) => (
              <th
                className="px-4 py-2 text-sm text-gray-700 font-medium not-italictext-gray-800 not-italic"
                key={heading}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {logs.map((log, i) => (
            <tr>
              <td className="px-4 py-2 text-base text-gray-800 font-medium not-italic">
                {log?.candidate?.first_name +
                  " " +
                  (log?.candidate?.last_name ?? "")}
              </td>
              <td className="px-4 py-2 text-sm text-gray-700 font-normal not-italic">
                {log?.job_name}
              </td>
              <td className="capitalize px-4 py-2 text-sm text-gray-800 font-normal not-italic ">
                {log?.status}
              </td>
              <td className="px-4 py-2 text-sm text-gray-800 font-normal not-italic "></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Status;
