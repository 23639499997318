import React from "react";
// import Calmlogo from "./images.jpg";

const Header = ({ orgDetails }) => {
  return (
    <div className="bg-gray-100 md:w-11/12 py-5 sm:w-full flex justify-between items-center sm:mx-0 border-b border-b-gray-400">
      <img src={orgDetails?.logo} alt="Logo" className="  w-40 h-auto  " />
      <div className="text-white  hover:bg-blue-600 hover:no-underline inline-flex items-center bg-blue-900 px-3 py-2 rounded-md">
        <a href="https://www.sproutsai.com/" className="hover:no-underline">
          Company Website
        </a>
      </div>
    </div>
  );
};

export default Header;
