import { useNavigate, useParams } from "react-router-dom";
import TabLayout from "../../../layouts/TabLayout";
import TabButtons from "./sidebar/TabButtons";

const TemplateSidebar = ({ templates }) => {
  const nav = useNavigate();
  const openId = useParams().id;
  const btnList = [
    {
      name: "Job",
      id: "Job",
    },
    {
      name: "Email",
      id: "Email",
    },
  ];
  return (
    <>
      <div className="w-full mt-8 grow divide-y overflow-y-scroll scrollbar-hide">
        {templates?.map((template, index) => (
          <button
            onClick={() => nav(template?._id)}
            key={index}
            style={
              template?._id == openId
                ? { backgroundColor: "#F2F4F8" }
                : { backgroundColor: "white" }
            }
            className="w-full h-auto flex flex-col p-2 bg-white hover:bg-gray-300 border-gray-500"
          >
            <h2 className="inline-block text-sm not-italic text-gray-800 truncate font-semibold">
              {template?.template_name}
            </h2>
            <h3 className="inline-block text-sm text-[#5F6989] truncate">
              {template?.template_type}
            </h3>
          </button>
        ))}
      </div>
      <TabButtons />
    </>
  );
};

export default TemplateSidebar;
