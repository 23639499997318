import { useState } from "react";
import { AuthStageContext } from "../../../lib/context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { authStages } from "../../../lib/constants";

const core = require("../../../lib/core");

export default function AccountType() {
  const { user, setUser } = useContext(core.UserContext);
  const { setStage, email, setEmail, isInvited } = useContext(AuthStageContext);
  const [account, setAccount] = useState(-1);

  const nav = useNavigate();
  console.log(user);
  const profiles = [
    {
      name: "Job Seeker",
      isShow: isInvited,
      id: 0,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 5.5V4.75C7 3.50736 8.00736 2.5 9.25 2.5H10.75C11.9926 2.5 13 3.50736 13 4.75V5.5M2.5 10.75H17.5M7 9.625V11.875M13 9.625V11.875M5.5 17.5H14.5C16.1569 17.5 17.5 16.1569 17.5 14.5V8.5C17.5 6.84315 16.1569 5.5 14.5 5.5H5.5C3.84315 5.5 2.5 6.84315 2.5 8.5V14.5C2.5 16.1569 3.84315 17.5 5.5 17.5Z"
            stroke="#00AC85"
            strokeLinecap="round"
          />
        </svg>
      ),
    },
    {
      name: "Recruiter",
      isShow: !isInvited,
      id: 1,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0007 9.99935C12.3018 9.99935 14.1673 8.13387 14.1673 5.83268C14.1673 3.5315 12.3018 1.66602 10.0007 1.66602C7.69946 1.66602 5.83398 3.5315 5.83398 5.83268C5.83398 8.13387 7.69946 9.99935 10.0007 9.99935Z"
            stroke="#00AC85"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.1585 18.3333C17.1585 15.1083 13.9501 12.5 10.0001 12.5C6.05013 12.5 2.8418 15.1083 2.8418 18.3333"
            stroke="#00AC85"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    // {
    //   name: "Enterprise",
    //   id: 2,
    //   icon: (
    //     <svg
    //       width="20"
    //       height="20"
    //       viewBox="0 0 20 20"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M1.66602 18.334H18.3327"
    //         stroke="#00AC85"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M14.1667 1.66602H5.83333C3.33333 1.66602 2.5 3.15768 2.5 4.99935V18.3327H17.5V4.99935C17.5 3.15768 16.6667 1.66602 14.1667 1.66602Z"
    //         stroke="#00AC85"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M5.83398 13.75H8.33398"
    //         stroke="#00AC85"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M11.666 13.75H14.166"
    //         stroke="#00AC85"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M5.83398 10H8.33398"
    //         stroke="#00AC85"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M11.666 10H14.166"
    //         stroke="#00AC85"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M5.83398 6.25H8.33398"
    //         stroke="#00AC85"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M11.666 6.25H14.166"
    //         stroke="#00AC85"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //     </svg>
    //   ),
    // },
  ];

  function handleClick(e, id) {
    e.preventDefault();
    setAccount(+id);
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      var json = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_USER,
        1,
        {
          account: account,
          stage: authStages.SELECTED,
        },
      );
      if (json?.data) {
        setUser({
          ...user,
          account: account,
          stage: authStages.SELECTED,
          organization: json?.data?.organization_name,
        });
        setStage(authStages.SELECTED);
      }
    } catch (err) {
      console.error(err);
      alert("Something went wrong, please try again");
      setAccount(-1);
    }
  }
  const handleLogOut = () => {
    setEmail("");
    setStage("NEWUSER");
    setUser({});
    core.logout();
    nav("/login");
  };

  return (
    <form className="w-full px-4 md:px-[10%] py-5 md:h-screen h-auto relative rounded-lg bg-white flex flex-col items-start justify-center gap-3.5">
      <h1 className="w-full text-black text-4xl font-semibold">
        Choose account type
      </h1>
      <div className="flex flex-col w-full rounded-2xl shadow border border-gray-300 divide-y divide-gray-300 my-2">
        {profiles.map((profile) => (
          <>
            {profile?.isShow && (
              <button
                disabled={profile?.id == 2}
                className={`w-full flex px-4 py-2 gap-2 items-center hover:bg-green-100 disabled:pointer-events-none disabled:bg-gray-300 ${
                  account == profile?.id ? "bg-green-200" : "bg-transparent"
                } ${profile?.id == 0 ? "rounded-t-2xl" : "rounded-b-2xl"}`}
                onClick={(e) => handleClick(e, profile?.id)}
                key={profile?.id}
              >
                <span
                  className={`inline-block p-2 rounded-full ${
                    account == profile?.id ? "bg-white" : "bg-gray-300"
                  }`}
                >
                  {profile?.icon}
                </span>
                <span
                  className={`text-base ${
                    profile?.id == 2 ? "text-gray-600" : "text-gray-800"
                  } font-semibold`}
                >
                  {profile?.name}
                </span>
              </button>
            )}
          </>
        ))}
      </div>
      <button
        disabled={account < 0}
        className="btn-primary btn-md h-12 min-w-[120px] disabled:pointer-events-none disabled:opacity-60"
        onClick={handleSubmit}
      >
        Continue
      </button>
      <h4 className="text-gray-700 text-sm w-full">
        Not {email}? Login using another email
        <a onClick={handleLogOut} className="text-blue-800 ml-2">
          here
        </a>
      </h4>
    </form>
  );
}
