import { useState } from "react";
import logoImg from "../assets/recruiter-logo.png";
import logoText from "../assets/recruiter-text.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../lib/core";
import { useContext, useEffect } from "react";
import LockedComponent from "../../layouts/LockedComponent";
import { Tooltip as ReactTooltip } from "react-tooltip";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import { chatContext } from "../../lib/ChatContext";

const core = require("../../lib/core");

const DashboardIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      className="group:stroke-blue-900"
      d="M0.666016 2.33366C0.666016 1.41318 1.41221 0.666992 2.33268 0.666992H5.66602C6.58649 0.666992 7.33268 1.41318 7.33268 2.33366V5.66699C7.33268 6.58747 6.58649 7.33366 5.66602 7.33366H2.33268C1.41221 7.33366 0.666016 6.58747 0.666016 5.66699V2.33366Z"
      stroke="#252E4B"
      strokeWidth="1.2"
    />
    <path
      className="group:stroke-blue-900"
      d="M17.3327 4.00033C17.3327 5.84128 15.8403 7.33366 13.9993 7.33366C12.1584 7.33366 10.666 5.84128 10.666 4.00033C10.666 2.15938 12.1584 0.666992 13.9993 0.666992C15.8403 0.666992 17.3327 2.15938 17.3327 4.00033Z"
      stroke="#252E4B"
      strokeWidth="1.2"
    />
    <path
      className="group:stroke-blue-900"
      d="M7.33268 14.0003C7.33268 15.8413 5.8403 17.3337 3.99935 17.3337C2.1584 17.3337 0.666016 15.8413 0.666016 14.0003C0.666016 12.1594 2.1584 10.667 3.99935 10.667C5.8403 10.667 7.33268 12.1594 7.33268 14.0003Z"
      stroke="#252E4B"
      strokeWidth="1.2"
    />
    <path
      className="group:stroke-blue-900"
      d="M10.666 12.3337C10.666 11.4132 11.4122 10.667 12.3327 10.667H15.666C16.5865 10.667 17.3327 11.4132 17.3327 12.3337V15.667C17.3327 16.5875 16.5865 17.3337 15.666 17.3337H12.3327C11.4122 17.3337 10.666 16.5875 10.666 15.667V12.3337Z"
      stroke="#252E4B"
      strokeWidth="1.2"
    />
  </svg>
);
const PersonIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
  >
    <path
      d="M14.6673 16.5C14.6673 14.1988 11.6825 12.3333 8.00065 12.3333C4.31875 12.3333 1.33398 14.1988 1.33398 16.5M8.00065 9.83333C5.69946 9.83333 3.83398 7.96785 3.83398 5.66667C3.83398 3.36548 5.69946 1.5 8.00065 1.5C10.3018 1.5 12.1673 3.36548 12.1673 5.66667C12.1673 7.96785 10.3018 9.83333 8.00065 9.83333Z"
      stroke="#252E4B"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CompanyIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M1.66602 17.4997H2.58707M2.58707 17.4997H11.3151M2.58707 17.4997V6.16651C2.58707 5.17475 2.58707 4.67851 2.77829 4.2997C2.94648 3.9665 3.21468 3.69579 3.54478 3.52602C3.92007 3.33301 4.41171 3.33301 5.39426 3.33301H8.50829C9.49084 3.33301 9.98186 3.33301 10.3571 3.52602C10.6873 3.69579 10.9559 3.9665 11.1241 4.2997C11.3151 4.67813 11.3151 5.17378 11.3151 6.16361V10.6342M11.3151 17.4997H17.0827M11.3151 17.4997V10.6342M17.0827 17.4997H18.3327M17.0827 17.4997V11.6342C17.0827 11.0819 16.635 10.6342 16.0827 10.6342H11.3151M5.21865 6.87467H8.24496M5.21865 10.4163H8.24496"
      stroke="#252E4B"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const JobIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M7 5.5V4.75C7 3.50736 8.00736 2.5 9.25 2.5H10.75C11.9926 2.5 13 3.50736 13 4.75V5.5M2.5 10.75H17.5M7 9.625V11.875M13 9.625V11.875M5.5 17.5H14.5C16.1569 17.5 17.5 16.1569 17.5 14.5V8.5C17.5 6.84315 16.1569 5.5 14.5 5.5H5.5C3.84315 5.5 2.5 6.84315 2.5 8.5V14.5C2.5 16.1569 3.84315 17.5 5.5 17.5Z"
      stroke="#252E4B"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
);
const AdminIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M22 7C22 4.79086 20.2091 3 18 3H6C3.79086 3 2 4.79086 2 7V17C2 19.2091 3.79086 21 6 21H18C20.2091 21 22 19.2091 22 17V7Z"
      stroke="#252E4B"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M8 12C8 10.3431 6.65685 9 5 9H2V15H5C6.65685 15 8 13.6569 8 12V12Z"
      stroke="#252E4B"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
  </svg>
);
const ChartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="#252E4B"
    strokeWidth="1.2"
    strokeLinecap="round"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
    />
  </svg>
);
const SequenceIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.25}
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.25 14.25h13.5m-13.5 0a3 3 0 0 1-3-3m3 3a3 3 0 1 0 0 6h13.5a3 3 0 1 0 0-6m-16.5-3a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3m-19.5 0a4.5 4.5 0 0 1 .9-2.7L5.737 5.1a3.375 3.375 0 0 1 2.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 0 1 .9 2.7m0 0a3 3 0 0 1-3 3m0 3h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Zm-3 6h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Z"
    />
  </svg>
);

const HandPicIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.4414 19.0512L15.9614 20.5712L19.0014 17.5312"
      stroke="#252E4B"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1586 10.87C12.0586 10.86 11.9386 10.86 11.8286 10.87C9.44863 10.79 7.55863 8.84 7.55863 6.44C7.54863 3.99 9.53863 2 11.9886 2C14.4386 2 16.4286 3.99 16.4286 6.44C16.4286 8.84 14.5286 10.79 12.1586 10.87Z"
      stroke="#252E4B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9891 21.8097C10.1691 21.8097 8.35906 21.3497 6.97906 20.4297C4.55906 18.8097 4.55906 16.1697 6.97906 14.5597C9.72906 12.7197 14.2391 12.7197 16.9891 14.5597"
      stroke="#252E4B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const TemplateIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
    />
  </svg>
);

// Additional items for admin
// if user is admin, splice item i at position x
// barItems.splice(x, 0, {name:"", icon:"", navigate:""})

export default function JobSidebar({
  active,
  CandidateFilter,
  hideSidebarItems,
}) {
  const { notification, setNotification } = chatContext();

  const ChatIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.66634 16.6L5.93631 15.584L5.94565 15.5768C6.21041 15.365 6.34401 15.2581 6.49307 15.182C6.6268 15.1136 6.7694 15.0639 6.9165 15.0337C7.08231 14.9997 7.25503 14.9997 7.60173 14.9997H14.8359C15.7675 14.9997 16.2338 14.9997 16.59 14.8182C16.9036 14.6584 17.1587 14.4032 17.3185 14.0896C17.5 13.7334 17.5 13.2676 17.5 12.336V5.9971C17.5 5.0655 17.5 4.59901 17.3185 4.24284C17.1587 3.92924 16.9031 3.67445 16.5895 3.51466C16.233 3.33301 15.7669 3.33301 14.8335 3.33301H5.16683C4.23341 3.33301 3.76635 3.33301 3.40983 3.51466C3.09623 3.67445 2.84144 3.92924 2.68166 4.24284C2.5 4.59936 2.5 5.06642 2.5 5.99984V15.559C2.5 16.4471 2.5 16.891 2.68205 17.1191C2.84037 17.3174 3.08036 17.4328 3.33415 17.4326C3.62596 17.4322 3.97287 17.1547 4.66634 16.6Z"
        stroke="#252E4B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  // Default open sidebar when
  const [open, setOpen] = useState(CandidateFilter ? true : false);

  const toggleSidebar = () => {
    if (CandidateFilter) {
      setOpen((prev) => !prev);
    }
  };
  const nav = useNavigate();

  const { user, resources, role } = useContext(UserContext);

  // Common sidebar items for all recruiters
  const barItems = [
    { name: "Dashboard", icon: DashboardIcon, navigate: "/", locked: false },

    { name: "Job", icon: JobIcon, navigate: "/job/active/", locked: false },
    {
      name: "Analytics Dashboard",
      icon: ChartIcon,
      navigate: "/admin/dashboard",
      locked:
        resources?.filter((item) => item === "Analytics_read")?.length > 0
          ? false
          : true,
    },
    {
      name: "Applicants",
      icon: PersonIcon,
      navigate: "/browse-candidates",
      locked:
        resources?.filter((item) => item === "CandidateBrowser_read")?.length >
        0
          ? false
          : true,
    },
    // { name: "hand-picked", icon: HandPicIcon, navigate: "/hand-picked" , locked: true},
    {
      name: "Messages",
      icon: ChatIcon,
      navigate: "/sms",
      locked:
        resources?.filter((item) => item === "Communications_read")?.length > 0
          ? false
          : true,
    },
    {
      name: "Companies",
      icon: CompanyIcon,
      navigate: "/company-profile",
      locked: true,
    },
    {
      name: "Sequences",
      icon: SequenceIcon,
      navigate: "/sequences",
      locked: true,
    },
    {
      name: "Templates",
      icon: TemplateIcon,
      navigate: "/templates/email",
      locked: true,
    },
  ];

  const getNotificationList = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.CHAT_API.GET_MESSAGE_NOTIFICATION_LIST,
        1,
        {},
      );

      if (json.data) {
        setNotification(json.data);
      }
    } catch {}
  };

  useEffect(() => {
    getNotificationList();
  }, []);

  const totalCount =
    notification && notification.length > 0
      ? notification?.reduce(
          (total, item) => total + item?.notificationCount,
          0,
        )
      : 0;

  console.log("notification", notification);

  return (
    <section
      className={`flex flex-row relative left-0 top-0 bottom-0 h-screen transition-width duration-200 px-2 py-4 bg-white ${
        open ? "w-[350px]" : "w-[74px]"
      }`}
    >
      <div
        className={`w-[58px] h-[100vh]  pl-[4px] pr-[12px] ${
          open ? "border-r border-r-[#EFF0F2]" : ""
        }`}
      >
        <img
          width={42}
          height={42}
          className="w-[42px] max-w-full  inline-block cursor-pointer"
          src={logoImg}
          onClick={(e) => {
            nav("/");
          }}
        />

        <ul
          id="menu-list"
          className={`relative mt-[16px] max-w-full left-0  w-[42px] flex justify-center flex-col 
          `}
        >
          {!hideSidebarItems &&
            barItems.map((item, _) =>
              user?.locked && item?.locked ? (
                <LockedComponent className="mt-2">
                  <li
                    key={item?.name}
                    className={`cursor-pointer bg-transparent flex items-center  w-[42px] h-[42px]  justify-center hover:bg-[#F2F4F8] rounded-[8px]`}
                  >
                    <span
                      title={item?.name}
                      className={`inline-block min-w-0 `}
                    >
                      {item?.icon}
                    </span>
                  </li>
                </LockedComponent>
              ) : (
                <>
                  {item?.name !== "Messages" ? (
                    <li
                      key={item?.name}
                      onClick={() => {
                        nav(item?.navigate);
                      }}
                      className={`cursor-pointer relative ${
                        item?.name == active
                          ? "[&_path]:stroke-blue-800 bg-[#F2F4F8]"
                          : "bg-transparent"
                      } flex items-center  w-[42px] h-[42px]  justify-center hover:bg-[#F2F4F8] rounded-[8px]`}
                    >
                      <span
                        data-tooltip-id="my-tooltip-1"
                        data-tooltip-content={item?.name}
                        className={`inline-block min-w-0 `}
                      >
                        {item?.icon}
                      </span>
                      {item?.name === "Messages" && (
                        <NotificationBadge
                          count={totalCount}
                          effect={Effect.SCALE}
                          containerStyle={{ width: "auto" }}
                          style={{
                            background: "red",
                            fontSize: "10px",
                            right: "auto",
                          }}
                        />
                      )}
                      <ReactTooltip
                        id="my-tooltip-1"
                        noArrow={true}
                        style={{
                          zIndex: 9999,
                          background: "#5F6989",
                          fontSize: "10px",
                          padding: "4px 8px",
                          fontWeight: "400",
                        }}
                        place="right"
                      />
                    </li>
                  ) : (
                    <LockedComponent className="mt-2">
                      <li
                        key={item?.name}
                        className={`cursor-pointer bg-transparent flex items-center  w-[42px] h-[42px]  justify-center hover:bg-[#F2F4F8] rounded-[8px]`}
                      >
                        <span
                          title={item?.name}
                          className={`inline-block min-w-0 `}
                        >
                          {item?.icon}
                        </span>
                      </li>
                    </LockedComponent>
                  )}
                </>
              ),
            )}

          {(role?.includes("Super Admin") || role?.includes("Admin")) && (
            <li
              onClick={() => {
                nav("/systemadmin");
              }}
              data-tooltip-id="my-tooltip-1"
              data-tooltip-content={"Manage Organization"}
              className={`cursor-pointer mt-2 ${
                active === "systemadmin"
                  ? "[&_path]:stroke-blue-800 bg-[#F2F4F8]"
                  : "bg-transparent"
              } flex items-center  w-[42px] h-[42px]  justify-center hover:bg-[#F2F4F8] rounded-[8px]`}
            >
              <span className={`inline-block min-w-0 `}>{AdminIcon}</span>
            </li>
          )}
        </ul>
      </div>
      <div className="lg:w-[254px] w-[180px] ml-4 rounded-2xl grow flex flex-col">
        <div className="h-12 flex items-center justify-between relative">
          <img
            width={119}
            height={42}
            className={
              open
                ? "w-[86px] max-w-full inline-block cursor-pointer"
                : "hidden cursor-pointer"
            }
            src={logoText}
            onClick={(e) => {
              nav("/");
            }}
          />
          <button
            className={`group absolute inline-block w-[14px] h-[26px] !px-0  ${
              open ? "right-0" : "left-[-24px]"
            }`}
            onClick={toggleSidebar}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="26"
              viewBox="0 0 14 26"
              fill="none"
              className={open ? "" : "-scale-x-100"}
            >
              <rect
                x="10"
                y="1"
                width="3"
                height="24"
                rx="1.5"
                fill="#00395E"
                fillOpacity="0.2"
              />
              <path
                className={`opacity-0 group-hover:opacity-100 `}
                d="M3.53511 12.2926C3.14458 12.6832 3.14458 13.3163 3.53511 13.7068L7.071 17.2427C7.46146 17.6332 7.46147 18.2663 7.071 18.6567C6.68053 19.0472 6.04747 19.0472 5.657 18.6567L0.707106 13.7068C0.316582 13.3163 0.316583 12.6832 0.707107 12.2926L5.657 7.34274C6.04747 6.95228 6.68053 6.95228 7.071 7.34274C7.46147 7.73321 7.46147 8.36628 7.071 8.75674L3.53511 12.2926Z"
                fill="#00395E"
                fillOpacity="0.2"
              />
            </svg>
          </button>
        </div>
        {open && CandidateFilter}
      </div>
    </section>
  );
}
