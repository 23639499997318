import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import JobFilter from "./JobFilter";
const core = require("../../../src/lib/core");

const JobOpening = ({ jobList }) => {
  const [jobListed, setJobListed] = useState([]);
  const [filteredJobList, setFilteredJobList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [positions, setPositions] = useState([]);
  const [workplaces, setWorkplaces] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const nav = useNavigate();

  const [buttonBgColor, setButtonBgColor] = useState("bg-blue-800");
  const [buttonHoverColor, setButtonHoverColor] = useState("hover:bg-blue-600");

  useEffect(() => {
    if (jobList && jobList.length > 0) {
      setJobListed(jobList);
      setFilteredJobList(jobList);
      const uniqueLocations = Array.from(
        new Set(jobList.flatMap((job) => job.location.map((loc) => loc.name))),
      );
      const uniquePositions = Array.from(
        new Set(jobList.map((job) => job.position)),
      );
      const uniqueWorkplaces = Array.from(
        new Set(jobList.map((job) => job.workplace)),
      );
      const uniqueJobTypes = Array.from(
        new Set(jobList.flatMap((job) => job.job_type.map((jt) => jt.type))),
      );
      setLocations(uniqueLocations);
      setPositions(uniquePositions);
      setWorkplaces(uniqueWorkplaces);
      setJobTypes(uniqueJobTypes);
    }
  }, [jobList]);

  console.log(jobList);

  const theme = "blue";

  const handleFilter = (filters) => {
    const { location, position, workplace, jobType } = filters;
    const filtered = jobListed.filter((job) => {
      const locationMatch = location
        ? job.location.some((loc) => loc.name === location)
        : true;
      const positionMatch = position ? job.position === position : true;
      const workplaceMatch = workplace ? job.workplace === workplace : true;
      const jobTypeMatch = jobType
        ? job.job_type.some((jt) => jt.type === jobType)
        : true;
      return locationMatch && positionMatch && workplaceMatch && jobTypeMatch;
    });
    setFilteredJobList(filtered);
  };

  return (
    <>
      <div className="bg-gray-100 md:w-11/12 sm:w-full flex justify-center items-center sm:mx-0 ">
        <div className=" sm:w-full sm:mx-0 px-0 py-12 sm:px-0 md:px-0 ">
          <div className="grid grid-cols-6 gap-8">
            <div className="grid grid-cols-1 col-span-4 gap-6  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
              {filteredJobList?.map((job, index) => (
                <React.Fragment key={job.id}>
                  <div className="bg-white rounded-lg overflow-hidden p-[20px] shadow-PipelineCardsShadow">
                    <div className="flex items-center justify-between px-2 py-1">
                      <div className="">
                        <h3 className="text-gray-700 text-base font-bold">
                          {job?.position}
                        </h3>
                        <p className="text-sm text-gray-700 mt-[8px]">
                          {job?.job_type.length > 0
                            ? job?.job_type.map((item) => item?.type).join(",")
                            : ""}
                          {job.workplace ? " • " : ""}
                          {job?.workplace ?? ""}
                        </p>

                        <p className="text-sm text-gray-600 mt-[8px] ">
                          {job?.location?.length > 0
                            ? job?.location
                                ?.map((item) => item?.name ?? item)
                                ?.join(",")
                            : ""}
                        </p>
                      </div>
                      <button
                        onClick={() => nav(`/details/${job?.id}`)}
                        className={`text-white ${buttonBgColor} ${buttonHoverColor} hover:text-white hover:no-underline font-medium px-6 py-2 rounded-md`}
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                  {/* {index < filteredJobList.length - 1 && (
                    <hr className="border-gray-500" />
                  )} */}
                </React.Fragment>
              ))}
            </div>
            <div className=" col-span-2">
              <div className="px-4 bg-[#F3F5F9] rounded-lg">
                <JobFilter
                  onFilter={handleFilter}
                  locations={locations}
                  positions={positions}
                  workplaces={workplaces}
                  jobTypes={jobTypes}
                  buttonBgColor={buttonBgColor}
                  buttonHoverColor={buttonHoverColor}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobOpening;
