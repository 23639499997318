import { useNavigate, useParams } from "react-router-dom";
const core = require("../../../lib/core");

const SequenceSidebar = ({ sequences }) => {
  const nav = useNavigate();
  const openId = useParams().id;

  const calculateSteps = (steps) =>
    steps?.filter((s) => s?.action != "WAIT")?.length;

  return (
    <>
      <div className="w-full mt-4 grow divide-y overflow-y-scroll scrollbar-hide">
        {sequences?.map((sequence, index) => (
          <button
            onClick={() => nav(sequence?._id)}
            key={index}
            style={
              sequence?._id == openId
                ? { backgroundColor: "#F2F4F8" }
                : { backgroundColor: "white" }
            }
            className="w-full h-auto flex flex-col p-2 bg-white hover:bg-gray-300 border-gray-500"
          >
            <h2 className="inline-block text-sm not-italic text-gray-800 truncate font-semibold">
              {sequence?.name}
            </h2>
            {sequence?.steps?.length > 0 && (
              <h3 className="inline-block text-sm text-[#5F6989] truncate">
                Steps - {calculateSteps(sequence?.steps)}
              </h3>
            )}
          </button>
        ))}
      </div>
      <button className="w-auto btn-sec btn-md mt-4" onClick={() => nav("new")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <g id="plus">
            <path
              id="Union"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.625 1C6.625 0.654822 6.34518 0.375 6 0.375C5.65482 0.375 5.375 0.654822 5.375 1V5.375H1C0.654822 5.375 0.375 5.65482 0.375 6C0.375 6.34518 0.654822 6.625 1 6.625H5.375V11C5.375 11.3452 5.65482 11.625 6 11.625C6.34518 11.625 6.625 11.3452 6.625 11V6.625H11C11.3452 6.625 11.625 6.34518 11.625 6C11.625 5.65482 11.3452 5.375 11 5.375H6.625V1Z"
              fill="#1369E9"
            />
          </g>
        </svg>
        <span>Create new sequence</span>
      </button>
    </>
  );
};

export default SequenceSidebar;
