import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { Range, getTrackBackground } from "react-range";
import HamburgerSvg from "../../assets/recruiter/DragIcon.svg";
import { TypesButtons, getTypeColor } from "./TypesButton";
import * as d3 from "d3";
import errorIcon from "../../assets/error.svg";
import { minAlphaNumericVal } from "./ValidateValues";
const core = require("../../../lib/core");

export default function Analytics({ classTxt, data, setData }) {
  // console.log(data)
  const { errorList, setErrorList } = useContext(core.JobFormContext);
  const [id, setId] = useState(data?.length);
  const [reload, setReload] = useState(0);
  const [categoryList, setCategoryList] = useState([
    ...new Set(data?.map((d) => d?.category)),
  ]);
  const relationSet = useMemo(
    () => [...new Set(data?.map((d) => d?.relationId))],
    [data],
  );
  const relationList = useMemo(() => data?.map((d) => d?.relationId), [data]);
  // console.log(categoryList, data);
  const categories = [
    "Competitive company",
    "Role",
    // "Salary",
    // "Education",
    "Relevant courses or programs",
    "Tests scores/participation",
    "Volunteer work",
    "Relevant internships",
    "Relevant certification or courses",
    "Achievements or accolades",
    "Any publications",
    "Blogs",
    "Research papers",
    "Patents owned",
    "Published apps",
  ];

  function showKeyData(id) {
    setReload(reload + 1);
    if (id) {
      var data = data?.filter((d) => d.label == id)[0];
    }
  }
  var colorScale = d3
    .scaleLinear()
    .domain([0, relationSet.length])
    .range(["#EeF0FD", "#FFEDEF", "#F3F9E6"]);

  const itemCounter = (index) => {
    return relationList.filter((x) => x == index).length;
  };

  function handleInput(newDict, dict) {
    try {
      let temp = data?.map((d) => {
        if (d != dict) return d;
        return { ...dict, ...newDict };
      });
      setData(temp);
      showKeyData(dict.label);
    } catch (error) {
      console.error(error);
    }
  }

  function reorder(array, from, to) {
    // Remove item from array
    let [removed] = array.splice(from, 1);
    // Add item to desired position in array
    array.splice(to, 0, removed);
  }

  function dragEnd(result) {
    const { source, destination, type } = result;
    // If the candidate is dropped outside of a Droppable, return
    if (!destination) {
      toast("Dragged item out of range");
      return;
    }
    // If the candidate is dropped in the same Droppable and position, return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      toast("Drag item further, it is in the same position");
      return;
    }

    if (type == "criteria") {
      reorder(data, source.index, destination.index);
    }
    if (type == "skill") {
      const sourceCriteriaIndex = +source.droppableId.split("_")[0];
      const destinationCriteriaIndex = +destination.droppableId.split("_")[0];

      if (sourceCriteriaIndex === destinationCriteriaIndex) {
        // Reorder within the same criteria
        let arr_idx = +source.droppableId.split("_")[0];
        reorder(data[arr_idx].data, source.index, destination.index);
      } else {
        // Move skill to another criteria
        let arr_idx = +source.droppableId.split("_")[0];
        let arr_idx2 = +destination.droppableId.split("_")[0];
        const [removed] = data[arr_idx].data.splice(source.index, 1);
        data[arr_idx2].data.splice(destination.index, 0, removed);
        let values = data[arr_idx2]?.data?.map((d) => d.value);
        data[arr_idx2].value = values.reduce((a, b) => a + b) / values.length;
        let values2 = data[arr_idx]?.data?.map((d) => d.value);
        data[arr_idx].value = values2.reduce((a, b) => a + b) / values2.length;
      }
    }
  }

  return (
    // analytics != null && (
    <section className={classTxt} id="Data-section">
      <div className="w-full data-section">
        <div className="w-full header-section">
          <h4 className="w-full text-[#252e4b] text-md font-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g id="vuesax/linear/shapes">
                <g id="vuesax/linear/shapes_2">
                  <g id="shapes">
                    <path
                      id="Vector"
                      d="M13.4303 15.0014H4.40034C2.58034 15.0014 1.42034 13.0514 2.30034 11.4514L4.63034 7.21141L6.81034 3.24141C7.72034 1.59141 10.1003 1.59141 11.0103 3.24141L13.2003 7.21141L14.2503 9.12141L15.5303 11.4514C16.4103 13.0514 15.2503 15.0014 13.4303 15.0014Z"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M22 15.5C22 19.09 19.09 22 15.5 22C11.91 22 9 19.09 9 15.5C9 15.33 9.01 15.17 9.02 15H13.43C15.25 15 16.41 13.05 15.53 11.45L14.25 9.12C14.65 9.04 15.07 9 15.5 9C19.09 9 22 11.91 22 15.5Z"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </g>
            </svg>
            Custom preferences
          </h4>
          {/* <span className='w-full heading-detail'>Add skill keywords to make your job posting reliable in particular categories</span> */}
        </div>
        <div className="w-full input-data-section">
          <label htmlFor="" className=" text-label w-full">
            Criteria
            {Object.keys(errorList).includes("preference") ? (
              <>
                <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
                <span className="text-red-800 text-sm">
                  {errorList?.preference}
                </span>
              </>
            ) : null}
          </label>
          <input
            className={`w-full px-2 mb-4 ${
              Object.keys(errorList).includes("preference")
                ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                : ""
            }`}
            type="text"
            placeholder="Press enter to add criteria"
            onChange={(event) => {
              // If input is cleared, remove error
              if (
                event.target.value.trim() == "" &&
                Object.keys(errorList).includes("preference")
              ) {
                setErrorList((current) => {
                  const { preference, ...rest } = current;
                  return rest;
                });
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                try {
                  if (e.target.value.trim() == "") return;
                  if (
                    categoryList
                      .map((d) => String(d).toLowerCase())
                      .includes(String(e.target.value).toLowerCase())
                  )
                    throw "Benefit already added";
                  setCategoryList((prev) => [...prev, e.target.value.trim()]);
                  // If validated value, remove previous error
                  setErrorList((current) => {
                    const { preference, ...rest } = current;
                    return rest;
                  });
                } catch (error) {
                  console.error(error);
                  setErrorList({ ...errorList, preference: error });
                }
              }
            }}
          />
          {[...new Set([...categories, ...categoryList])]?.map((cat) => (
            <button
              key={cat.trim().split(" ").join("_")}
              className={`mr-2 mb-2 h-[34px] px-3 rounded-2xl ${
                categoryList?.includes(cat)
                  ? "btn-primary !bg-[#00B98D] !border-[#00B98D]"
                  : "btn-sec hover:text-[#00B98D] hover:border-[#00B98D]"
              }`}
              onClick={(e) => {
                e.preventDefault();
                if (categoryList?.includes(cat))
                  setCategoryList((prev) => prev.filter((d) => d != cat));
                else setCategoryList((prev) => [...prev, cat]);
              }}
            >
              {cat}
            </button>
          ))}

          <div className="w-full" id="create-data-section">
            <DragDropContext onDragEnd={dragEnd}>
              <Droppable
                droppableId={"criteria_drop"}
                type="criteria"
                className="w-full"
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="w-full"
                  >
                    {categoryList?.map((category, it) => (
                      <Draggable
                        draggableId={
                          String(category).split(" ").join("_") + "_" + it
                        }
                        index={it}
                        key={String(category)
                          .toLowerCase()
                          .trim()
                          .split(" ")
                          .join("_")}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="section-data w-full rounded-lg p-2 bg-gray-200 bg-opacity-70 mb-4"
                          >
                            <div className="w-full flex justify-between items-center">
                              <div className="w-3/4 label-input flex justify-between items-center">
                                <span
                                  {...provided.dragHandleProps}
                                  title="Hold and drag"
                                  className="drag w-5 h-5"
                                >
                                  <img
                                    className="inline-block w-5 h-5"
                                    src={HamburgerSvg}
                                    alt="dragger"
                                  />
                                </span>
                                <input
                                  type={"text"}
                                  required
                                  className={`${
                                    Object.keys(errorList).includes(
                                      "preference" + it,
                                    )
                                      ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                                      : ""
                                  } w-[calc(100%-28px)]`}
                                  placeholder={category}
                                  defaultValue={category}
                                  onChange={(e) => {
                                    try {
                                      minAlphaNumericVal(e.target.value);
                                      if (
                                        categoryList
                                          .map((d) => d.toLowerCase())
                                          .includes(
                                            e.target.value.trim().toLowerCase(),
                                          )
                                      )
                                        throw "Criteria already added";
                                      category = e.target.value;
                                      // If validated value, remove previous error
                                      setErrorList((current) => {
                                        const key = "preference" + it;
                                        const { [key]: val, ...rest } = current;
                                        return rest;
                                      });
                                    } catch (error) {
                                      console.error(error);
                                      setErrorList({
                                        ...errorList,
                                        ["preference" + it]: error,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div
                                className="inline-block w-5 h-5"
                                title="Remove criteria"
                                onClick={() => {
                                  setData((prev) =>
                                    prev.filter((d) => d.category != category),
                                  );
                                  setCategoryList((prev) =>
                                    prev.filter((d) => d != category),
                                  );
                                  //  Remove error on delete
                                  if (
                                    Object.keys(errorList).includes(
                                      "preference" + it,
                                    )
                                  )
                                    setErrorList((current) => {
                                      const key = "preference" + it;
                                      const { [key]: val, ...rest } = current;
                                      return rest;
                                    });
                                  // For items of this category have other error, remove on delete
                                  data?.filter((d, i) => {
                                    if (d?.category == category) {
                                      if (
                                        Object.keys(errorList).includes(
                                          "preferLabel" + d?.id,
                                        )
                                      )
                                        setErrorList((current) => {
                                          const key = "preferLabel" + d?.id;
                                          const { [key]: val, ...rest } =
                                            current;
                                          return rest;
                                        });
                                    }
                                  });
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <g id="left icon">
                                    <path
                                      id="Vector"
                                      d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                                      stroke="#FF475D"
                                      strokeLinecap="round"
                                    />
                                  </g>
                                </svg>
                              </div>
                            </div>
                            {Object.keys(errorList).includes(
                              "preference" + it,
                            ) ? (
                              <div className="w-full mt-2">
                                <img
                                  className="mr-2 w-3.5 h-3.5 inline"
                                  src={errorIcon}
                                />
                                <span className="text-red-800 text-sm">
                                  {errorList["preference" + it]}
                                </span>
                              </div>
                            ) : null}
                            {data?.filter((d, i) => d?.category == category)
                              .length > 0 && (
                              <>
                                <div className="w-full flex justify-between items-center mt-4 text-center text-sm text-gray-700 font-semibold">
                                  <span className="w-1/3">Label</span>
                                  <span className="w-1/3">Weightage</span>
                                  <span className="w-1/4">Type</span>
                                </div>
                                <Droppable
                                  droppableId={it + "_drop"}
                                  type="skill"
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                      className="list-section w-full"
                                    >
                                      {data
                                        ?.filter((d) => d?.category == category)
                                        .sort((a, b) =>
                                          a.relationId > b.relationId
                                            ? 1
                                            : b.relationId > a.relationId
                                              ? -1
                                              : 0,
                                        )
                                        ?.map((ks, i) => (
                                          <Draggable
                                            draggableId={it + "_" + i}
                                            index={i}
                                            key={it + "_" + i}
                                          >
                                            {(provided, snapshot) => (
                                              <>
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  className="w-full flex justify-between items-center px-1 py-2 bg-opacity-5"
                                                  style={
                                                    itemCounter(ks.relationId) >
                                                    1
                                                      ? {
                                                          background:
                                                            colorScale(
                                                              relationSet.indexOf(
                                                                ks.relationId,
                                                              ),
                                                            ),
                                                        }
                                                      : null
                                                  }
                                                >
                                                  <div
                                                    className="w-1/3 flex justify-between items-center"
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <img
                                                      src={HamburgerSvg}
                                                      alt="drag"
                                                      className="w-4 h-4"
                                                    />
                                                    <input
                                                      type="text"
                                                      className={`${
                                                        Object.keys(
                                                          errorList,
                                                        ).includes(
                                                          "preferLabel" +
                                                            ks?.id,
                                                        )
                                                          ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                                                          : ""
                                                      } w-[calc(100%-20px)]`}
                                                      required
                                                      value={ks.label}
                                                      placeholder="Type here"
                                                      onKeyDown={(e) => {
                                                        if (e.key == "Enter") {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      onChange={(e) => {
                                                        try {
                                                          minAlphaNumericVal(
                                                            e.target.value,
                                                          );
                                                          if (
                                                            data
                                                              .map((d) =>
                                                                d?.label?.toLowerCase(),
                                                              )
                                                              .includes(
                                                                e.target.value
                                                                  .trim()
                                                                  .toLowerCase(),
                                                              )
                                                          )
                                                            throw "Criteria already added";
                                                          handleInput(
                                                            {
                                                              label:
                                                                e.target.value,
                                                            },
                                                            ks,
                                                          );
                                                          // If validated value, remove previous error
                                                          setErrorList(
                                                            (current) => {
                                                              const key =
                                                                "preferLabel" +
                                                                ks?.id;
                                                              const {
                                                                [key]: val,
                                                                ...rest
                                                              } = current;
                                                              return rest;
                                                            },
                                                          );
                                                        } catch (error) {
                                                          console.error(error);
                                                          setErrorList({
                                                            ...errorList,
                                                            ["preferLabel" +
                                                            ks?.id]: error,
                                                          });
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="w-1/3 flex justify-between items-center">
                                                    <Range
                                                      step={1}
                                                      min={
                                                        ks?.type > 0 ? 0 : -10
                                                      }
                                                      max={
                                                        ks?.type > 0 ? 10 : 0
                                                      }
                                                      values={[ks?.value]}
                                                      onChange={(value) => {
                                                        handleInput(
                                                          { value: value[0] },
                                                          ks,
                                                        );
                                                      }}
                                                      renderTrack={({
                                                        props,
                                                        children,
                                                      }) => (
                                                        <div
                                                          {...props}
                                                          style={{
                                                            ...props.style,
                                                            height: "8px",
                                                            width:
                                                              "calc(100% - 30px)",
                                                            borderRadius: "4px",
                                                            right: 0,
                                                            position:
                                                              "relative",
                                                            background:
                                                              getTrackBackground(
                                                                {
                                                                  values: [
                                                                    ks?.value,
                                                                  ],
                                                                  colors:
                                                                    ks?.type > 0
                                                                      ? [
                                                                          getTypeColor(
                                                                            ks?.type,
                                                                          ),
                                                                          "#f2f2f2",
                                                                        ]
                                                                      : [
                                                                          "#f2f2f2",
                                                                          getTypeColor(
                                                                            ks?.type,
                                                                          ),
                                                                        ],
                                                                  min:
                                                                    ks?.type > 0
                                                                      ? 0
                                                                      : -10,
                                                                  max:
                                                                    ks?.type > 0
                                                                      ? 10
                                                                      : 0,
                                                                },
                                                              ),
                                                          }}
                                                        >
                                                          {children}
                                                        </div>
                                                      )}
                                                      renderThumb={({
                                                        props,
                                                      }) => (
                                                        <div
                                                          {...props}
                                                          style={{
                                                            ...props.style,
                                                            height: "19px",
                                                            width: "19px",
                                                            borderRadius:
                                                              "33.043px",
                                                            background: "#FFF",
                                                            boxShadow:
                                                              "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                    <span className="inline-block w-4">
                                                      {ks.value}
                                                    </span>
                                                  </div>
                                                  <div className="w-1/4 flex justify-around items-center">
                                                    <ul
                                                      className={
                                                        "w-[calc(100%-48px)] py-1 flex justify-between"
                                                      }
                                                    >
                                                      <TypesButtons
                                                        data={ks?.type ?? 2}
                                                        clickFunction={(
                                                          e,
                                                          type,
                                                        ) => {
                                                          if (
                                                            (ks?.value > 0 &&
                                                              type < 0) ||
                                                            (ks?.value < 0 &&
                                                              type > 0)
                                                          ) {
                                                            handleInput(
                                                              {
                                                                type: type,
                                                                value:
                                                                  -ks.value,
                                                              },
                                                              ks,
                                                            );
                                                          } else {
                                                            handleInput(
                                                              {
                                                                type: type,
                                                              },
                                                              ks,
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    </ul>
                                                    <button
                                                      className="w-6 pl-3"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        setData((prev) =>
                                                          prev.filter(
                                                            (_, d) =>
                                                              d != ks?.id,
                                                          ),
                                                        );
                                                        // Remove error on delete
                                                        setErrorList(
                                                          (current) => {
                                                            const key =
                                                              "preferLabel" +
                                                              ks?.id;
                                                            const {
                                                              [key]: val,
                                                              ...rest
                                                            } = current;
                                                            return rest;
                                                          },
                                                        );
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 14 14"
                                                        fill="none"
                                                      >
                                                        <g id="left icon">
                                                          <path
                                                            id="Vector"
                                                            d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                                                            stroke="#FF475D"
                                                            strokeLinecap="round"
                                                          />
                                                        </g>
                                                      </svg>
                                                    </button>
                                                    {/* <button
                                                    className="w-6 pl-3 text-lg"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setData((prev) => [
                                                        {
                                                          id: data?.length, 
                                                          relationId:
                                                            ks?.relationId,
                                                          label: "",
                                                          value: 5,
                                                          type: 1,
                                                          category: category,
                                                        },
                                                        ...prev,
                                                      ]);
                                                    }}
                                                  >
                                                    +
                                                  </button> */}
                                                  </div>
                                                </div>
                                                {Object.keys(
                                                  errorList,
                                                ).includes(
                                                  "preferLabel" + ks?.id,
                                                ) ? (
                                                  <div className="w-full">
                                                    <img
                                                      className="mr-2 w-3.5 h-3.5 inline"
                                                      src={errorIcon}
                                                    />
                                                    <span className="text-red-800 text-sm">
                                                      {
                                                        errorList[
                                                          "preferLabel" + ks?.id
                                                        ]
                                                      }
                                                    </span>
                                                  </div>
                                                ) : null}
                                              </>
                                            )}
                                          </Draggable>
                                        ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </>
                            )}

                            <button
                              className="text-blue-800 hover:text-blue-900 text-sm font-medium mt-3 border-none"
                              type="button"
                              onClick={() => {
                                setData((prev) => [
                                  ...prev,
                                  {
                                    id: id,
                                    relationId: "relation" + id,
                                    label: "",
                                    value: 5,
                                    type: 1,
                                    category: category,
                                  },
                                ]);
                                setId((prev) => prev + 1);
                              }}
                            >
                              + Add
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </section>
    // )
  );
}
