import React, { useState, useEffect, useRef } from "react";
import RecordRTC from "recordrtc";
import AssessmentButtons from "./AssessmentButtons";

const core = require("../../../lib/core");

function Mcq({
  currentQuestion,
  UpdateTestState,
  setCurrentQuestion,
  questions,
  handleSubmit,
  selectedAnswers,
  setSelectedAnswers,
  qsnData,
  videoRef,
  navigation,
}) {
  useEffect(() => {
    // document.documentElement.requestFullscreen();
    // document.documentElement.requestFullscreen();
  }, [questions]);
  // console.log(questions)

  const formatCode = (code) => {
    const lines = code?.split("\n");
    const formattedCode = lines?.map((line, index) => {
      const indentation = line?.match(/^\s*/)[0];
      const indentationLevel = indentation?.length / 4; // Assuming each tab is equivalent to 4 spaces
      const spaces = "\u00A0".repeat(indentationLevel * 4); // Non-breaking spaces

      const formattedLine = line
        ?.replace(/\\n/g, "<br>")
        ?.replace(/\\t/g, spaces)
        ?.replace(/^\s*/, spaces);

      return (
        <span key={index} dangerouslySetInnerHTML={{ __html: formattedLine }} />
      );
    });

    return formattedCode;
  };

  function getCookie(name) {
    var cookieName = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }

  const trackResponse = async (question_id, ans) => {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_TRACK_CANDIDATE_ANSWER +
          getCookie("assessmentId") +
          "/track_response",
        1,
        {
          question_id: question_id,
          answer: ans,
          type: "mcq",
        },
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    questions.length > 0 && (
      <section id="mcqSection" className="col-xs-12 no-padding">
        <div id="job-list" className="col-xs-8  left-section no-padding">
          <div className="question-section col-xs-12 no-padding">
            <div className="col-xs-12 question-sub-section no-padding">
              <span className="questionNumber col-xs-12 no-padding">{`Question ${
                currentQuestion + 1
              }`}</span>
              {/* {questions[currentQuestion]?.question.split(/\\n|\n/).filter(item => item.trim() !== '').map((item, index) => ( */}
              <span className="col-xs-12 question-text no-padding">
                {formatCode(questions[currentQuestion]?.question)}
              </span>
              {/* // <span className="col-xs-12 question-text no-padding">{`${item}`}</span> */}
              {/* ))
              } */}
              <form onSubmit={handleSubmit}>
                {questions[currentQuestion]?.options?.map((option, index) => (
                  <div key={option} className="col-xs-12 no-padding option">
                    <input
                      id={`option-${index}`}
                      name={`question-${currentQuestion}`}
                      value={index}
                      checked={selectedAnswers[currentQuestion]?.includes(
                        index,
                      )}
                      onChange={(e) =>
                        setSelectedAnswers(
                          currentQuestion,
                          index,
                          e.target.checked,
                        )
                      }
                      type="radio"
                    />
                    <label htmlFor={`option-${index}`}>{option}</label>
                  </div>
                ))}
                {/* <button type="submit">Submit</button> */}
              </form>
            </div>

            <div className=" col-xs-12 no-padding question-numbers-bottom">
              <div className="col-xs-6 question-numbers">
                {questions?.map((_, index) => (
                  <button
                    className={`question-number ${
                      selectedAnswers[index].length > 0 ? "answered" : ""
                    } 
                    ${index === currentQuestion ? "active" : ""}
                    ${
                      selectedAnswers[index].length == 0 &&
                      index !== currentQuestion
                        ? "visited"
                        : ""
                    }`}
                    onClick={(e) => {
                      if (currentQuestion > index && qsnData?.canGoBack) {
                        return;
                      }
                      trackResponse(
                        questions[currentQuestion].id,
                        selectedAnswers[currentQuestion],
                      );
                      setCurrentQuestion(index);
                    }}
                    key={index}
                  >
                    <span>{index + 1}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <AssessmentButtons
            qsnData={qsnData}
            questions={questions}
            type="mcq"
            selectedAnswers={selectedAnswers}
            trackResponse={trackResponse}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            navigation={navigation}
            UpdateTestState={UpdateTestState}
          />
        </div>
        {/* <div className=" right-section">
          <div className="question-index-section col-xs-12 no-padding">
            <div className="col-xs-12 heading no-padding">
              <span>List of questions</span>
            </div>
            <div className="question-numbers col-xs-12 no-padding">
              {questions?.map((_, index) => (
                  <div
                    className={`question-number ${
                      selectedAnswers[index].length > 0 ? "answered" : ""
                    } 
                    ${index === currentQuestion ? "active" : ""}
                    ${
                      selectedAnswers[index].length == 0 &&
                      index !== currentQuestion
                        ? "visited"
                        : ""
                    }`}
                    onClick={() => {
                      setCurrentQuestion(index);
                    }}
                    key={index}
                  >
                    <span> {index + 1}</span>
                  </div>
              ))}
            </div>
            <div className="indecator-section col-xs-12">
              <span className="text col-xs-6  no-padding">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <rect width="12" height="12" rx="6" fill="#252E4B" />
                  <rect x="4" y="4" width="4" height="4" rx="2" fill="white" />
                </svg>
                Active
              </span>
              <span className="text col-xs-6 no-padding">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="11"
                    height="11"
                    rx="5.5"
                    fill="#F3F9E6"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="11"
                    height="11"
                    rx="5.5"
                    stroke="#80BB01"
                  />
                </svg>
                Answered
              </span>
              <span className="text col-xs-6 no-padding">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="11"
                    height="11"
                    rx="5.5"
                    fill="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="11"
                    height="11"
                    rx="5.5"
                    stroke="#DADAE4"
                  />
                </svg>
                Not answered
              </span>
            </div>
          </div>
        </div> */}
      </section>
    )
  );
}

export default Mcq;
