import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
import InitialsLogo from "../../components/all/InitialsLogo";
import VoiceCall from "../voice/VoiceCall";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AddChatUserModal from "./AddChatUserModal";
import ScrollableFeed from "react-scrollable-feed";
import { chatContext } from "../../lib/ChatContext";
import "react-chat-elements/dist/main.css";
import { MessageList } from "react-chat-elements";
import { ScaleLoader } from "react-spinners";

const core = require("../../lib/core");

function Sms() {
  const { socket, setTouser, notification, setNotification } = chatContext();

  const [open, setOpen] = useState(true);
  const [chatList, setChatList] = useState([]);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getallchats();
  }, []);

  const getallchats = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.CHAT_API.GET_ALL_SMSCHATS,
        1,
      );
      if (json) {
        if (json.data.length > 0) {
          setChatList(
            json?.data?.sort((a, b) => a?.dateUpdated - b?.dateUpdated),
          );
        } else {
          setChatList([]);
        }
      }
    } catch {}
  };

  const groupedMessagesfunction = (messages) => {
    console.log(messages);
    // Create an object to group messages by date
    const groupedMessages = {};

    messages.forEach((message) => {
      // Extract the date from the message (assuming dateCreated contains the date)
      const messageDate = new Date(message?.date_created).toDateString();

      // Check if a group for this date exists, or create one
      if (!groupedMessages[messageDate]) {
        groupedMessages[messageDate] = [];
      }

      // Add the message to the corresponding group
      groupedMessages[messageDate]?.push(message);
    });
    return groupedMessages;
  };

  const [loadSendMessages, setLoadSendMessages] = useState(false);

  const [messagesList, setMessagesList] = useState([]);

  const getChatdetail = async (id) => {
    socket.emit("room_id", id);

    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CHAT_API.GET_MESSAGE_LIST,
        1,
        {
          chatUser: id,
        },
      );

      if (json?.data?.messages) {
        setMessagesList(json?.data?.messages);
        setLoadSendMessages(false);
        setLoading(false);
      } else {
        setLoadSendMessages(false);
      }
    } catch {
      setLoading(false);
      setLoadSendMessages(false);
    }
  };

  const seenNotificationUpdate = async (id) => {
    try {
      let json = await core.API(
        core.API_METHODS.PUT,
        core.CHAT_API.PUT_MESSAGE_NOTIFICATION,
        1,
        {
          _id: id,
        },
      );

      if (json.data) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setReceivedMessages(groupedMessagesfunction(messagesList));
  }, [messagesList]);

  const [newUserName, setNewUserName] = useState("");
  const [newUserNumber, setNewUserNumber] = useState("");

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar
          active="Messages"
          open={open}
          setOpen={setOpen}
          CandidateFilter={
            <SmsList
              getallchats={getallchats}
              setLoading={setLoading}
              chatList={chatList}
              getChatdetail={getChatdetail}
              setTo={setTouser}
              setUserDetail={setUserDetail}
              userDetail={userDetail}
              seenNotificationUpdate={seenNotificationUpdate}
              messagesList={messagesList}
              setNewUserName={setNewUserName}
              setNewUserNumber={setNewUserNumber}
              newUserNumber={newUserNumber}
              notification={notification}
              setNotification={setNotification}
            />
          }
        />
        <div className="w-full">
          <HeaderNew />
          <SmsContent
            chatList={chatList}
            socket={socket}
            setNotification={setNotification}
            notification={notification}
            setChatList={setChatList}
            seenNotificationUpdate={seenNotificationUpdate}
            loading={loading}
            messagesList={messagesList}
            setMessagesList={setMessagesList}
            receivedMessages={receivedMessages}
            userDetail={userDetail}
            getChatdetail={getChatdetail}
            loadSendMessages={loadSendMessages}
            setLoadSendMessages={setLoadSendMessages}
          />
        </div>
      </div>
    </div>
  );
}

export default Sms;

function SmsContent({
  seenNotificationUpdate,
  setNotification,
  notification,
  messagesList,
  setMessagesList,
  userDetail,
  chatList,
  setChatList,
  socket,
  loading,
  loadSendMessages,
  setLoadSendMessages,
}) {
  const [message, setMessage] = useState("");
  const [fetchNumber, setFetchNumber] = useState("");
  const userDetailRef = useRef(userDetail);

  useEffect(() => {
    userDetailRef.current = userDetail;
  }, [userDetail]);

  const updateNotifications = (newItem) => {
    const updatedNotifications = [...notification];
    const existingIndex = updatedNotifications.findIndex(
      (item) => item._id === newItem.chatUser,
    );
    if (existingIndex !== -1) {
      updatedNotifications[existingIndex].notificationCount += 1;
    } else {
      updatedNotifications.push({
        _id: newItem.chatUser,
        notificationCount: 1,
      });
    }
    setNotification(updatedNotifications);
  };

  useEffect(() => {
    socket.on("message", (message) => {
      if (userDetailRef.current?._id === message?.chatUser) {
        setMessagesList((prevMessages) => [...prevMessages, message]);
      } else {
        updateNotifications(message);
        updateLastMessageAndSeenById(message?.chatUser, message?.body);
      }
    });
  }, []);

  useEffect(() => {
    if (userDetail?._id) {
      seenNotificationUpdate(userDetail?._id);
    }
  }, [userDetail, messagesList]);

  const { user } = useContext(core.UserContext);

  // Function to update the last message and last seen based on message id
  const updateLastMessageAndSeenById = (id, message) => {
    setChatList((prevMessages) => {
      const updatedMessages = prevMessages.map((msg) => {
        if (msg._id === id) {
          return {
            ...msg,
            lastMessage: message,
            lastSeen: new Date().toISOString(), // Assuming you want to update lastSeen to current time
          };
        }
        return msg;
      });
      return updatedMessages;
    });
  };

  const sendMessage = async () => {
    let outgoingmsg = {
      type: "msg",
      date_created: new Date(),
      direction: "outbound-api",
      body: message,
      status: "delivered",
    };

    setLoadSendMessages(true);
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CHAT_API.GET_SEND_MESSAGE,
        1,
        {
          body: message,
          toPhone: userDetail?.toPhone,
          jobId: userDetail?.jobId,
          matchProfile: userDetail?.matchProfile,
          toEmail: userDetail?.toEmail,
          toUserId: userDetail?.toUserId,
          chatUser: userDetail?._id,
          name: userDetail?.name,
        },
      );
      if (json) {
        setMessagesList((prevMessages) => [...prevMessages, outgoingmsg]);
        setMessage("");
        setLoadSendMessages(false);
        updateLastMessageAndSeenById(userDetail?._id, message);
        socket.emit("message", {
          body: message,
          toPhone: userDetail?.toPhone,
          jobId: userDetail?.jobId,
          matchProfile: userDetail?.matchProfile,
          toEmail: userDetail?.toEmail,
          toUserId:
            user?.id === userDetail?.created_by
              ? userDetail?.toUserId
              : userDetail?.created_by,
          chatUser: userDetail?._id,
          name: userDetail?.name,
        });
      } else {
        setLoadSendMessages(false);
      }
    } catch {
      setLoadSendMessages(false);
    }
  };

  const [hideCallBar, setHideCallBar] = useState(false);

  function formatDateTime(dateCreated) {
    const options = {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const dateTime = new Date(dateCreated);
    return dateTime.toLocaleDateString(undefined, options);
  }
  function formatDate(dateStr) {
    // Convert the input date string to a Date object
    const inputDate = new Date(dateStr);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in days between the input date and the current date
    const timeDifference = (currentDate - inputDate) / (1000 * 3600 * 24);

    if (timeDifference < 1) {
      return "Today";
    } else if (timeDifference < 2) {
      return "Yesterday";
    } else if (timeDifference < 7) {
      // Format the input date as a day of the week (e.g., Sun, Mon, etc.)
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[inputDate.getDay()];
    } else {
      // Format the input date in a custom way (e.g., 'Fri Sep 01 2023')
      const options = { year: "numeric", month: "short", day: "numeric" };
      return inputDate.toLocaleDateString("en-US", options);
    }
  }

  // const fetchRecordingUrl = async () => {
  //   try {
  //     // Replace with the actual URL for the recording from your Twilio response
  //     const response = await fetch(
  //       "https://api.twilio.com/2010-04-01/Accounts/ACc6de85618a2972db84f4727bbd8354b5/Recordings/RE1ba9290e98db6658bea65c34891fd85f/Transcriptions.json",
  //     );

  //     console.log(response);
  //     if (response.ok) {
  //       const data = await response.json();
  //     } else {
  //       console.error("Failed to fetch recording URL");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching recording URL:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchRecordingUrl();
  // }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Call your function here
      if (message.length > 0) {
        sendMessage();
      }

      // Optionally, you can prevent the default behavior (form submission)
      e.preventDefault();
    }
  };

  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] ">
      {/* {receivedMessages?.length>0 && */}
      {chatList?.length > 0 ? (
        <div className="relative flex flex-col h-[calc(100vh-100px)]">
          {userDetail?._id && (
            <div className="pb-[15px] flex items-center justify-between border-b-[2px] border-b-[#E1E1EE]">
              <span className="flex items-center">
                <InitialsLogo str={userDetail?.name} />
                <div className="flex flex-col">
                  <span className="ml-[8px] ">
                    {" "}
                    <span className="ml-[8px] text-[#252E4B] text-[18px] font-semibold not-italic leading-[24px]">
                      {userDetail?.name}
                    </span>
                    {userDetail?.position && (
                      <span className="text-[#fff] border border-[green] bg-[green] rounded-[20px] px-[15px] py-[3px] ml-[10px] text-[14px] font-semibold not-italic leading-[24px]">{`${userDetail?.position + "," + userDetail?.company}`}</span>
                    )}
                  </span>
                  <span className="ml-[8px] text-[#5F6989] text-[14px] font-normal not-italic ">
                    {userDetail?.to}
                  </span>
                </div>
              </span>
              <div className="flex">
                {userDetail?.toPhone && (
                  <span
                    className=" ml-[12px] cursor-pointer shadow-menuOptionShadow interview-button  p-[6px] bg-[#FFF] border border-[#DADAE4] w-[40px] h-[40px] flex items-center justify-center rounded-[8px]"
                    data-toggle="modal"
                    data-target="#call-modal"
                    onClick={() => {
                      setFetchNumber(userDetail?.toPhone);
                      setHideCallBar(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M7.2197 3.52275C7.00317 2.98526 6.47887 2.63281 5.89584 2.63281H3.93477C3.18875 2.63281 2.58398 3.23315 2.58398 3.97388C2.58398 10.2701 7.72467 15.3743 14.0658 15.3743C14.8119 15.3743 15.4165 14.7738 15.4165 14.033L15.4168 12.0856C15.4168 11.5067 15.0619 10.9862 14.5206 10.7712L12.641 10.025C12.1548 9.83183 11.6012 9.91875 11.1988 10.2516L10.7137 10.6533C10.1472 11.1221 9.31365 11.0848 8.7922 10.5671L7.42627 9.20958C6.90482 8.69183 6.86629 7.86485 7.33839 7.30234L7.74288 6.82073C8.07816 6.42125 8.16647 5.87139 7.97197 5.38858L7.2197 3.52275Z"
                        fill="#5F6989"
                      />
                    </svg>
                  </span>
                )}

                {/* <span
                  className="ml-[12px] shadow-menuOptionShadow interview-button  p-[6px] bg-[#FFF] border border-[#DADAE4] w-[40px] h-[40px] flex items-center justify-center rounded-[8px]"
                  data-toggle="modal"
                  data-target="#call-modal"
                  onClick={() => {
                    setHideCallBar(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                  >
                    <rect
                      x="1.63672"
                      y="1.50781"
                      width="14.7273"
                      height="13.2545"
                      rx="2"
                      fill="#5F6989"
                    />
                    <path
                      d="M1.63672 4.45312L6.50158 8.34501C7.96245 9.51371 10.0383 9.51371 11.4991 8.34501L16.364 4.45312"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span
                  className="ml-[12px] shadow-menuOptionShadow interview-button  p-[6px] bg-[#FFF] border border-[#DADAE4] w-[40px] h-[40px] flex items-center justify-center rounded-[8px]"
                  data-toggle="modal"
                  data-target="#call-modal"
                  onClick={() => {
                    setHideCallBar(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM9.75 5.25C9.75 5.66421 9.41421 6 9 6C8.58579 6 8.25 5.66421 8.25 5.25C8.25 4.83579 8.58579 4.5 9 4.5C9.41421 4.5 9.75 4.83579 9.75 5.25ZM8.25 6.9375C7.93934 6.9375 7.6875 7.18934 7.6875 7.5C7.6875 7.81066 7.93934 8.0625 8.25 8.0625H8.4375V12.75C8.4375 13.0607 8.68934 13.3125 9 13.3125C9.31066 13.3125 9.5625 13.0607 9.5625 12.75V7.5C9.5625 7.18934 9.31066 6.9375 9 6.9375H8.25Z"
                      fill="#5F6989"
                    />
                  </svg>
                </span>
                <span
                  className="ml-[12px]  shadow-menuOptionShadow interview-button  p-[6px] bg-[#FFF] border border-[#DADAE4] w-[40px] h-[40px] flex items-center justify-center rounded-[8px]"
                  data-toggle="modal"
                  data-target="#call-modal"
                  onClick={() => {
                    setHideCallBar(true);
                  }}
                >
                  <svg
                    className="mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.33398 4.79134C8.33398 3.98593 8.9869 3.33301 9.79232 3.33301C10.5977 3.33301 11.2507 3.98593 11.2507 4.79134C11.2507 5.59676 10.5977 6.24967 9.79232 6.24967C8.9869 6.24967 8.33398 5.59676 8.33398 4.79134ZM8.33398 9.79134C8.33398 8.98593 8.9869 8.33301 9.79232 8.33301C10.5977 8.33301 11.2507 8.98593 11.2507 9.79134C11.2507 10.5968 10.5977 11.2497 9.79232 11.2497C8.9869 11.2497 8.33398 10.5968 8.33398 9.79134ZM9.79232 13.333C8.9869 13.333 8.33398 13.9859 8.33398 14.7913C8.33398 15.5968 8.9869 16.2497 9.79232 16.2497C10.5977 16.2497 11.2507 15.5968 11.2507 14.7913C11.2507 13.9859 10.5977 13.333 9.79232 13.333Z"
                      fill="#5F6989"
                    ></path>
                  </svg>
                </span> */}
              </div>
            </div>
          )}

          {hideCallBar && (
            <div className="absolute z-10 w-[256px] rounded-[12px] bg-[#FFF] right-0 bottom-[40px] ">
              <VoiceCall
                setHideCallBar={setHideCallBar}
                chatDetail={{ phone: [userDetail?.toPhone] }}
                fetchNumber={fetchNumber}
              />
            </div>
          )}

          {loading ? ( // Check if there are no messages
            <div className="h-[100%] flex items-center justify-center">
              <ScaleLoader height={28} color="#1569EB" loading={true} />
            </div>
          ) : messagesList?.length === 0 ? (
            <>
              <div className="h-[100%] flex items-center justify-center"></div>
            </>
          ) : (
            <ScrollableFeed>
              <MessageList
                className="message-list"
                lockable={true}
                toBottomHeight={"100%"}
                dataSource={messagesList?.map((message, index) => ({
                  position:
                    message?.direction === "inbound-api" ? "left" : "right",
                  type: "text",
                  text: message?.body,
                  date: message?.date_created,
                  title:
                    message?.direction === "inbound-api"
                      ? userDetail?.name
                      : "You",
                  titleColor:
                    message?.direction === "inbound-api" ? "blue" : "green",
                  titleAlign:
                    message?.direction === "inbound-api" ? "left" : "right",
                  titleSize: 14,
                  titleWeight: "bold",
                  titleStyle: "italic",
                  dateColor: "black",
                  dateSize: 10,
                  dateWeight: "bold",
                }))}
              />
            </ScrollableFeed>
          )}

          <div className="bg-[#fff] mb-[12px]  px-[12px] rounded-[8px] shadow-searchboxShadow">
            <div className="flex items-center py-[9px]">
              <span className="w-[18px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g id="Group 427319278">
                    <path
                      id="Star 8"
                      d="M5.54474 2.67969C6.04291 5.38384 8.15878 7.49971 10.8629 7.99787C8.15878 8.49603 6.04291 10.6119 5.54474 13.3161C5.04658 10.6119 2.93071 8.49603 0.226562 7.99787C2.93071 7.49971 5.04658 5.38384 5.54474 2.67969Z"
                      fill="#5F6989"
                    />
                    <path
                      id="Star 9"
                      d="M12.0904 0.632812C12.282 1.67287 13.0958 2.48667 14.1358 2.67827C13.0958 2.86987 12.282 3.68366 12.0904 4.72372C11.8988 3.68366 11.085 2.86987 10.0449 2.67827C11.085 2.48667 11.8988 1.67287 12.0904 0.632812Z"
                      fill="#5F6989"
                    />
                    <path
                      id="Star 10"
                      d="M12.9086 9.63281C13.1768 11.0889 14.3161 12.2282 15.7722 12.4964C14.3161 12.7647 13.1768 13.904 12.9086 15.3601C12.6403 13.904 11.501 12.7647 10.0449 12.4964C11.501 12.2282 12.6403 11.0889 12.9086 9.63281Z"
                      fill="#5F6989"
                    />
                  </g>
                </svg>
              </span>
              <textarea
                className=" w-[calc(100%-35px)] min-h-[20px] max-h-[80px] py-[9px] resize-none overflow-hidden bg-transparent p-[6px]  outline-none auto-complete-input "
                name=""
                id=""
                rows={1}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Enter your message..."
              ></textarea>
              <span
                className="cursor-pointer text-[#1369E9] text-[14px] font-medium not-italic leading-[24px]  p-[6px] "
                disabled={loadSendMessages}
                onClick={(e) => {
                  if (message.length > 0) {
                    sendMessage();
                  }
                }}
              >
                {loadSendMessages ? (
                  <>Sending...</>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M7.92668 3.52389L15.06 7.09056C18.26 8.69056 18.26 11.3072 15.06 12.9072L7.92668 16.4739C3.12668 18.8739 1.16835 16.9072 3.56835 12.1156L4.29335 10.6739C4.47668 10.3072 4.47668 9.69889 4.29335 9.33222L3.56835 7.88223C1.16835 3.09056 3.13502 1.12389 7.92668 3.52389Z"
                      fill="#1369E9"
                    />
                    <path d="M10 9.98571L3 8.5V11.1L10 9.98571Z" fill="white" />
                  </svg>
                )}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          no chat there
        </div>
      )}
    </main>
  );
}

function SmsList({
  getallchats,
  chatList,
  getChatdetail,
  setLoading,
  seenNotificationUpdate,
  setTo,
  setUserDetail,
  userDetail,
  setNewUserName,
  setNewUserNumber,
  newUserNumber,
  notification,
  setNotification,
}) {
  const [filterChatList, setFilterChatlist] = useState(chatList);

  useEffect(() => {
    setFilterChatlist(chatList);
  }, [chatList]);

  const [option, setOption] = useState("name");

  function searchItems(keyword) {
    // Convert the keyword to lowercase for case-insensitive search
    const lowerCaseKeyword = keyword?.toLowerCase();

    // Use the filter() method to find items that match the keyword

    if (option === "position") {
      const filteredItems = chatList?.filter((item) =>
        item?.position?.toLowerCase().includes(lowerCaseKeyword),
      );
      setFilterChatlist(filteredItems);
    } else {
      const filteredItems = chatList?.filter((item) =>
        item?.name?.toLowerCase().includes(lowerCaseKeyword),
      );
      setFilterChatlist(filteredItems);
    }
  }

  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [showfilteroption, setShowfilteroption] = useState(false);

  useEffect(() => {
    const sortedMessages = chatList.sort(
      (a, b) => new Date(b.lastSeen) - new Date(a.lastSeen),
    );
    setFilterChatlist(sortedMessages);
  }, [chatList]);

  const addNewsmsUser = async (toUserId) => {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CHAT_API.POST_ADD_SMSUSER,
        1,
        {
          name: toUserId.length > 0 ? "" : newUserName,
          toUserId: toUserId,
          toPhone: toUserId.length > 0 ? "" : "+" + newUserNumber,
        },
      );

      if (json) {
        getallchats();
        setShowInterviewModal(false);
      }
    } catch {}
  };

  return (
    <div className="mt-[26px] relative  h-[calc(100vh-134px)]  ">
      <div>
        {showfilteroption && (
          <div className="absolute bg-[#F3F5F9] flex flex-col rounded-[12px] shadow-menuOptionShadow z-10 w-[240px] px-[20px] py-[15px] top-[40px]">
            <span
              className="cursor-pointer py-[6px]"
              onClick={(e) => {
                setShowfilteroption(false);
                setOption("name");
              }}
            >
              {" "}
              By name
            </span>
            <span
              className="cursor-pointer py-[6px]"
              onClick={(e) => {
                setShowfilteroption(false);
                setOption("position");
              }}
            >
              {" "}
              By Job
            </span>
          </div>
        )}
      </div>
      <div className="relative w-[240px] flex items-center justify-between">
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => searchItems(e.target.value)}
          className="bg-[#F3F5F9] rounded-[8px] w-[195px] h-[32px] pl-4"
        />
        <button
          className="border-[2px] w-[32px]  rounded-[12px] flex justify-center items-center h-[32px] bg-[#1369E9] "
          onClick={() => {
            setShowInterviewModal(!showInterviewModal);
          }}
        >
          <span className="text-[16px] font-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 9.33073V7.33073M8 7.33073V5.33073M8 7.33073H6M8 7.33073H10M4.74905 12.4648L3.73307 13.2776C3.17829 13.7214 2.90077 13.9435 2.66732 13.9437C2.46429 13.9439 2.2723 13.8516 2.14564 13.6929C2 13.5105 2 13.1553 2 12.4449V4.79753C2 4.05079 2 3.67714 2.14532 3.39193C2.27316 3.14104 2.47698 2.93722 2.72786 2.80939C3.01308 2.66406 3.38673 2.66406 4.13346 2.66406H11.8668C12.6135 2.66406 12.9867 2.66406 13.2719 2.80939C13.5228 2.93722 13.727 3.14104 13.8548 3.39193C14 3.67686 14 4.05006 14 4.79534V9.86645C14 10.6117 14 10.9844 13.8548 11.2693C13.727 11.5202 13.5229 11.7244 13.272 11.8522C12.9871 11.9974 12.6143 11.9974 11.8691 11.9974H6.08171C5.80435 11.9974 5.66552 11.9974 5.53288 12.0246C5.41519 12.0488 5.30144 12.0886 5.19445 12.1432C5.07433 12.2046 4.96642 12.2909 4.75148 12.4629L4.74905 12.4648Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </button>
      </div>

      <div className="overflow-y-scroll h-[calc(100vh-180px)] mt-[15px] scrollbar-candidate">
        {filterChatList?.map((item, idx) => (
          <div
            className={`flex p-[15px] ${item?._id === userDetail?._id ? "bg-[#F3F5F9] rounded-[8px] " : ""}  cursor-pointer`}
            key={idx}
            onClick={(e) => {
              getChatdetail(item?._id);
              setTo(item?._id);
              setUserDetail(item);
              setLoading(true);
              seenNotificationUpdate(item?._id);
              setNotification(
                notification?.filter((iter) => iter._id !== item._id),
              );
            }}
          >
            <InitialsLogo str={item?.name} />
            <div className="flex flex-col ml-[15px] w-full">
              <span className="flex justify-between items-center w-full">
                <span className="inline-block text-[14px] w-[130px] not-italic text-[#252E4B] font-medium truncate">
                  {item?.name}
                </span>
                {/* <span>time</span> */}
              </span>
              <span className="flex justify-between items-center ">
                <span className="inline-block w-[130px] text-[13px] not-italic font-normal text-[#5F6989] truncate">
                  {item?.lastMessage}
                </span>
                {notification &&
                  notification.length > 0 &&
                  notification?.filter((iter) => iter._id === item._id)[0]
                    ?.notificationCount > 0 && (
                    <span className="w-[18px] h-[18px] rounded-[50%] bg-[red] flex justify-center items-center mr-[8px] text-[10px] font-medium text-[#ffffff]">
                      {
                        notification?.filter((iter) => iter._id === item._id)[0]
                          ?.notificationCount
                      }
                    </span>
                  )}
              </span>
            </div>
          </div>
        ))}
      </div>

      {showInterviewModal && (
        <AddChatUserModal
          modalDismiss={() => setShowInterviewModal(false)}
          addNewsmsUser={addNewsmsUser}
          setNewUserName={setNewUserName}
          newUserNumber={newUserNumber}
          setNewUserNumber={setNewUserNumber}
        />
      )}
    </div>
  );
}
