import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "./Search";

const TabButtons = () => {
  const [toggleSearch, setToggleSearch] = useState(false);
  const nav = useNavigate();
  const par = useLocation().pathname.split("/");
  const active = toggleSearch
    ? "search"
    : par.includes("new")
      ? "new"
      : par.includes("job")
        ? "job"
        : "email";
  const activeStyle =
    "border-blue-900 border-0 text-blue-900 jobPostButtonShadow btn-md btn-sec px-2.5 h-8 w-[42px]";
  const inactiveStyle =
    "border-button border-0 rounded-lg btn-sec btn-md px-2.5 h-8 text-gray-800 w-[42px]";

  return (
    <div className="w-full relative flex justify-between pt-5">
      <button
        data-tooltip-id="tooltip"
        data-tooltip-content="Email"
        onClick={() => nav("/templates/email")}
        className={active == "email" ? activeStyle : inactiveStyle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 22 20"
          stroke="currentColor"
          fill="none"
        >
          <path
            d="M5 6L8.7812 8.5208C10.1248 9.41653 11.8752 9.41653 13.2188 8.5208L17 6M5 19H17C19.2091 19 21 17.2091 21 15V5C21 2.79086 19.2091 1 17 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <button
        data-tooltip-id="tooltip"
        data-tooltip-content="Job"
        onClick={() => nav("/templates/job")}
        className={active == "job" ? activeStyle : inactiveStyle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          stroke="currentColor"
          strokeWidth={1.2}
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7 5.5V4.75C7 3.50736 8.00736 2.5 9.25 2.5H10.75C11.9926 2.5 13 3.50736 13 4.75V5.5M2.5 10.75H17.5M7 9.625V11.875M13 9.625V11.875M5.5 17.5H14.5C16.1569 17.5 17.5 16.1569 17.5 14.5V8.5C17.5 6.84315 16.1569 5.5 14.5 5.5H5.5C3.84315 5.5 2.5 6.84315 2.5 8.5V14.5C2.5 16.1569 3.84315 17.5 5.5 17.5Z"
            strokeLinecap="round"
          />
        </svg>
      </button>
      <button
        data-tooltip-id="tooltip"
        data-tooltip-content="New"
        onClick={() => nav("new")}
        className={active == "new" ? activeStyle : inactiveStyle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </button>
      <button
        onClick={() => setToggleSearch(!toggleSearch)}
        data-tooltip-id="tooltip"
        data-tooltip-content="Search"
        className={toggleSearch ? activeStyle : inactiveStyle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </button>
      {toggleSearch ? (
        <Search disableSearch={() => setToggleSearch(false)} />
      ) : null}
    </div>
  );
};

export default TabButtons;
