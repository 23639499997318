import React from "react";
import { ACTIVITY_TYPE } from "../../../../lib/constants";
import { activity, calendar, mail, notes, report } from "../icons/Icons";
import GenericContent from "./GenericContent";
import InterviewScheduledCard from "../interview/InterviewScheduledCard";
import InterviewReportCard from "../interview/InterviewReportCard";
import { Link } from "react-router-dom";
import EmailContent, { EmailHeader } from "./EmailContent";
import NotesContent from "../notes/NotesContent";

const ActivityItem = ({ data, ...props }) => {
  const actIcon =
    data?.activity_type == ACTIVITY_TYPE.EMAIL
      ? mail
      : data?.activity_type == ACTIVITY_TYPE.NOTE
        ? notes
        : data?.activity_type == ACTIVITY_TYPE.SCHEDULED
          ? calendar
          : data?.activity_type == ACTIVITY_TYPE.REPORT
            ? report
            : activity;

  const activityContent =
    data?.activity_type == ACTIVITY_TYPE.EMAIL ? (
      <EmailContent data={data} />
    ) : data?.activity_type == ACTIVITY_TYPE.SEQUENCE ? (
      <EmailContent data={data} />
    ) : data?.activity_type == ACTIVITY_TYPE.NOTE ? (
      <NotesContent data={data} />
    ) : data?.activity_type == ACTIVITY_TYPE.SCHEDULED ? (
      <InterviewScheduledCard dataList={[data]} />
    ) : data?.activity_type == ACTIVITY_TYPE.REPORT ? (
      <InterviewReportCard data={data} />
    ) : (
      <GenericContent data={data} />
    );

  const getDuration = () => {
    const now = new Date();
    const then = new Date(data?.createdAt);
    const durationInMilliseconds = now - then;

    const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
    const minutes = Math.floor((durationInMilliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((durationInMilliseconds / (1000 * 60 * 60)) % 24);
    const days = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));

    let duration = "";
    if (days > 0) duration += `${days} day${days > 1 ? "s" : ""} `;
    else if (hours > 0) duration += `${hours} hour${hours > 1 ? "s" : ""} `;
    else if (minutes > 0)
      duration += `${minutes} minute${minutes > 1 ? "s" : ""} `;
    else if (seconds > 0)
      duration += `${seconds} second${seconds > 1 ? "s" : ""} `;

    return duration.trim() + " ago";
  };
  const capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  return (
    <div {...props} className="flex gap-3 items-start justify-start mb-5">
      <div
        data-tooltip-id="tooltip"
        data-tooltip-content={capitalize(data?.activity_type)}
        className="w-9 h-9 bg-white border border-gray-400 rounded-full -ml-[1.125rem] p-2 text-gray-900 outline-[6px] outline-white outline"
      >
        {actIcon}
      </div>
      <div className="w-full flex flex-col gap-1.5 mt-1">
        {data?.heading && String(data?.heading)?.length > 0 ? (
          <h1
            data-testid="heading"
            className="flex items-start text-black text-base font-medium grow not-italic"
          >
            {data?.heading}
            {data?.activity_type == ACTIVITY_TYPE.EMAIL ? (
              <EmailHeader data={data} />
            ) : null}
          </h1>
        ) : null}
        <div className="w-full flex justify-between gap-2 mb-2">
          <div className="grow gap-2 flex justify-start">
            {data?.user && data?.activity_type != ACTIVITY_TYPE.EMAIL && (
              <span
                data-testid="user"
                className="px-2 pt-1 rounded-lg bg-blue-200 text-gray-800 text-xs font-medium"
              >
                {data?.user?.name}
              </span>
            )}
            {data?.job && (
              <Link
                data-testid="job"
                target="_blank"
                to={
                  "/job/" +
                  data?.job?.status +
                  "/" +
                  data?.job?._id +
                  "/description"
                }
                className="px-2 pt-1 rounded-lg bg-bluegreen-200 text-gray-800 text-xs font-medium"
              >
                {data?.job?.name}
              </Link>
            )}
            {
              <span
                data-testid="user"
                className="px-2 text-xs text-emerald-900"
              >
                {data?.source}
              </span>
            }
          </div>
          {data?.createdAt && (
            <h2
              data-testid="duration"
              data-tooltip-id="tooltip"
              data-tooltip-content={new Date(data?.createdAt)?.toString()}
              className="text-gray-700 text-sm not-italic font-normal w-auto cursor-default"
            >
              {getDuration()}
            </h2>
          )}
        </div>
        {activityContent}
      </div>
    </div>
  );
};

export default ActivityItem;
