import { useEffect, useState } from "react";
import Description from "./Description";
import JobAnalytics from "./JobAnalytics";
import { useSelector } from "react-redux";
import {
  fetchDescription,
  reparseJobService,
} from "../../../../redux/services";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import TabLayout from "../../../../layouts/TabLayout";
import { ScaleLoader } from "react-spinners";

function JobDescription({}) {
  const [view, setView] = useState("all");

  const { job, jobId } = useSelector((state) => {
    return {
      job: state?.JobExpandSlice?.description,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  const dispatch = useDispatch();

  const fetchDesc = async () => {
    if (!jobId) {
      return;
    }
    dispatch(fetchDescription(jobId + "?status=active"));
  };

  useEffect(() => {
    fetchDesc();
    if (job?.analytics?.skills?.length > 0 || job?.preferCriteria?.length > 0) {
      setView("all");
    }
  }, [jobId]);

  const [parse, setParse] = useState("Reparse");
  const reParse = async () => {
    dispatch(reparseJobService({ atsJobId: job?.atsJobId }));
    setParse("Parsing...");
  };

  const btnList = [
    {
      name: "All",
      id: "all",
    },
    {
      name: "Details",
      id: "details",
    },
    {
      name: "Analytics",
      id: "analytics",
    },
  ];
  return (
    <section
      className="h-auto overflow-y-scroll max-h-[calc(100vh-178px)]  scrollbar-hide"
      id="description"
    >
      {job &&
        (job?.analytics?.skills?.length > 0 ||
          job?.preferCriteria?.length > 0) && (
          <TabLayout
            btnList={btnList}
            className="my-4"
            state={view}
            setState={setView}
          />
        )}
      {!job ? (
        <div className="flex justify-center h-[calc(100vh-140px)]  items-center">
          <ScaleLoader height={40} color="#1569EB" loading={true} />
        </div>
      ) : (
        // Render your actual content here
        <div
          className={`${
            view == "all" ? "max-w-full grid grid-cols-12 gap-[60px]" : ""
          } bg-white rounded-xl mt-2.5  p-6`}
        >
          {view != "analytics" && (
            <Description
              classTxt={view == "all" ? "grow shrink col-span-7" : "w-full"}
              job={job}
            />
          )}
          {view != "details" &&
            (job?.analytics?.skills?.length > 0 ||
              job?.preferCriteria?.length > 0) && (
              <JobAnalytics
                classTxt={
                  view == "all"
                    ? "analytics-dashboard  col-span-5"
                    : "analytics-dashboard  w-full gap-y-8 gap-x-4 flex flex-row items-start"
                }
                onlyAnalytics={view == "analytics"}
                job={job?.analytics}
                preferCriteria={job?.preferCriteria}
              />
            )}
        </div>
      )}
    </section>
  );
}
export default JobDescription;
